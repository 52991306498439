import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import { getAllItemIncremental } from "../../Item/services/item.services";
import { IItem } from "../../Item/itemModel";
import {
  Grid,
  GridColumn,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import { setCurrentPage, setPageLimit } from "../../pagination/paginationSlice";
import { getInwardPackingMaterialStockReport } from "../services/reports.services";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { Button } from "@progress/kendo-react-buttons";
import Logo from "../../../assets/Images/logo.png";
import {
  clearInwardPackingStockList,
  setInwardPackingStockReport,
} from "../reportsSlice";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import {
  checkAcessRights,
  formatIndianNumberForQty,
} from "../../../_helper/helper";
import { EXCEL_EXPORT_ACCESS } from "../../../_contstants/common";
import FormIncrementalSearch from "../../../components/formFields/FormIncrementalSearch";
import { clearItemList } from "../../Item/itemSlice";

const InwardPackingMaterialStockForm: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const itemLoading = useAppSelector((state) => state.item.loading);
  const ItemList = useAppSelector((state) => state.item.ItemList);
  const InwardPackingMaterialStockList = useAppSelector(
    (state) => state.reports.InwardPackingMaterialStockList
  );
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  useEffect(() => {
    return () => {
      dispatch(clearInwardPackingStockList());
      dispatch(setCurrentPage(0));
      dispatch(clearItemList());
    };
  }, []);
  const handleSubmit = (values: any) => {
    const payload = {
      item_id: values?.item_id ? values?.item_id : null,
      from_date: values?.from_date
        ? moment(values?.from_date).format("YYYY-MM-DD")
        : null,
      to_date: values?.to_date
        ? moment(values?.to_date).format("YYYY-MM-DD")
        : null,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(setInwardPackingStockReport(payload));
    dispatch(getInwardPackingMaterialStockReport(payload));
    // window.open(
    //   `companywisebookingreportview/${payload?.CompanyID}/${payload?.DateType}/${payload?.Type}/${payload?.FromDate}/${payload?.to_date}`
    // );
  };
  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 10 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[{ width: "40%" }, { width: "60%" }]}
                >
                  <GridLayoutItem colSpan={2}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Inward Packing Material Stock Detail Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="from_date"
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="to_date"
                      label="To Date"
                      format="dd/MM/yyyy"
                      //   minDate={moment(
                      //     formRenderProps.valueGetter("from_date")
                      //   ).toDate()}
                      component={FormDatePicker}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem></GridLayoutItem>
                  <GridLayoutItem
                    style={{ display: "flex", alignItems: "end", gap: 10 }}
                  >
                    <Field
                      wrapperClassName="w-100"
                      name="item_id"
                      label="Product Name"
                      // component={FormSelectionField}
                      // options={ItemList?.map((item: IItem) => {
                      //   return {
                      //     value: item?.id,
                      //     label: item?.product_name,
                      //   };
                      // })}
                      component={FormIncrementalSearch}
                      fetchIncrementalData={(search: any) =>
                        dispatch(getAllItemIncremental({ search }))
                      }
                      loading={itemLoading}
                      options={ItemList?.map((item: IItem) => {
                        return {
                          value: item?.id,
                          label: item?.product_name,
                        };
                      })}
                    />
                    <div style={{ marginBottom: 1 }}>
                      <ButtonWithLoading
                        label={"View"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          InwardPackingMaterialStockList &&
          InwardPackingMaterialStockList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 }}>
              <InwardPackingMaterialStockGridComponent />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

const InwardPackingMaterialStockGridComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const isExcelAccess = checkAcessRights(
    location.pathname,
    EXCEL_EXPORT_ACCESS
  );

  //   const loading = useAppSelector((state) => state.reports.loading);
  const InwardPackingMaterialStockList = useAppSelector(
    (state) => state.reports.InwardPackingMaterialStockList
  );
  const InwardPackingMaterialStocksDetails = useAppSelector(
    (state) => state.reports.InwardPackingMaterialStocksDetails
  );
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const isInwardPackingstockRef = React.useRef(true);

  useEffect(() => {
    if (!isInwardPackingstockRef.current) {
      const payload = {
        item_id: InwardPackingMaterialStocksDetails?.item_id
          ? InwardPackingMaterialStocksDetails?.item_id
          : null,
        from_date: InwardPackingMaterialStocksDetails?.from_date
          ? InwardPackingMaterialStocksDetails?.from_date
          : "",
        to_date: InwardPackingMaterialStocksDetails?.to_date
          ? InwardPackingMaterialStocksDetails?.to_date
          : "",
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      dispatch(getInwardPackingMaterialStockReport(payload));
    } else {
      isInwardPackingstockRef.current = false;
    }
  }, [currentPage, pageLimit]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(
      document.querySelector(".table-bordered")
    );
    XLSX.utils.book_append_sheet(wb, ws, "CompanyWiseBookingReport");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, "");
  };

  const printTable = (data: any[]) => {
    let printbuttons = document.querySelector(
      ".printbuttons"
    ) as HTMLElement | null;
    let report_container = document.querySelector(
      ".report-container"
    ) as HTMLElement | null;

    if (printbuttons) printbuttons.style.display = "none";
    if (report_container) report_container.style.padding = "20px 0";

    let tableRows = data.length;
    let maxRows = tableRows;
    let pageCount = Math.ceil(tableRows / maxRows);
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      //   content += `<div class="page-header">Page ${i + 1}</div>`;
      content +=
        '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
      content += "<tr>";
      content += `<td rowSpan="3" style="width:15%" ><img src="${Logo}" width="100%" /></td>`;
      content +=
        '<td colSpan="2" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
      content += "</tr>";
      content += "<tr>";
      content +=
        '<td colSpan="2"><h3 style="margin:0;">Inward Packing Material Stock Report</h3></td>';
      content += "</tr>";
      content += "<tr>";
      content += `<td style="text-align:end;">Page : ${i + 1}</td>`;
      content += "</tr>";

      content += "</table>";

      content += '<table class="table table-bordered" style="font-size:10px;">';
      content += '<thead style="background:lightgray;">';
      content += "<tr>";
      content += '<th style="width:30px;">Sr. No.</th>';
      content += "<th>Inward Packing No.</th>";
      content += "<th>Inward Packing Date</th>";
      content += '<th style="width:200px;">Product Name</th>';
      content += "<th>Qty</th>";
      content += "<th>Unit</th>";
      content += "<th>Roll</th>";
      content += "</tr>";
      content += "</thead>";
      content += "<tbody>";

      let startRow = i * maxRows;
      let endRow = Math.min((i + 1) * maxRows, tableRows);

      for (let j = startRow; j < endRow; j++) {
        content += "<tr>";
        content += `<td>${j + 1}</td>`;
        content += `<td>${data[j]?.inward_packing_no}</td>`;
        content += `<td style="text-align:center;">${
          data[j]?.inward_packing_material_date
            ? moment(
                data[j]?.inward_packing_material_date,
                "DD/MM/YYYY"
              ).format("DD MMM YYYY")
            : ""
        }</td>`;
        content += `<td>${data[j]?.product_name}</td>`;
        content += `<td style="text-align:end;">${formatIndianNumberForQty(
          +data[j]?.inward_item_qty || 0
        )}</td>`;
        content += `<td style="text-align:center;">${data[j]?.unit_name}</td>`;
        content += `<td style="text-align:end;">${data[j]?.roll}</td>`;
        content += "</tr>";
      }

      content += "</tbody>";
      content += "</table>";

      // content +=
      //   '<table class="table table-bordered" style="font-size:11px; width:100%; margin:30px 0; text-align:center">';
      // content += "<tr>";
      // content += `<td style="width:33%">Prepared By: <br>Mr. GAURANG MEHTA</br></td>`;
      // content += `<td style="width:34%">Document Status: </br>CONTROL COPY</td>`;
      // content += `<td style="width:33%">Approved By: <br>Mr. PARAG SHAH</br> </td>`;
      // content += "</tr>";
      // content += "</table>";

      content += "</div>";
    }

    let printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }
    printWindow.document.write(`
        <html>
        <head>
          <title>Print Preview</title>
          <style>
            @page { margin: 1cm; }
            .print-page { page-break-after: always; }
            .table-bordered { width: 100%; border-collapse: collapse; }
            .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
            body { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body>
          ${content}
          <script>
            window.onload = function() {
              window.print();
            };
          </script>
        </body>
        </html>
      `);
    printWindow.document.close();

    if (printbuttons) printbuttons.style.display = "block";
    if (report_container) report_container.style.padding = "20px 10px";
  };

  return (
    <>
      {InwardPackingMaterialStockList &&
      InwardPackingMaterialStockList.length ? (
        <GridLayout
          //   style={{ marginRight: 30 }}
          gap={{ rows: 10, cols: 10 }}
          cols={[{ width: "100%" }]}
        >
          <GridLayoutItem className="d-flex justify-content-between align-items-center">
            <Typography.h4 className="m-0">
              Inward Packing Material Stock List{" "}
              {(InwardPackingMaterialStocksDetails?.from_date ||
                InwardPackingMaterialStocksDetails?.to_date) && (
                <span style={{ fontSize: 13 }}>
                  {"("}
                  {InwardPackingMaterialStocksDetails?.from_date
                    ? moment(
                        InwardPackingMaterialStocksDetails?.from_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {InwardPackingMaterialStocksDetails?.from_date &&
                    InwardPackingMaterialStocksDetails?.to_date &&
                    " - "}
                  {InwardPackingMaterialStocksDetails?.to_date
                    ? moment(
                        InwardPackingMaterialStocksDetails?.to_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {")"}
                </span>
              )}
            </Typography.h4>
            <div>
              <Button
                type="button"
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={() => printTable(InwardPackingMaterialStockList)}
              >
                Print
              </Button>
              {isExcelAccess && (
                <Button
                  style={{ marginLeft: 5 }}
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={handleExportExcel}
                >
                  Excel Export
                </Button>
              )}
            </div>
          </GridLayoutItem>
          <GridLayoutItem>
            <Grid
              className="table-bordered"
              style={{ maxHeight: "calc(100vh - 152px)" }}
              filterable={true}
              filter={filter}
              data={
                filter
                  ? filterBy(InwardPackingMaterialStockList, filter)
                  : InwardPackingMaterialStockList
              }
              onFilterChange={handleFilterChange}
              total={
                InwardPackingMaterialStockList[0]?.itemcount ||
                InwardPackingMaterialStockList.length
              }
              skip={currentPage * pageLimit}
              take={pageLimit}
              pageable={{
                buttonCount: 5,
                pageSizes: [10, 50, 100, 300, 500],
                pageSizeValue: pageLimit,
                type: "input",
              }}
              onPageChange={pageChange}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <GridColumn
                field="UniqueNo"
                title="Sr. No."
                width={80}
                filterable={false}
              />
              <GridColumn
                field="inward_packing_no"
                title="Inward Packing No."
              />
              <GridColumn
                field="inward_packing_material_date"
                title="Inward Packing Date"
                cell={(props) => (
                  <td>
                    {props.dataItem.inward_packing_material_date
                      ? moment(
                          props.dataItem.inward_packing_material_date,
                          "DD/MM/YYYY"
                        ).format("DD MMM YYYY")
                      : ""}
                  </td>
                )}
              />
              <GridColumn field="product_name" title="Item Name" />
              <GridColumn
                field="inward_item_qty"
                title="Qty"
                cell={(props: any) => (
                  <td className="text-end">
                    {props.dataItem?.inward_item_qty
                      ? formatIndianNumberForQty(
                          +props.dataItem?.inward_item_qty
                        )
                      : null}
                  </td>
                )}
              />
              <GridColumn
                field="unit_name"
                title="Unit"
                width={100}
                filterable={false}
              />
              <GridColumn
                field="roll"
                title="Roll"
                width={100}
                filterable={false}
                cell={(props: any) => (
                  <td className="text-end">{props.dataItem?.roll}</td>
                )}
              />
            </Grid>
          </GridLayoutItem>
        </GridLayout>
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default InwardPackingMaterialStockForm;
