import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import {
  Button,
  DropDownButton,
  DropDownButtonItem,
} from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { MdDelete } from "react-icons/md";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import { checkAcessRights, formatIndianNumber } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  FINANCIAL_YEAR,
  INSERT_ACCESS,
  PRINT_ACCESS,
} from "../../_contstants/common";
import { deleteSales, getAllSales } from "./services/sales.services";
import {
  clearFilterSales,
  clearSalesID,
  setFilterSales,
  setSalesID,
} from "./salesSlice";
import { clearItemList } from "../Item/itemSlice";
import { filterClearIcon, printIcon } from "@progress/kendo-svg-icons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import _debounce from "lodash/debounce";
import { TextBox } from "@progress/kendo-react-inputs";

const Sales: React.FC = () => {
  const navigate = useNavigate();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  const handleCreate = () => {
    navigate("/sales/create");
  };

  return (
    <>
      <DeleteSalesDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Sales Invoice List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              {"Add New"}
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <SalesGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const SalesGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditBtnRef = useRef<any>(false);
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);

  const SalesList = useAppSelector((state) => state.sales.SalesList);
  const loading = useAppSelector((state) => state.sales.loading);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterSales: any = useAppSelector((state) => state.sales.FilterSales);

  useEffect(() => {
    return () => {
      dispatch(clearItemList());
      dispatch(setCurrentPage(0));

      if (!isEditBtnRef.current) {
        dispatch(clearFilterSales());
      }
    };
  }, []);

  useEffect(() => {
    const payload = {
      ...FilterSales,
      financial_year: FINANCIAL_YEAR,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllSales(payload));
  }, [currentPage, pageLimit]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (sale_guid: any) => {
      navigate("/sales/edit", {
        state: { sale_guid: sale_guid },
      });
    };

    const handleOpenDeleteDialog = (sale_guid: any) => {
      dispatch(openDialog("deleteSales"));
      dispatch(setSalesID(sale_guid));
    };

    const handleSalesPrint = (sale_guid: any) => {
      window.open(`/salesprint/${sale_guid}`);
    };
    const handleGatePassPrint = (sale_guid: any) => {
      window.open(`/sales/gatepass/${sale_guid}`);
    };
    const handleEnvelopePrint = (sale_guid: any) => {
      window.open(`/sales/envelope/${sale_guid}`);
    };

    const handleItemClick = (event: any) => {
      const clickedItem = event?.item?.text;

      if (clickedItem === "Sales Invoice") {
        handleSalesPrint(props.dataItem?.sale_guid);
      } else if (clickedItem === "Gate Pass") {
        handleGatePassPrint(props.dataItem?.sale_guid);
      } else if (clickedItem === "Envelope") {
        handleEnvelopePrint(props.dataItem?.sale_guid);
      }
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            ref={isEditBtnRef}
            onClick={() => {
              isEditBtnRef.current = true;
              handleEdit(props.dataItem?.sale_guid);
            }}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem?.sale_guid)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
        <Tooltip anchorElement="target" position="top" parentTitle={true}>
          <DropDownButton
            className="sales-printing-opt-btn"
            svgIcon={printIcon}
            fillMode={"flat"}
            title="Print"
            themeColor={"primary"}
            onItemClick={handleItemClick}
            popupSettings={{
              anchorAlign: { horizontal: "right", vertical: "bottom" },
              popupAlign: { horizontal: "center", vertical: "top" },
            }}
          >
            <DropDownButtonItem text="Sales Invoice" />
            <DropDownButtonItem text="Gate Pass" />
          </DropDownButton>
        </Tooltip>
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  // if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)" }}
        filterable={true}
        filter={filter}
        data={filter ? filterBy(SalesList, filter) : SalesList}
        onFilterChange={handleFilterChange}
        total={SalesList[0]?.itemcount || SalesList?.length}
        skip={currentPage * pageLimit}
        take={pageLimit}
        pageable={{
          buttonCount: 5,
          pageSizes: [10, 50, 100, 300, 500],
          pageSizeValue: pageLimit,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="invoice_no_string"
          title="Invoice No"
          cell={(props: any) => (
            <td>
              <div
                className={isEditAccess ? "hoverableName" : "normalName"}
                onClick={() =>
                  isEditAccess &&
                  navigate("/sales/edit", {
                    state: {
                      sale_guid: props.dataItem?.sale_guid,
                    },
                  })
                }
              >
                {props.dataItem?.invoice_no_string}
              </div>
            </td>
          )}
        />
        <Column field="invoice_date" title="Invoice Date" />
        <Column
          field="account_name"
          title="Account Name"
          filterCell={CommonFilterCell}
        />
        <Column
          field="total_amount"
          title="Total"
          cell={(props: any) => (
            <td className="text-end">
              {props.dataItem?.total_amount
                ? formatIndianNumber(props.dataItem?.total_amount)
                : null}
            </td>
          )}
        />
        {(isEditAccess || isDeleteAccess || isPrintAccess) && (
          <Column
            field="id"
            title="Actions"
            cell={MyEditCommandCell}
            width={"140px"}
            filterable={false}
          />
        )}
      </KendoGrid>
    </>
  );
};

const DeleteSalesDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const SalesID = useAppSelector((state) => state.sales.SalesID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterSales: any = useAppSelector((state) => state.sales.FilterSales);

  const handleDeleteAction = async (ID: any) => {
    if (ID) {
      const response = await dispatch(deleteSales(ID));
      dispatch(setCurrentPage(0));
      const payload = {
        ...FilterSales,
        financial_year: FINANCIAL_YEAR,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload.Data.Status === 200) {
        dispatch(getAllSales(payload));
        dispatch(closeDialog());
        dispatch(clearSalesID());
      } else {
        dispatch(getAllSales(payload));
        dispatch(closeDialog());
        dispatch(clearSalesID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteSales" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Sales "}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Sales ?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(SalesID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const CommonFilterCell = (props: any) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterSales: any = useAppSelector((state) => state.sales.FilterSales);
  const isFilterRef = useRef(true);
  const [inputValue, setInputValue] = useState(props.value || "");
  const [inputField, setInputField] = useState(props.field || "");

  const onChange = (event: any) => {
    setInputValue(event.target.value);
    setInputField(event.target.name);
  };

  const delayedApiCall = React.useCallback(
    _debounce((updatedFilters: any) => {
      dispatch(setCurrentPage(0));
      const payload = {
        ...updatedFilters,
        financial_year: FINANCIAL_YEAR,
        limit: +pageLimit,
        skip: 0 * +pageLimit,
      };

      dispatch(getAllSales(payload));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    if (!isFilterRef.current) {
      const updatedFilters = { ...FilterSales, [inputField]: inputValue };
      dispatch(setFilterSales(updatedFilters));
      delayedApiCall(updatedFilters);
    } else {
      isFilterRef.current = false;
    }
    return () => {
      delayedApiCall.cancel();
    };
  }, [inputValue, delayedApiCall]);

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });

    const updatedFilters = { ...FilterSales, [props.field]: "" };
    dispatch(setFilterSales(updatedFilters));

    const payload = {
      ...updatedFilters,
      financial_year: FINANCIAL_YEAR,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };

    dispatch(getAllSales(payload));
  };

  return (
    <div className="d-flex">
      <TextBox
        value={FilterSales[props.field] || ""}
        name={props.field || ""}
        onChange={onChange}
      />
      <Button
        svgIcon={filterClearIcon}
        title="Clear"
        disabled={!FilterSales[props.field]}
        onClick={onClearButtonClick}
        type="button"
        style={{
          marginLeft: "8px",
        }}
      ></Button>
    </div>
  );
};

export default Sales;
