import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { axiosApiInstance } from "../../app/axios";
import { API_URL } from "../../app/config";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { useLocation } from "react-router-dom";
import { IUnit } from "../unit/unitModel";
import { setInwardDeleteIndex } from "./inwardSlice";
import { IWarehouse } from "../warehouse/warehouseModel";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { getItemById } from "../Item/services/item.services";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import _ from "lodash";

const InwardItemDetailsArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const InputRef = React.useRef<any>(null);
  const location = useLocation();
  const inward_guid = location.state?.inward_guid;

  const InwardDeleteIndex = useAppSelector(
    (state) => state.inward.InwardDeleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const UnitList = useAppSelector((state) => state.unit.UnitList);
  const warehouseList = useAppSelector(
    (state) => state.warehouse.warehouseList
  );

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("inwardItemDeleteDialog"));
    dispatch(setInwardDeleteIndex(index));
  };

  useEffect(() => {
    if (!inward_guid) {
      pushElementInarray();
    }
  }, [inward_guid]);

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index].id) {
        try {
          const response = await axiosApiInstance.post(
            `${API_URL}/Inward/DeleteInwardItem`,
            { id: fieldArrayRenderProps?.value[index].id }
          );
          fieldArrayRenderProps.onRemove({ index: index });
          dispatch(closeDialog());
          dispatch(setInwardDeleteIndex(-1));
          SuccessToast(response.data?.Details || "Success");
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Item:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setInwardDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        item_id: null,
        dagina: null,
        weight: null,
        rate: null,
        total: null,
        cold_storage_id: null,
        receipt_no: "",
        unit_id: null,
      },
    });

    setTimeout(() => {
      InputRef.current?.element?.children[0]?.children[1]?.children[0]?.focus();
    }, 0);
  }, [fieldArrayRenderProps]);

  const fetchItemDetails = async (
    item_id: number,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    if (item_id) {
      const payload = {
        id: item_id,
      };
      const res = (await dispatch(getItemById(payload))) as any;
      fieldArrayRenderProps.formRenderProps.onChange(
        `inward_items.${index}.unit_id`,
        {
          value: res.payload?.unit_id,
        }
      );
    } else {
      fieldArrayRenderProps.formRenderProps.onChange(
        `inward_items.${index}.unit_id`,
        {
          value: null,
        }
      );
    }
  };

  const debouncedHandleProductChange = React.useRef(
    _.debounce((item_id: any, index: number, fieldArrayRenderProps: any) => {
      fetchItemDetails(item_id, index, fieldArrayRenderProps);
    }, 300)
  ).current;

  useEffect(() => {
    return () => {
      debouncedHandleProductChange.cancel();
    };
  }, []);

  const handleChange = (
    item_id: any,
    index: number,
    fieldArrayRenderProps: any
  ) => {
    debouncedHandleProductChange(item_id, index, fieldArrayRenderProps);
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {/* {!fieldArrayRenderProps.value ? pushElementInarray() : null} */}
      <GridLayoutItem className="bg-dark text-white fw-600">
        <GridLayout
          style={{ marginRight: 40, padding: 10 }}
          gap={{ rows: 10, cols: 5 }}
          cols={
            inward_guid
              ? [
                  { width: "18%" },
                  { width: "7%" },
                  { width: "10%" },
                  { width: "12%" },
                  { width: "10%" },
                  { width: "6%" },
                  { width: "10%" },
                  { width: "15%" },
                  { width: "9%" },
                  { width: "3%" },
                ]
              : [
                  { width: "20%" },
                  { width: "10%" },
                  { width: "12%" },
                  { width: "10%" },
                  { width: "8%" },
                  { width: "12%" },
                  { width: "15%" },
                  { width: "10%" },
                  { width: "3%" },
                ]
          }
        >
          <GridLayoutItem>Item Name</GridLayoutItem>
          {inward_guid && <GridLayoutItem>GRN No</GridLayoutItem>}
          <GridLayoutItem>Qty (Weight)</GridLayoutItem>
          <GridLayoutItem>Unit</GridLayoutItem>
          <GridLayoutItem>Rate</GridLayoutItem>
          <GridLayoutItem>Dagina</GridLayoutItem>
          <GridLayoutItem>Amount</GridLayoutItem>
          <GridLayoutItem>Warehouse</GridLayoutItem>
          <GridLayoutItem>Warehouse Receipt No.</GridLayoutItem>
          <GridLayoutItem></GridLayoutItem>
        </GridLayout>
      </GridLayoutItem>
      {dialogName === "inwardItemDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Item"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(InwardDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((inward_items: any, index: number) => (
          <GridLayoutItem key={index} style={{ padding: 10 }}>
            {/* <Card className="fieldarray-card" style={{ padding: 10 }}> */}
            <GridLayout
              style={{ marginRight: 40 }}
              gap={{ rows: 10, cols: 5 }}
              cols={
                inward_guid
                  ? [
                      { width: "18%" },
                      { width: "7%" },
                      { width: "10%" },
                      { width: "12%" },
                      { width: "10%" },
                      { width: "6%" },
                      { width: "10%" },
                      { width: "15%" },
                      { width: "9%" },
                      { width: "3%" },
                    ]
                  : [
                      { width: "20%" },
                      { width: "10%" },
                      { width: "12%" },
                      { width: "10%" },
                      { width: "8%" },
                      { width: "12%" },
                      { width: "15%" },
                      { width: "10%" },
                      { width: "3%" },
                    ]
              }
            >
              <GridLayoutItem ref={InputRef}>
                <Field
                  wrapperStyle={{ margin: 0 }}
                  name={`inward_items.${index}.item_id`}
                  placeholder="Item"
                  isAddNew={true}
                  addNewLink="item/create"
                  component={FormIncrementalSearch}
                  validator={requiredValidator}
                  onChange={(e) => {
                    handleChange(e?.value, index, fieldArrayRenderProps);
                    // fetchItemDetails(e?.value, index, fieldArrayRenderProps);
                  }}
                  fetchIncrementalData={(search: any) =>
                    fieldArrayRenderProps.handleItemSearchChange(
                      search,
                      `inward_items.${index}.item_options`,
                      fieldArrayRenderProps.formRenderProps
                    )
                  }
                  options={
                    fieldArrayRenderProps.value[index]?.item_options?.map(
                      (item: any) => {
                        return {
                          value: item.id,
                          label: item.product_name,
                        };
                      }
                    ) || []
                  }
                />
              </GridLayoutItem>
              {inward_guid && (
                <GridLayoutItem>
                  <Field
                    wrapperStyle={{ margin: 0 }}
                    name={`inward_items.${index}.grn_no_string`}
                    placeholder="GRN No"
                    // type="number"
                    // minNumber="0"
                    disabled={true}
                    component={FormTextField}
                  />
                </GridLayoutItem>
              )}
              <GridLayoutItem>
                <Field
                  wrapperClassName="right-alighned-field"
                  wrapperStyle={{ margin: 0 }}
                  name={`inward_items.${index}.weight`}
                  placeholder="0"
                  // format="n2"
                  validator={requiredValidator}
                  component={FormNumericTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperStyle={{ margin: 0 }}
                  name={`inward_items.${index}.unit_id`}
                  placeholder="Unit"
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={UnitList?.map((unit: IUnit) => {
                    return {
                      value: unit?.id,
                      label: unit?.unit_code,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="right-alighned-field"
                  wrapperStyle={{ margin: 0 }}
                  name={`inward_items.${index}.rate`}
                  placeholder="0"
                  type="number"
                  minNumber="0"
                  validator={requiredValidator}
                  component={FormNumericTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="right-alighned-field"
                  wrapperStyle={{ margin: 0 }}
                  name={`inward_items.${index}.dagina`}
                  placeholder="0"
                  type="number"
                  minNumber="0"
                  validator={requiredValidator}
                  component={FormNumericTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="child-calculation-total right-alighned-field"
                  wrapperStyle={{ margin: 0 }}
                  name={`inward_items.${index}.total`}
                  placeholder="0"
                  disabled="true"
                  format="n2"
                  component={FormNumericTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperStyle={{ margin: 0 }}
                  name={`inward_items.${index}.cold_storage_id`}
                  placeholder="Warehouse"
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={warehouseList?.map((warehouse: IWarehouse) => {
                    return {
                      value: warehouse?.id,
                      label: warehouse?.warehouse_name,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperStyle={{ margin: 0 }}
                  name={`inward_items.${index}.receipt_no`}
                  placeholder="0"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ position: "relative" }}>
                <IconButton
                  onClick={() => handleOpenDeleteDialog(index)}
                  disabled={fieldArrayRenderProps.value.length === 1}
                  themeColor={"error"}
                  size={"small"}
                  fillMode={"solid"}
                  type="button"
                  style={{
                    position: "absolute",
                    height: 32,
                    width: 32,
                    top: 1,
                    right: 0,
                  }}
                >
                  <MdDelete
                    className="absolute-position"
                    style={{ fontSize: "16px" }}
                  />
                </IconButton>
              </GridLayoutItem>
            </GridLayout>
            {/* </Card> */}
          </GridLayoutItem>
        ))}

      <GridLayoutItem
        style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
      >
        <GridLayout
          style={{ marginRight: 40, padding: 10 }}
          gap={{ rows: 10, cols: 5 }}
          cols={
            inward_guid
              ? [
                  { width: "18%" },
                  { width: "7%" },
                  { width: "10%" },
                  { width: "12%" },
                  { width: "10%" },
                  { width: "6%" },
                  { width: "10%" },
                  { width: "15%" },
                  { width: "9%" },
                  { width: "3%" },
                ]
              : [
                  { width: "20%" },
                  { width: "10%" },
                  { width: "12%" },
                  { width: "10%" },
                  { width: "8%" },
                  { width: "12%" },
                  { width: "15%" },
                  { width: "10%" },
                  { width: "3%" },
                ]
          }
        >
          <GridLayoutItem
            className="d-flex align-items-end"
            colSpan={inward_guid ? 4 : 3}
          >
            <Button
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={pushElementInarray}
              type="button"
            >
              Add Row
            </Button>
          </GridLayoutItem>
          <GridLayoutItem
            colSpan={2}
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              fontWeight: 600,
              fontSize: 17,
            }}
          >
            Total Amount :
          </GridLayoutItem>
          <GridLayoutItem>
            <Field
              wrapperClassName="parent-calculation-label right-alighned-field"
              wrapperStyle={{ margin: 0 }}
              name="total_amount"
              format="n2"
              component={FormNumericTextField}
              disabled="true"
            />
          </GridLayoutItem>
        </GridLayout>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default InwardItemDetailsArray;
