import React, { useEffect, useRef } from "react";
import {
  GridLayout,
  GridLayoutItem,
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
// import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";

import {
  clearEmployeeLeaveGUID,
  setEmployeeLeaveGUID,
  setSelectedTab,
} from "./employeeLeaveSlice";
import { FiEdit } from "react-icons/fi";
import { checkAcessRights } from "../../_helper/helper";
import { EDIT_ACCESS, LEAVE_STATUS } from "../../_contstants/common";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import {
  getAllEmployeeLeave,
  updateEmployeeLeaveStatus,
} from "./services/employeeLeave.services";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextArea from "../../components/formFields/FormTextArea";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";

export const AdminLeave: React.FC = () => {
  const dispatch = useAppDispatch();
  const SelectedTab = useAppSelector(
    (state) => state.employeeLeave.SelectedTab
  );
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const handleSelect = (e: TabStripSelectEventArguments) => {
    dispatch(setSelectedTab(e.selected));
  };
  const statusMap: { [key: number]: string } = {
    0: "pending",
    1: "approved",
    2: "rejected",
    3: "all",
  };
  useEffect(() => {
    const FilterPlayload = {
      // apply_to_reporting_id: Number(localStorage.getItem("UserID")) || 0,
      //   apply_to_reporting_id: 0,
      leavestatus: statusMap[SelectedTab] || "",
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllEmployeeLeave(FilterPlayload));
    return () => {
      //   dispatch(closeDialog());
      //   dispatch(clearEmployeeLeaveDetails());
    };
  }, [SelectedTab]);
  return (
    <>
      <ApprovedEmployeeLeaveDialog />
      <Typography.h4 style={{ marginBottom: 10, marginLeft: "3px" }}>
        Employee Leave List
      </Typography.h4>
      <TabStrip selected={SelectedTab} onSelect={handleSelect}>
        <TabStripTab title="Pending">
          <AdminLeaveGridComponent />
        </TabStripTab>
        <TabStripTab title="Approved">
          <AdminLeaveGridComponent />
        </TabStripTab>
        <TabStripTab title="Rejected">
          <AdminLeaveGridComponent />
        </TabStripTab>
        <TabStripTab title="All">
          <AdminLeaveGridComponent />
        </TabStripTab>
      </TabStrip>
    </>
  );
};

const AdminLeaveGridComponent: React.FC = () => {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  //   const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const EmployeeLeaveList = useAppSelector(
    (state) => state.employeeLeave.EmployeeLeaveList
  );
  const loading = useAppSelector((state) => state.employeeLeave.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    // const handleEditEmployeeLeave = (ID: number) => {
    //   navigate("/employeeLeave/edit", {
    //     state: { employee_leave_guid: ID, id: props.dataItem?.id },
    //   });
    // };
    const handleOpenApprovedDialog = (ID: number) => {
      dispatch(openDialog("ApprovedEmployeeLeave"));
      dispatch(setEmployeeLeaveGUID(ID));
    };
    return (
      <td className="action-td">
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Leave Status"
            disabled={props.dataItem.leavestatus !== "pending"}
            onClick={() =>
              handleOpenApprovedDialog(props.dataItem.employee_leave_guid)
            }
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {/* {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() =>
              handleOpenDeleteDialog(props.dataItem.employee_leave_guid)
            }
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )} */}
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 5 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  // const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
  //   const [IsActive, setIsActive] = useState<boolean>(props.dataItem?.isactive);

  //   const handleActiveInActive = () => {
  //     setIsActive(!IsActive);
  //     const data = {
  //       employee_leave_guid: props.dataItem.employee_leave_guid,
  //       isactive: !IsActive,
  //     };
  //     dispatch(EmployeeLeaveActiveInactive(data)).then((response: any) => {
  //       if (response.payload?.data?.Status === 200) {
  //         dispatch(getAllEmployeeLeaves());
  //       } else {
  //         dispatch(getAllEmployeeLeaves());
  //       }
  //     });
  //   };

  //   return (
  //     <td style={{ textAlign: "center" }}>
  //       <Tooltip anchorElement="target" position="top" parentTitle={true}>
  //         <div title={`Set ${IsActive ? "Inactive" : "Active"}.`}>
  //           <Switch
  //             disabled={isEditAccess ? false : true}
  //             name="isactive"
  //             checked={IsActive}
  //             onChange={handleActiveInActive}
  //             size="small"
  //           />
  //         </div>
  //       </Tooltip>
  //     </td>
  //   );
  // };
  const gridRef = useRef<any>(null);
  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {EmployeeLeaveList && EmployeeLeaveList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            (filter
              ? filterBy(EmployeeLeaveList, filter)
              : EmployeeLeaveList) || []
          }
          onFilterChange={handleFilterChange}
          total={EmployeeLeaveList[0]?.itemcount || EmployeeLeaveList?.length}
          skip={currentPage * pageLimit}
          take={pageLimit}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 50, 100, 300, 500],
            pageSizeValue: pageLimit,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="employee_name"
            title="Employee Name"
            cell={(props: any) => (
              <td>
                <div
                  className={isEditAccess ? "hoverableName" : "normalName"}
                  onClick={() => {
                    if (isEditAccess) {
                      dispatch(openDialog("ApprovedEmployeeLeave"));
                      dispatch(setEmployeeLeaveGUID(props.dataItem.employee_leave_guid));
                    }
                  }}
                >
                  {props.dataItem?.employee_name}
                </div>
              </td>
            )}
          />
          <Column field="leave_type" title="Leave Type" />
          <Column field="from_date" title="From Date" />
          <Column field="to_date" title="To Date" />
          <Column field="days" title="Total Days" />
          <Column field="apply_to_reporting" title="Apply To" />
          <Column field="reason" title="Reason" />
          <Column
            field="leavestatus"
            title="Leave Status"
            cell={(props: any) => (
              <td>
                <div>
                  {props.dataItem?.leavestatus === "pending" && (
                    <span
                      style={{
                        backgroundColor: "rgb(254, 240, 199)",
                        color: "rgb(128, 55, 23)",
                        padding: "3px 10px",
                        textTransform: "capitalize",
                        borderRadius: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {props.dataItem?.leavestatus}
                    </span>
                  )}
                  {props.dataItem?.leavestatus === "Approved" && (
                    <span
                      style={{
                        backgroundColor: "#D1FADF",
                        color: "#065032",
                        padding: "3px 10px",
                        textTransform: "capitalize",
                        borderRadius: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {props.dataItem?.leavestatus}
                    </span>
                  )}{" "}
                  {props.dataItem?.leavestatus === "Rejected" && (
                    <span
                      style={{
                        backgroundColor: "rgb(255, 200, 200)",
                        color: "rgb(255, 0, 20)",
                        padding: "3px 10px",
                        textTransform: "capitalize",
                        borderRadius: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {props.dataItem?.leavestatus}
                    </span>
                  )}
                </div>
              </td>
            )}
          />
          {/* <Column
              field="isactive"
              title="Status"
              cell={MyActiveInActtiveCommandCell}
              width={"90px"}
              filterable={false}
              locked={true}
            /> */}
          {isEditAccess && (
            <Column
              field="employee_leave_guid"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
              locked={true}
            />
          )}

          {/* {(isEditAccess || isDeleteAccess) && (
            <Column
              field="employee_leave_guid"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
              locked={true}
            />
          )} */}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const ApprovedEmployeeLeaveDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const [formKey, setFormKey] = React.useState(1);
  const EmployeeLeaveGUID = useAppSelector(
    (state) => state.employeeLeave.EmployeeLeaveGUID
  );
  const EmployeeLeaveStatusDialog = useAppSelector(
    (state) => state.employeeLeave.EmployeeLeaveStatusDialog
  );

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [EmployeeLeaveStatusDialog]);

  const formLoading = useAppSelector(
    (state) => state.employeeLeave.formLoading
  );
  const SelectedTab = useAppSelector(
    (state) => state.employeeLeave.SelectedTab
  );
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  const statusMap: { [key: number]: string } = {
    0: "pending",
    1: "approved",
    2: "rejected",
    3: "all",
  };
  const FilterPlayload = {
    //  apply_to_reporting_id: Number(localStorage.getItem("UserID")) || 0,
    leavestatus: statusMap[SelectedTab] || "",
    limit: +pageLimit,
    skip: +currentPage * +pageLimit,
  };
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleSubmit = async (values: any) => {
    const formdata = {
      employee_leave_guid: EmployeeLeaveGUID,
      leavestatus: values?.leavestatus,
      remark: values?.remark,
    };
    if (EmployeeLeaveGUID) {
      try {
        const response = await dispatch(updateEmployeeLeaveStatus(formdata));
        if (response?.payload?.Status === 200) {
          dispatch(getAllEmployeeLeave(FilterPlayload));
          dispatch(closeDialog());
          dispatch(clearEmployeeLeaveGUID());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  return (
    <>
      {dialogName === "ApprovedEmployeeLeave" && (
        <AppDialog className="employee_leave_status_dialog">
          <>
            <Typography.h5>{"Employe Leave Status"}</Typography.h5>
            <Form
              key={formKey}
              onSubmit={handleSubmit}
              initialValues={EmployeeLeaveStatusDialog}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement>
                  <GridLayout gap={{ rows: 10, cols: 10 }}>
                    <GridLayoutItem>
                      <Field
                        name="leavestatus"
                        label="Leave Status"
                        placeholder="Select Leave Status"
                        component={FormSelectionField}
                        // validator={requiredValidator}
                        // astrike={true}
                        options={LEAVE_STATUS.map((status: any) => {
                          return {
                            value: status?.value,
                            label: status?.label,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        name="remark"
                        label="Remark"
                        placeholder="Remark..."
                        component={FormTextArea}
                        // validator={emailValidator}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: 10,
                      }}
                    >
                      <ButtonWithLoading
                        label={"Submit"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || formLoading}
                        loading={formLoading}
                        buttonStyle={{ marginRight: 10 }}
                      />
                      <DialogCloseButton themeColor="error" />
                    </GridLayoutItem>
                  </GridLayout>
                </FormElement>
              )}
            />
          </>
        </AppDialog>
      )}
    </>
  );
};

export default AdminLeave;
