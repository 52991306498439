import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { LoadingPanel } from "../../components/layout/Loading";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";

import FormSelectionField from "../../components/formFields/FormSelectionField";
import { MONTHS } from "../../_contstants/common";
import {
  convertNumToIndianCurrency,
  getYearsArray,
  PercentageValue,
} from "../../_helper/helper";
import {
  clearSalaryGenerationDetail,
  setUpdateSalaryDetails,
} from "./salryGenerationSlice";
import {
  getEmployeeSalaryByIDDaysMonthYear,
  getEmployeeSalaryDaysByIDMonthYear,
  updateEmployeeSalaryGeneration,
} from "./services/salaryGeneration.services";
import { getAllActiveEmployee } from "../employee/services/employee.services";
import { IEmployee } from "../employee/employeeModel";
import FormTextField from "../../components/formFields/FormTextField";
import FormTextArea from "../../components/formFields/FormTextArea";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Button } from "@progress/kendo-react-buttons";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { SuccessToast } from "../../components/toast/Toasts";
interface SalaryChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
interface SalaryGenerationChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const SalaryGenerationChangeWatcher: React.FC<
  SalaryGenerationChangeWatcherProps
> = ({ formRenderProps }) => {
  const PayoutMonth = formRenderProps.valueGetter("payout_month")?.toString();
  const PayoutYear = formRenderProps.valueGetter("payout_year");
  const EmployeeID = formRenderProps.valueGetter("employee_id");

  const TotalDays = +formRenderProps.valueGetter("total_days") || 0;
  const WorkingDays = +formRenderProps.valueGetter("working_days") || 0;
  const PresetDays = +formRenderProps.valueGetter("present_days") || 0;

  const dispatch = useAppDispatch();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutRefTwo = useRef<NodeJS.Timeout | null>(null);

  const updateFormFields = (Obj: Object) => {
    Object.keys(Obj).forEach((key) => {
      formRenderProps.onChange(key, {
        value: Obj[key as keyof typeof Obj],
      });
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      if (PayoutMonth && PayoutYear && EmployeeID) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(async () => {
          const payload = {
            employee_id: EmployeeID,
            payout_month: PayoutMonth,
            payout_year: PayoutYear,
          };
          const response = await dispatch(
            getEmployeeSalaryDaysByIDMonthYear(payload)
          );
          if (response.meta.requestStatus === "fulfilled") {
            updateFormFields(response?.payload);
            const total_days = response?.payload.total_days;
            const working_days = response?.payload.working_days;
            const present_days = response?.payload.present_days;
            if (
              EmployeeID &&
              PayoutMonth &&
              PayoutYear &&
              total_days &&
              working_days &&
              present_days
            ) {
              const SalaryPayload = {
                employee_id: EmployeeID,
                payout_month: PayoutMonth,
                payout_year: PayoutYear,
                total_days,
                working_days,
                present_days,
              };
              const res = await dispatch(
                getEmployeeSalaryByIDDaysMonthYear(SalaryPayload)
              );
              if (res.meta.requestStatus === "fulfilled") {
                updateFormFields(res?.payload);
              }
            }
          }
        }, 300);
      }
    };
    fetchData();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [PayoutMonth, PayoutYear, EmployeeID, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      if (TotalDays && WorkingDays && PresetDays) {
        if (timeoutRefTwo.current) {
          clearTimeout(timeoutRefTwo.current);
        }
        timeoutRefTwo.current = setTimeout(async () => {
          const payload = {
            employee_id: EmployeeID,
            payout_month: PayoutMonth,
            payout_year: PayoutYear,
            total_days: TotalDays,
            working_days: WorkingDays,
            present_days: PresetDays,
          };
          const response = await dispatch(
            getEmployeeSalaryByIDDaysMonthYear(payload)
          );
          if (response.meta.requestStatus === "fulfilled") {
            updateFormFields(response?.payload);
          }
        }, 300);
      }
    };
    fetchData();
    return () => {
      if (timeoutRefTwo.current) {
        clearTimeout(timeoutRefTwo.current);
      }
    };
  }, [TotalDays, WorkingDays, PresetDays]);
  return null;
};

const SalaryChangeWatcher: React.FC<SalaryChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const Basic = +formRenderProps.valueGetter("basic") || 0;
  const DAPercent = +formRenderProps.valueGetter("da_per") || 0;
  const HRAPercent = +formRenderProps.valueGetter("hra_per") || 0;
  const PFPercent = +formRenderProps.valueGetter("pf_per") || 0;
  const ESIPercent = +formRenderProps.valueGetter("esi_per") || 0;

  const DA = +formRenderProps.valueGetter("da") || 0;
  const HRA = +formRenderProps.valueGetter("hra") || 0;
  const Wash = +formRenderProps.valueGetter("wash") || 0;
  const Bonus = +formRenderProps.valueGetter("adv_bonus") || 0;
  const Leave = +formRenderProps.valueGetter("adv_leave") || 0;
  const Food = +formRenderProps.valueGetter("food") || 0;
  const TotalIncome = +formRenderProps.valueGetter("total_income") || 0;
  const TotalDeduction = +formRenderProps.valueGetter("total_deduction") || 0;

  const PF = +formRenderProps.valueGetter("pf") || 0;
  const ESI = +formRenderProps.valueGetter("esi") || 0;
  const PT = +formRenderProps.valueGetter("pt") || 0;
  const IT = +formRenderProps.valueGetter("it") || 0;
  const LWF = +formRenderProps.valueGetter("lwf") || 0;
  const Advance = +formRenderProps.valueGetter("advance") || 0;
  const Loan = +formRenderProps.valueGetter("loan") || 0;
  const Other = +formRenderProps.valueGetter("other") || 0;
  useEffect(() => {
    // const TotalIncome = new Intl.NumberFormat("en-IN", {
    //   style: "currency",
    //   currency: "INR",
    // }).format(+(Basic + DA + HRA + Wash + Bonus + Leave + Food).toFixed(2));
    formRenderProps.onChange("da", {
      value: (Basic * (DAPercent / 100))?.toFixed(2),
    });
    formRenderProps.onChange("hra", {
      value: ((Basic + DA) * (HRAPercent / 100))?.toFixed(2),
    });
    formRenderProps.onChange("pf", {
      value: ((Basic + DA) * (PFPercent / 100))?.toFixed(2),
    });
    formRenderProps.onChange("esi", {
      value: ((Basic + DA) * (ESIPercent / 100)).toFixed(2),
    });
    formRenderProps.onChange("total_income", {
      value: (Basic + DA + HRA + Wash + Bonus + Leave + Food).toFixed(2),
    });
  }, [
    Basic,
    DAPercent,
    HRAPercent,
    DA,
    PFPercent,
    ESIPercent,
    HRA,
    Wash,
    Bonus,
    Leave,
    Food,
  ]);

  useEffect(() => {
    formRenderProps.onChange("total_deduction", {
      value: (PF + ESI + PT + IT + LWF + Advance + Loan + Other).toFixed(2),
    });
  }, [PF, ESI, PT, IT, LWF, Advance, Loan, Other]);

  useEffect(() => {
    const PTCal = TotalIncome > 12000 ? 200 : 0;
    formRenderProps.onChange("pt", {
      value: PTCal,
    });
  }, [TotalIncome]);

  useEffect(() => {
    formRenderProps.onChange("payable_amount", {
      value: +(TotalIncome - TotalDeduction).toFixed(),
    });
  }, [TotalIncome, TotalDeduction]);

  useEffect(() => {
    formRenderProps.onChange("da_per", { value: PercentageValue(DAPercent) });
    formRenderProps.onChange("hra_per", { value: PercentageValue(HRAPercent) });
    formRenderProps.onChange("pf_per", { value: PercentageValue(PFPercent) });
    formRenderProps.onChange("esi_per", { value: PercentageValue(ESIPercent) });
  }, [DAPercent, HRAPercent, PFPercent, ESIPercent]);

  return null;
};

const SalaryGeneration = () => {
  // const location = useLocation();
  //   const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const [formKey, setFormKey] = useState(1);
  const loading = useAppSelector((state) => state.processpayroll.loading);
  const SalaryGenerationDetail = useAppSelector(
    (state) => state.salarygeneration.SalaryGenerationDetail
  );
  const EmployeeList = useAppSelector((state) => state.employee.EmployeeList);
  useEffect(() => {
    dispatch(getAllActiveEmployee());
    return () => {
      dispatch(clearSalaryGenerationDetail());
    };
  }, []);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [SalaryGenerationDetail]);

  const handleSubmit = async (values: any) => {
    try {
      const payload = {
        employee_id: values?.employee_id,
        payout_month: values?.payout_month
          ? values?.payout_month?.toString()
          : "",
        payout_year: values?.payout_year,
        total_days: values?.total_days?.toString(),
        working_days: values?.working_days?.toString(),
        present_days: values?.present_days?.toString(),
        basic: +values?.basic,
        da_per: +values?.da_per,
        da: +values?.da,
        hra_per: +values?.hra_per,
        hra: +values?.hra,
        wash: +values?.wash,
        adv_bonus: +values?.adv_bonus,
        adv_leave: +values?.adv_leave,
        food: +values?.food,
        pf_per: +values?.pf_per,
        pf: +values?.pf,
        esi_per: +values?.esi_per,
        esi: +values?.esi,
        pt: +values?.pt,
        it: +values?.it,
        lwf: +values?.lwf,
        advance: +values?.advance,
        loan: +values?.loan,
        other: +values?.other,
        remarks: values?.remarks,
        total_income: +values?.total_income,
        total_deduction: +values?.total_deduction,
        payable_amount: +values?.payable_amount,
        isstatus: false,
        // apply_from_date:values?.apply_from_date,
      };
      const response = await dispatch(updateEmployeeSalaryGeneration(payload));
      if (response.payload?.isstatus === false) {
        const updatePayload = {
          ...payload,
          isstatus: true,
        };
        dispatch(setUpdateSalaryDetails(updatePayload));
        dispatch(openDialog("updateGeneratedSalary"));
      } else {
        SuccessToast(response.payload?.Details || "Success");
        setFormKey(formKey + 1);
        dispatch(clearSalaryGenerationDetail());
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };


  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <UpdateGeneratedSalaryDialog setFormKey={setFormKey} />
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={SalaryGenerationDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <Typography.h4>Salary Generation</Typography.h4>
              <div className="container p-0" style={{ maxWidth: 850 }}>
                <GridLayout
                  style={{ marginRight: 40 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  <GridLayoutItem colSpan={4} className="d-flex gap-2">
                    <Field
                      name="payout_month"
                      label="Payout Month"
                      wrapperStyle={{ width: "33.33%" }}
                      placeholder="Payout Month"
                      component={FormSelectionField}
                      options={MONTHS.map((month: any) => ({
                        value: month.value,
                        label: month.label,
                      }))}
                      validator={requiredValidator}
                      astrike={true}
                    />
                    <Field
                      name="payout_year"
                      label="Payout Year"
                      wrapperStyle={{ width: "33.33%" }}
                      placeholder="Payout Year"
                      component={FormSelectionField}
                      options={getYearsArray(2000, new Date().getFullYear())
                        ?.reverse()
                        ?.map((year: any) => {
                          return {
                            value: year?.value,
                            label: year?.label,
                          };
                        })}
                      validator={requiredValidator}
                      astrike={true}
                    />
                    <Field
                      name="employee_id"
                      label="Employee"
                      wrapperStyle={{ width: "33.33%" }}
                      placeholder="Select Employee"
                      component={FormSelectionField}
                      options={EmployeeList.map((emp: IEmployee) => ({
                        value: emp.id,
                        label: emp.name,
                      }))}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={4} className="d-flex gap-2">
                    <Field
                      name="total_days"
                      label="Total Days"
                      placeholder="Total Days"
                      wrapperStyle={{ width: "33.33%" }}
                      component={FormTextField}
                      type="number"
                      disabled
                      validator={requiredValidator}
                      astrike={true}
                    />
                    <Field
                      name="working_days"
                      label="Working Days"
                      wrapperStyle={{ width: "33.33%" }}
                      placeholder="Working Days"
                      component={FormTextField}
                      type="number"
                      validator={requiredValidator}
                      astrike={true}
                    />
                    <Field
                      name="present_days"
                      label="Present Days"
                      wrapperStyle={{ width: "33.33%" }}
                      placeholder="Present Days"
                      component={FormTextField}
                      type="number"
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                  {/* Salary Structure */}
                  <GridLayoutItem
                    colSpan={4}
                    style={{
                      border: "1px solid #ccc",
                      // backgroundColor:"#000",
                      padding: 10,
                      borderRadius: 5,
                    }}
                  >
                    <GridLayout
                      style={{ marginRight: 10 }}
                      gap={{ rows: 10, cols: 10 }}
                      cols={[{ width: "50%" }, { width: "50%" }]}
                    >
                      {/* Earnings */}
                      <GridLayoutItem>
                        <GridLayout>
                          <GridLayoutItem>
                            <Typography.h5
                              style={{ textDecoration: "underline" }}
                            >
                              {"Earnings"}
                            </Typography.h5>
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="basic"
                              label="Basic"
                              component={FormTextField}
                              type="number"
                              placeholder="Basic"
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem className="d-flex gap-3">
                            <Field
                              name="da_per"
                              label="% DA"
                              // max={2}
                              wrapperStyle={{ width: "40%" }}
                              type="number"
                              placeholder="i.e. 5"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                            <Field
                              name="da"
                              label="DA"
                              disabled
                              wrapperStyle={{ width: "100%" }}
                              format="n2"
                              type="number"
                              placeholder="DA"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem className="d-flex gap-3">
                            <Field
                              name="hra_per"
                              label="% HRA"
                              // max={2}
                              wrapperStyle={{ width: "40%" }}
                              type="number"
                              placeholder="i.e. 5"
                              component={FormTextField}
                              //validator={requiredValidator}
                            />
                            <Field
                              name="hra"
                              label="HRA"
                              disabled
                              wrapperStyle={{ width: "100%" }}
                              type="number"
                              format="n2"
                              placeholder="HRA"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="wash"
                              label="Wash"
                              type="number"
                              placeholder="Wash"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="adv_bonus"
                              label="Bonus"
                              type="number"
                              placeholder="Bonus"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="adv_leave"
                              label="Advance Leave"
                              type="number"
                              placeholder="Advance Leave"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="food"
                              label="Food"
                              type="number"
                              placeholder="Food"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                        </GridLayout>
                      </GridLayoutItem>

                      {/* Deduction */}
                      <GridLayoutItem
                        style={{
                          borderLeft: "2px solid #000",
                          paddingLeft: 10,
                        }}
                      >
                        <GridLayout>
                          <GridLayoutItem>
                            <Typography.h5
                              style={{ textDecoration: "underline" }}
                            >
                              {"Deduction"}
                            </Typography.h5>
                          </GridLayoutItem>
                          <GridLayoutItem className="d-flex gap-3">
                            <Field
                              name="pf_per"
                              label="% PF"
                              type="number"
                              wrapperStyle={{ width: "40%" }}
                              placeholder="i.e. 5"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                            <Field
                              name="pf"
                              label="PF"
                              wrapperStyle={{ width: "100%" }}
                              disabled
                              type="number"
                              placeholder="PF"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem className="d-flex gap-3">
                            <Field
                              name="esi_per"
                              label="% ESI"
                              wrapperStyle={{ width: "40%" }}
                              type="number"
                              placeholder="i.e. 5"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                            <Field
                              name="esi"
                              label="ESI"
                              wrapperStyle={{ width: "100%" }}
                              type="number"
                              disabled
                              placeholder="ESI"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="pt"
                              label="PT"
                              type="number"
                              placeholder="PT"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="it"
                              label="IT"
                              type="number"
                              placeholder="IT"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="lwf"
                              label="LWF"
                              type="number"
                              placeholder="LWF"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="advance"
                              label="Advance"
                              type="number"
                              placeholder="Advance"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="loan"
                              label="Loan"
                              type="number"
                              placeholder="Loan"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="other"
                              label="Other"
                              type="number"
                              placeholder="Other"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                        </GridLayout>
                      </GridLayoutItem>
                    </GridLayout>
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={2}
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      backgroundColor: "#e7e6e6",
                      padding: "5px 20px",
                      borderRadius: "5px",
                      marginTop: 20,
                    }}
                  >
                    <Typography.h5 style={{ margin: 0 }}>
                      {" "}
                      Total Earning :
                    </Typography.h5>
                    <Typography.h4 style={{ margin: 0 }}>
                      {convertNumToIndianCurrency(
                        formRenderProps.valueGetter("total_income")
                      )}
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={2}
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      backgroundColor: "#e7e6e6",
                      padding: "5px 20px",
                      borderRadius: "5px",
                      marginLeft: 10,
                      marginTop: 20,
                    }}
                  >
                    <Typography.h5 style={{ margin: 0 }}>
                      Total Deduction :
                    </Typography.h5>
                    <Typography.h4 style={{ margin: 0 }}>
                      {convertNumToIndianCurrency(
                        formRenderProps.valueGetter("total_deduction")
                      )}
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={2}>
                    <Field
                      name="remarks"
                      label="Remarks"
                      placeholder="Remarks..."
                      component={FormTextArea}
                      rows={2}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={2}
                    style={{
                      padding: "13px 0 11px  9px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        backgroundColor: "rgb(231, 230, 230)",
                        padding: "5px 20px",
                        borderRadius: "5px",
                        marginTop: "20px",
                        minHeight: "68px",
                      }}
                    >
                      <Typography.h5 style={{ color: "green", margin: "0" }}>
                        Total Salary :
                      </Typography.h5>
                      <Typography.h4 style={{ color: "green", margin: "0" }}>
                        {convertNumToIndianCurrency(
                          formRenderProps.valueGetter("payable_amount")
                        )}
                      </Typography.h4>
                    </div>
                  </GridLayoutItem>

                  <SalaryChangeWatcher formRenderProps={formRenderProps} />
                  <SalaryGenerationChangeWatcher
                    formRenderProps={formRenderProps}
                  />
                  <GridLayoutItem
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                    }}
                    colSpan={4}
                  >
                    <div>
                      {/* <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginRight: 4 }}
                      onClick={() => navigate("/salarygeneration")}
                    >
                      Cancel
                    </Button> */}
                      <ButtonWithLoading
                        label={"Save"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                  {/* <DepartmentChangeWatcher
                  formRenderProps={formRenderProps}
                  setRowSelectionModel={setRowSelectionModel}
                  setSelectedState={setSelectedState}
                /> */}
                </GridLayout>
              </div>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

interface UpdateGeneratedSalaryDialogProps {
  setFormKey: React.Dispatch<React.SetStateAction<number>>;
}

const UpdateGeneratedSalaryDialog: React.FC<
  UpdateGeneratedSalaryDialogProps
> = ({ setFormKey }) => {
  const dispatch = useAppDispatch();
  const UpdateSalaryDetails = useAppSelector(
    (state) => state.salarygeneration.UpdateSalaryDetails
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleUpdateAction = async (formdata: any) => {
    const response = await dispatch(updateEmployeeSalaryGeneration(formdata));
    if (response?.meta.requestStatus === "fulfilled") {
      SuccessToast(response.payload?.Details || "Success");
      dispatch(closeDialog());
      setFormKey((prev) => prev + 1);
      dispatch(clearSalaryGenerationDetail());
    } else {
      dispatch(closeDialog());
    }
  };

  return (
    <>
      {dialogName === "updateGeneratedSalary" && (
        <AppDialog>
          <>
            <Typography.h5>
              {"The salary for this employee has already been generated."}
            </Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>Are you want to update it?</Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleUpdateAction(UpdateSalaryDetails)}
                  type="button"
                >
                  Update
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default SalaryGeneration;
