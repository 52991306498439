import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import moment from "moment";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllStockTransfers = createAsyncThunk(
  "StockTransfer/FindAllStockTransfer",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StockTransfer/FindAllStockTransfer`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Stock Transfers:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createStockTransfer = createAsyncThunk(
  "StockTransfer/InsertStockTransfer",
  async (StockTransferData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StockTransfer/InsertStockTransfer`,
        StockTransferData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating stock transfer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateStockTransfer = createAsyncThunk(
  "StockTransfer/UpdateStockTransfer",
  async (StockTransferData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StockTransfer/UpdateStockTransfer`,
        StockTransferData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Stock Transfer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteStockTransfer = createAsyncThunk(
  "StockTransfer/DeleteStockTransfer",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StockTransfer/DeleteStockTransfer`,
        { stock_transfer_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Stock Transfer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getStockTransferByID = createAsyncThunk(
  "StockTransfer/FindByIDStockTransfer",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StockTransfer/FindByIDStockTransfer`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        stock_transfer_guid: result?.stock_transfer_guid,
        stock_transfer_date: result.stock_transfer_date
          ? moment(result.stock_transfer_date, "YYYY-MM-DD").toDate()
          : "",
        stock_transfer_no: result?.stock_transfer_no,
        from_warehouse_id: result?.from_warehouse_id,
        from_warehouse: result?.from_warehouse,
        to_warehouse_id: result?.to_warehouse_id,
        to_warehouse: result?.to_warehouse,
        from_department_id: result?.from_department_id,
        to_department_id: result?.to_department_id,
        outward_no: result?.outward_no,
        vehicle_no: result?.vehicle_no,
        item_id: result?.item_id,
        item_options: result?.item_options || [],
        product_name: result?.product_name,
        grn_no: result?.grn_no,
        stock_qty: result?.stock_qty,
        vacal_no: result?.vacal_no,
        inward_no: result?.inward_no,
        inward_date: result.inward_date
          ? moment(result.inward_date, "YYYY-MM-DD").toDate()
          : "",
        financial_year: result?.financial_year,
        remarks: result?.remarks,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Stock Transfer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCurrentStock = createAsyncThunk(
  "StockTransfer/FindAllCurrentStock",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/StockTransfer/FindAllCurrentStock`,
        formData
      );
      return response.data?.Data as any;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Stock Transfer:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
