import React, { useEffect, useRef } from "react";
import {
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog } from "../../components/dialog/dialogSlice";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";
import { deleteProcess, getAllProcess } from "./services/process.services";
import { clearProcessID } from "./processSlice";
import CreateProcess from "./CreateProcess";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import AppDrawer from "../../components/muidrawer/Drawer";

const Process: React.FC = () => {
  const dispatch = useAppDispatch();
  const isDrawerOpen = useAppSelector((state) => state.muidrawer.open);
  const drawerName = useAppSelector((state) => state.muidrawer.name);

  useEffect(() => {
    dispatch(getAllProcess());
    return () => {
      dispatch(closeDialog());
      dispatch(closed());
    };
  }, []);

  return (
    <>
      <DeleteProcessDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Production Department List
          </Typography.h4>
          {/* <ButtonCreate label="Add Process" drawerName="processForm" /> */}
        </GridLayoutItem>
        <GridLayoutItem>
          <ItemGridComponent />
        </GridLayoutItem>
        {isDrawerOpen && drawerName === "processForm" && (
          <AppDrawer>
            <CreateProcess />
          </AppDrawer>
        )}
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const ItemGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  // const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  // const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const ProcessList = useAppSelector((state) => state.process.ProcessList);
  const loading = useAppSelector((state) => state.process.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? ProcessList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  // const MyEditCommandCell = (props: GridCellProps) => {
  //   const handleOpenDeleteDialog = (ID: number) => {
  //     dispatch(openDialog("deleteProcess"));
  //     dispatch(setProcessID(ID));
  //   };
  //   return (
  //     <td
  //       className="action-td"
  //       style={{
  //         ...props.style,
  //       }}
  //     >
  //       {isEditAccess && (
  //         <ButtonEdit ID={props.dataItem.process_guid} name="processForm" tooltipTitle="Edit"/>
  //       )}
  //       {isDeleteAccess && (
  //         <IconButton
  //           type="button"
  //           fillMode="flat"
  //           size="small"
  //           title="Delete"
  //           onClick={() => handleOpenDeleteDialog(props.dataItem.process_guid)}
  //         >
  //           <MdDelete
  //             className="absolute-position"
  //             style={{ fontSize: "24px" }}
  //           />
  //         </IconButton>
  //       )}
  //     </td>
  //   );
  // };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  // const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
  //   const [IsActive, setIsActive] = useState<boolean>(props.dataItem.isactive);

  //   const handleActiveInActive = () => {
  //     setIsActive(!IsActive);
  //     const data = {
  //       process_guid: props.dataItem.process_guid,
  //       isactive: !IsActive,
  //     };
  //     dispatch(ProcessActiveInactive(data)).then((response: any) => {
  //       if (response.payload?.data?.Status === 200) {
  //         dispatch(getAllProcess());
  //       } else {
  //         dispatch(getAllProcess());
  //       }
  //     });
  //   };

  //   return (
  //     <td style={{ textAlign: "center" }}>
  //       <Switch
  //         disabled={isEditAccess ? false : true}
  //         name="isactive"
  //         checked={IsActive}
  //         onChange={handleActiveInActive}
  //         size="small"
  //       />
  //     </td>
  //   );
  // };

  return (
    <>
      {ProcessList && ProcessList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(ProcessList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : ProcessList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={ProcessList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 25, 50, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="process_name"
            title="Production Department"
            cell={(props: any) => (
              <td className={"normalName"}>
                {/* <td className={isEditAccess ? "hoverableName" : "normalName"}> */}
                {/* {isEditAccess ? (
                  <ButtonEdit
                    ID={props.dataItem.process_guid}
                    name="processForm"
                    label={props.dataItem?.process_name}
                  />
                ) : ( */}
                {props.dataItem?.process_name}
                {/* )} */}
              </td>
            )}
          />
          <Column field="parent_process_name" title="Parent Process" />
          <Column
            field="remarks"
            title="Remarks"
            cell={(props: any) => (
              <td>
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.dataItem?.remarks,
                  }}
                />
              </td>
            )}
          />
          {/* <Column
            field="isactive"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          /> */}
          {/* {(isEditAccess || isDeleteAccess) && (
            <Column
              field="process_guid"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
            />
          )} */}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteProcessDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const ProcessID = useAppSelector((state) => state.process.ProcessID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteProcess(ID));
      if (response.payload?.Data.Status === 200) {
        dispatch(getAllProcess());
        dispatch(closeDialog());
        dispatch(clearProcessID());
      } else {
        dispatch(getAllProcess());
        dispatch(closeDialog());
        dispatch(clearProcessID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteProcess" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Process?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Process?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => {
                    handleDeleteAction(ProcessID);
                  }}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default Process;
