import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllInwardReturns = createAsyncThunk(
  "InwardReturn/FindAllInwardReturn",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/InwardReturn/FindAllInwardReturn`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Inward Returns:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createInwardReturn = createAsyncThunk(
  "InwardReturn/InsertInwardReturn",
  async (InwardReturnData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/InwardReturn/InsertInwardReturn`,
        InwardReturnData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating inward return:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateInwardReturn = createAsyncThunk(
  "InwardReturn/UpdateInwardReturn",
  async (InwardReturnData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/InwardReturn/UpdateInwardReturn`,
        InwardReturnData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating inward return:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getInwardReturnByID = createAsyncThunk(
  "InwardReturn/FindByIDInwardReturn",
  async (FormData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/InwardReturn/FindByIDInwardReturn`,
        FormData
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        inward_return_guid: result?.inward_return_guid,
        inward_return_no: result?.inward_return_no,
        inward_return_date: result?.inward_return_date
          ? moment(result?.inward_return_date, "YYYY-MM-DD").toDate()
          : "",
        vendor_id: result?.vendor_id,
        account_name: result?.account_name,
        vehicle_no: result?.vehicle_no,
        chalan_no: result?.chalan_no,
        bill_no: result?.bill_no,
        financial_year: result?.financial_year,
        remarks: result?.remarks,
        inward_return_items: result?.inward_return_items
          ? result?.inward_return_items?.map((inwardreturn: any) => {
              return {
                id: inwardreturn?.id ? inwardreturn?.id : null,
                inward_return_id: inwardreturn?.inward_return_id
                  ? inwardreturn?.inward_return_id
                  : null,
                item_id: inwardreturn?.item_id ? inwardreturn?.item_id : null,
                grn_no: inwardreturn?.grn_no ? inwardreturn?.grn_no : null,
                weight: inwardreturn?.weight ? inwardreturn?.weight : null,
                unit_id: inwardreturn?.unit_id ? inwardreturn?.unit_id : null,
                rate: inwardreturn?.rate ? inwardreturn?.rate : null,
                dagina: inwardreturn?.dagina ? inwardreturn?.dagina : null,
                total_amount: inwardreturn?.total_amount
                  ? inwardreturn?.total_amount
                  : null,
                warehouse_id: inwardreturn?.warehouse_id
                  ? inwardreturn?.warehouse_id
                  : null,
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching inward return:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteInwardReturn = createAsyncThunk(
  "InwardReturn/DeleteInwardReturn",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/InwardReturn/DeleteInwardReturn`,
        { inward_return_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting inward return:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getGRNByItemID = createAsyncThunk(
  "ProductCleaning/FindByGrnNo",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/ProductCleaning/FindByGrnNo`,
        { item_id: ID }
      );
      const result = response.data?.Data as any;
      return result?.map((item: any) => {
        return {
          item_id: item?.item_id,
          grn_no: item?.grn_no,
          grn_no_string: item?.grn_no_string,
          weight: item?.weight,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching grn no:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllInwardGRN = createAsyncThunk(
  "Inward/FindAllInwardGRN",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Inward/FindAllInwardGRN`,
        formData
      );
      const result = response.data?.Data as any;
      return result?.map((item: any) => {
        return {
          item_id: item?.item_id,
          grn_no: item?.grn_no,
          grn_no_string: item?.grn_no_string,
          weight: item?.weight,
        };
      });
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching grn no:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllItemByInwardNo = createAsyncThunk(
  "Inward/FindAllInwardNo",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Inward/FindAllInwardNo`,
        formData
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Inward Items:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
