import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import {
  Avatar,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import IconButton from "../../components/common/IconButton";
import { TextBox } from "@progress/kendo-react-inputs";
import { getAllEmployee } from "../employee/services/employee.services";
import { filterClearIcon } from "@progress/kendo-svg-icons";
import {
  clearFilterCustomEmplooye,
  setFilterCustomEmplooye,
} from "../employee/employeeSlice";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import _debounce from "lodash/debounce";
import { MdBrightness7 } from "react-icons/md";
import { checkAcessRights } from "../../_helper/helper";
import { EDIT_ACCESS } from "../../_contstants/common";

const User: React.FC = () => {
  const gridRef = useRef<any>(null);
  const loading = useAppSelector((state) => state.user.loading);

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            User List
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem>
          <EmployeeGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const EmployeeGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const isEditBtnRef = useRef<any>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  // const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const EmployeeList = useAppSelector((state) => state.employee.EmployeeList);
  const loading = useAppSelector((state) => state.employee.loading);

  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterCustomEmplooye = useAppSelector(
    (state) => state.employee.FilterCustomEmplooye
  );

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  useEffect(() => {
    return () => {
      if (!isEditBtnRef.current) {
        dispatch(clearFilterCustomEmplooye());
      }
    };
  }, []);

  useEffect(() => {
    const fetchItemList = async () => {
      const payload = {
        ...FilterCustomEmplooye,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      await dispatch(getAllEmployee(payload));
    };
    fetchItemList();
  }, [currentPage, pageLimit]);

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (item: any) => {
      navigate("/user/edit", {
        state: {
          id: item?.id,
        },
      });
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Rights Assign"
            ref={isEditBtnRef}
            onClick={() => {
              isEditBtnRef.current = true;
              handleEdit(props.dataItem);
            }}
          >
            <MdBrightness7
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)" }}
        filterable={true}
        filter={filter}
        data={
          (filter
            ? filterBy(
                EmployeeList?.map((employee: any) => {
                  return {
                    ...employee,
                    name: `${employee?.first_name} ${employee?.middle_name} ${employee?.last_name}`,
                  };
                }),
                filter
              )
            : EmployeeList?.map((employee: any) => {
                return {
                  ...employee,
                  name: `${employee?.first_name} ${employee?.middle_name} ${employee?.last_name}`,
                };
              })) || []
        }
        onFilterChange={handleFilterChange}
        total={EmployeeList[0]?.itemcount || EmployeeList?.length}
        skip={currentPage * pageLimit}
        take={pageLimit}
        pageable={{
          buttonCount: 5,
          pageSizes: [5, 10, 50, 100, 300, 500],
          pageSizeValue: pageLimit,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="user_image"
          title="Image"
          width="80px"
          filterable={false}
          cell={(props: any) => {
            const firstName = props.dataItem.first_name
              ? props.dataItem.first_name?.charAt(0)?.toUpperCase()
              : "";
            const lastName = props.dataItem.last_name
              ? props.dataItem.last_name?.charAt(0)?.toUpperCase()
              : "";
            return (
              <td>
                <Avatar
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: props.dataItem.user_image
                      ? "transparent"
                      : "#303F9F",
                  }}
                >
                  {props.dataItem.user_image ? (
                    <img
                      src={props.dataItem.user_image}
                      alt={`${firstName + lastName}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    `${firstName + lastName}`
                  )}
                </Avatar>
              </td>
            );
          }}
        />
        <Column
          field="name"
          title="Employee Name"
          // width={160}
          filterable
          filterCell={MyCustomFilterCell}
          cell={(props: any) => (
            <td>
              {isEditAccess ? (
                <div
                  className="hoverableName"
                  onClick={() =>
                    navigate("/user/edit", {
                      state: {
                        id: props.dataItem?.id,
                      },
                    })
                  }
                >
                  {`${props.dataItem?.first_name} ${props.dataItem?.last_name}`}
                </div>
              ) : (
                <div className="normalName">{`${props.dataItem?.first_name} ${props.dataItem?.last_name}`}</div>
              )}
            </td>
          )}
        />
        <Column field="employee_code" title="Code" />
        <Column
          field="mobile_no"
          width={120}
          filterCell={MyCustomFilterCell}
          title="Mobile No"
        />
        <Column field="email" title="Email" filterCell={MyCustomFilterCell} />
        <Column field="department_name" title="Department" />
        <Column field="designation_name" title="Designation" />
        {isEditAccess && (
          <Column
            field="id"
            title="Actions"
            cell={MyEditCommandCell}
            width={"90px"}
            filterable={false}
          />
        )}
      </KendoGrid>
    </>
  );
};

const MyCustomFilterCell = (props: any) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterCustomEmplooye: any = useAppSelector(
    (state) => state.employee.FilterCustomEmplooye
  );
  const isFilterRef = useRef(true);
  const [inputValue, setInputValue] = useState(props.value || "");
  const [inputField, setInputField] = useState(props.field || "");

  const onChange = (event: any) => {
    setInputValue(event.target.value);
    setInputField(event.target.name);
  };

  const delayedApiCall = React.useCallback(
    _debounce((updatedFilters: any) => {
      dispatch(setCurrentPage(0));
      const payload = {
        ...updatedFilters,
        limit: +pageLimit,
        skip: 0 * +pageLimit,
      };

      dispatch(getAllEmployee(payload));
    }, 300),
    [dispatch]
  );
  useEffect(() => {
    if (!isFilterRef.current) {
      const updatedFilters = {
        ...FilterCustomEmplooye,
        [inputField]: inputValue,
      };
      dispatch(setFilterCustomEmplooye(updatedFilters));
      delayedApiCall(updatedFilters);
    } else {
      isFilterRef.current = false;
    }
    return () => {
      delayedApiCall.cancel();
    };
  }, [inputValue, delayedApiCall]);

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });

    const updatedFilters = { ...FilterCustomEmplooye, [props.field]: "" };
    dispatch(setFilterCustomEmplooye(updatedFilters));

    const payload = {
      ...updatedFilters,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllEmployee(payload));
  };

  return (
    <div className="d-flex">
      <TextBox
        value={FilterCustomEmplooye[props.field] || ""}
        name={props.field || ""}
        onChange={onChange}
      />
      <Button
        svgIcon={filterClearIcon}
        title="Clear"
        disabled={!FilterCustomEmplooye[props.field]}
        onClick={onClearButtonClick}
        type="button"
        style={{
          marginLeft: "8px",
        }}
      ></Button>
    </div>
  );
};

export default User;
