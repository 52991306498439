import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import {
  createPurchase,
  deletePurchase,
  getAllPurchase,
  getPurchaseByID,
  updatePurchase,
} from "./services/purchase.services";
import { IPurchase, IPurchaseInitialState } from "./purchaseModel";

const initialState: IPurchaseInitialState = {
  loading: false,
  error: "",
  PurchaseStateForGST: null,
  PurchaseDeleteIndex: -1,
  PurchaseID: null,
  PurchaseList: [],
  PurchaseDetail: {
    purchase_order_id: null,
    financial_year: null,
    mobile_no: "",
    email_id: "",
    place_of_supply_id: null,
    terms_days: null,
    packing: null,
    labour: null,
    dalali: null,
    inward_no: null,
    invoice_no: "",
    invoice_date: moment().toDate(),
    vendor_id: null,
    due_date: "",
    total_amount: null,
    total_gst: null,
    gst: null,
    cgst: null,
    sgst: null,
    igst: null,
    purchase_items: [],
  },
  FilterCustomPurchase: {
    account_name: "",
  },
};

const purchaseSlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    clearPurchaseDetails: (state) => {
      state.PurchaseDetail = initialState.PurchaseDetail;
    },
    setPurchaseID: (state, action) => {
      state.PurchaseID = action.payload;
    },
    clearPurchaseID: (state) => {
      state.PurchaseID = initialState.PurchaseID;
    },
    setPurchaseDeleteIndex: (state, action) => {
      state.PurchaseDeleteIndex = action.payload;
    },
    setPurchaseStateForGST: (state, action) => {
      state.PurchaseStateForGST = action.payload;
    },
    clearPurchaseStateForGST: (state) => {
      state.PurchaseStateForGST = initialState.PurchaseStateForGST;
    },
    setFilterCustomPurchase: (state, action) => {
      state.FilterCustomPurchase = action.payload;
    },
    clearFilterCustomPurchase: (state) => {
      state.FilterCustomPurchase = initialState.FilterCustomPurchase;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPurchase.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllPurchase.fulfilled,
      (state, action: PayloadAction<IPurchase[]>) => {
        state.loading = false;
        state.PurchaseList = action.payload || [];
      }
    );
    builder.addCase(getAllPurchase.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PurchaseList = [];
    });

    builder.addCase(createPurchase.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createPurchase.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createPurchase.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updatePurchase.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updatePurchase.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePurchase.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deletePurchase.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deletePurchase.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePurchase.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getPurchaseByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getPurchaseByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.PurchaseDetail = action.payload;
    });
    builder.addCase(getPurchaseByID.rejected, (state, action) => {
      state.loading = false;
      state.PurchaseDetail = initialState.PurchaseDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearPurchaseDetails,
  clearPurchaseID,
  setPurchaseID,
  setPurchaseDeleteIndex,
  setPurchaseStateForGST,
  clearPurchaseStateForGST,
  setFilterCustomPurchase,
  clearFilterCustomPurchase,
} = purchaseSlice.actions;
export default purchaseSlice.reducer;
