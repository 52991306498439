import { Button } from "@progress/kendo-react-buttons";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  deleteDriverVisit,
  getAllDriverVisits,
} from "./services/driverVisit.services";
import { setDriverVisitID } from "./driverVisitSlice";
import { FiEdit } from "react-icons/fi";
import { checkAcessRights, formatIndianNumber } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  PRINT_ACCESS,
} from "../../_contstants/common";
import { FaEye } from "react-icons/fa";

const DriverVisit: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllDriverVisits());
    return () => {
      dispatch(closeDialog());
    };
  }, []);

  return (
    <>
      <DeleteDriverDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Driver Visit List
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem>
          <DriverGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const DriverGridComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);

  const DriverVisitList = useAppSelector(
    (state) => state.drivervisit.DriverVisitList
  );
  const loading = useAppSelector((state) => state.drivervisit.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? DriverVisitList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditDriver = (ID: number) => {
      navigate("/drivervisit/edit", {
        state: { id: ID },
      });
    };
    const handleViewDriverVisit = (ID: number) => {
      navigate("/drivervisit/view", {
        state: { id: ID },
      });
    };

    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteDriverVisit"));
      dispatch(setDriverVisitID(ID));
    };

    return (
      <td
        className={`action-td ${props?.className}`}
        style={{ ...props.style }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() => handleEditDriver(props.dataItem.id)}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.id)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="View"
          onClick={() => handleViewDriverVisit(props.dataItem.id)}
        >
          <FaEye className="absolute-position" style={{ fontSize: "24px" }} />
        </IconButton>
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 11 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const gridRef = useRef<any>(null);
  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)" }}
        filterable={true}
        filter={filter}
        data={
          filter
            ? filterBy(DriverVisitList, filter).slice(
                page.skip,
                page.take + page.skip
              )
            : DriverVisitList.slice(page.skip, page.take + page.skip)
        }
        onFilterChange={handleFilterChange}
        skip={page.skip}
        take={page.take}
        total={DriverVisitList.length}
        pageable={{
          buttonCount: 5,
          pageSizes: [10, 25, 50, "All"],
          pageSizeValue: pageSizeValue,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="voucher_no_string"
          title="Voucher No."
          width={150}
          cell={(props: any) => (
            <td>
              {props.dataItem?.claim_status === "Approved" ? (
                <div
                  className={isPrintAccess ? "hoverableName" : "normalName"}
                  onClick={() =>
                    isPrintAccess &&
                    window.open(
                      `/drivervisitprint/${
                        props.dataItem?.id ? props.dataItem?.id : 0
                      }`
                    )
                  }
                >
                  {props.dataItem?.voucher_no_string}
                </div>
              ) : (
                <div className={"normalName"}>
                  {props.dataItem?.voucher_no_string}
                </div>
              )}
            </td>
          )}
        />
        <Column field="driver_name" title="Driver Name" width={250} />
        <Column field="route_name" title="Route Name" width={200} />
        <Column field="session_start" title="Session Start" width={200} />
        <Column field="session_end" title="Session End" width={200} />
        <Column field="helper_name" title="Helper" width={200} />
        <Column field="vehicle_no" title="Vehicle No" width={200} />
        <Column field="total_km" title="Total KM" width={200} />
        <Column
          field="total_amount"
          title="Total Amount"
          width={200}
          cell={(props: any) => (
            <td style={{ textAlign: "end" }}>
              {formatIndianNumber(+props.dataItem.total_amount)}
            </td>
          )}
        />
        <Column
          field="approved_amount"
          title="Approved Amount"
          width={200}
          cell={(props: any) => (
            <td style={{ textAlign: "end" }}>
              {formatIndianNumber(+props.dataItem.approved_amount)}
            </td>
          )}
        />
        <Column field="claim_status" title="Status" width={200} />
        {(isEditAccess || isDeleteAccess) && (
          <Column
            field="id"
            title="Actions"
            cell={MyEditCommandCell}
            width={"140px"}
            filterable={false}
            locked={true}
          />
        )}
      </KendoGrid>
    </>
  );
};

const DeleteDriverDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const DriverVisitID = useAppSelector(
    (state) => state.drivervisit.DriverVisitID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteDriverVisit(ID));
      if (response.payload?.Data.Status === 200) {
        dispatch(getAllDriverVisits());
        dispatch(closeDialog());
      } else {
        dispatch(getAllDriverVisits());
        dispatch(closeDialog());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteDriverVisit" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Driver Visit?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Driver Visit?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => {
                    handleDeleteAction(DriverVisitID);
                  }}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default DriverVisit;
