import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ShadowCard from "../../components/common/ShadowCard";
import FormTextField from "../../components/formFields/FormTextField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import {
  createRetailer,
  getRetailerByID,
  updateRetailer,
} from "./services/retailer.services";
import { getAllActiveCities } from "../city/services/city.services";
import { getAllActiveStates } from "../state/services/state.services";
import { clearRetailerDetails } from "./retailerSlice";
import { IState } from "../state/stateModel";
import { ICity } from "../city/cityModel";
import {
  emailWOValueValidator,
  MobileNoWOValueValidator,
  pincodeWOValueValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
import { findIndex } from "lodash";
import { LoadingPanel } from "../../components/layout/Loading";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import { STATUSARRAY } from "../../_contstants/common";

interface ChangeWatcherProps {
  formRenderProps: FormRenderProps;
  CityList: ICity[];
}

const CityChangeWatcher: React.FC<ChangeWatcherProps> = ({
  formRenderProps,
  CityList,
}) => {
  const isRetailerCityRef = useRef(true);
  const location = useLocation();
  const retailer_guid = location.state?.retailer_guid;
  const cityID = formRenderProps.valueGetter("city_id");

  useEffect(() => {
    const handleStateChange = () => {
      const CityID = cityID ? cityID : undefined;
      const cityIndex = findIndex(CityList, { id: CityID });
      if (cityIndex > -1) {
        const city = CityList[cityIndex];
        formRenderProps.onChange("state_id", { value: city?.state_id });
      } else {
        formRenderProps.onChange("city_id", { value: "" });
        formRenderProps.onChange("state_id", { value: "" });
      }
    };
    if (!isRetailerCityRef.current) {
      handleStateChange();
    } else {
      if (!retailer_guid) {
        handleStateChange();
      }
      isRetailerCityRef.current = false;
    }
  }, [cityID]);
  return null;
};

const CreateRetailer: React.FC = () => {
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const retailer_guid = location.state?.retailer_guid;

  const loading = useAppSelector((state) => state.retailer.loading);
  const CityList = useAppSelector((state) => state.city.CityList);
  const StateList = useAppSelector((state) => state.state.StateList);
  const RetailerDetail = useAppSelector(
    (state) => state.retailer.RetailerDetail
  );

  useEffect(() => {
    if (retailer_guid) {
      const payload = {
        retailer_guid: retailer_guid,
      };
      dispatch(getRetailerByID(payload));
    }
  }, [retailer_guid]);

  useEffect(() => {
    dispatch(getAllActiveCities());
    dispatch(getAllActiveStates());

    return () => {
      dispatch(clearRetailerDetails());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    if (retailer_guid) {
      try {
        const editPayload = {
          retailer_guid: retailer_guid,
          retailer_name: values.retailer_name ? values.retailer_name : "",
          contact_person: values.contact_person ? values.contact_person : "",
          email_id: values.email_id ? values.email_id : "",
          mobile_no: values.mobile_no ? values.mobile_no : "",
          address1: values.address1 ? values.address1 : "",
          address2: values.address2 ? values.address2 : "",
          pincode: values.pincode ? values.pincode : "",
          state_id: values.state_id ? +values.state_id : null,
          city_id: values.city_id ? +values.city_id : null,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updateRetailer(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/retailer");
          dispatch(closed());
          dispatch(clearRetailerDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
      }
    } else {
      try {
        const insertPayload = {
          retailer_name: values.retailer_name ? values.retailer_name : "",
          contact_person: values.contact_person ? values.contact_person : "",
          email_id: values.email_id ? values.email_id : "",
          mobile_no: values.mobile_no ? values.mobile_no : "",
          address1: values.address1 ? values.address1 : "",
          address2: values.address2 ? values.address2 : "",
          pincode: values.pincode ? values.pincode : "",
          state_id: values.state_id ? +values.state_id : null,
          city_id: values.city_id ? +values.city_id : null,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(createRetailer(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/retailer");
          dispatch(closed());
          dispatch(clearRetailerDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
    navigate("/retailer");
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={RetailerDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {retailer_guid ? "Update Retailer" : "Add Retailer"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="retailer_name"
                    label="Retailer Name"
                    placeholder="i.e. John Smith"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="contact_person"
                    label="Contact Person Name"
                    placeholder="i.e. Adam Smith"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>

                <GridLayoutItem>
                  <Field
                    name="mobile_no"
                    label="Mobile No."
                    placeholder="i.e. 9999999999"
                    type="number"
                    max={10}
                    component={FormTextField}
                    validator={MobileNoWOValueValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  <Field
                    wrapperClassName="w-100"
                    name="address1"
                    label="Address Line 1"
                    placeholder="Address Line 1"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    wrapperClassName="w-100"
                    name="address2"
                    label="Address Line 2"
                    placeholder="Address Line 2"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="pincode"
                    label="Pincode"
                    type="number"
                    placeholder="i.e. 380000"
                    minNumber="0"
                    component={FormTextField}
                    validator={pincodeWOValueValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="email_id"
                    label="Email"
                    placeholder="i.e. example@gmail.com"
                    component={FormTextField}
                    validator={emailWOValueValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="city_id"
                    label="City"
                    placeholder="i.e. Ahmedabad"
                    component={FormSelectionField}
                    options={CityList?.map((city: ICity) => {
                      return {
                        value: city?.id,
                        label: city?.city_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="state_id"
                    label="State"
                    placeholder="i.e. Gujarat"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={StateList?.map((state: IState) => {
                      return {
                        value: state?.id,
                        label: state?.state_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"isactive"}
                    name={"isactive"}
                    label={"Status"}
                    component={FormSelectionField}
                    placeholder="Active / Inactive"
                    validator={requiredValidator}
                    astrike={true}
                    options={STATUSARRAY?.map((status: any) => {
                      return {
                        value: status.value,
                        label: status.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <ButtonWithLoading
                    label={!retailer_guid ? "Save" : "Update"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <CityChangeWatcher
              formRenderProps={formRenderProps}
              CityList={CityList}
            />
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateRetailer;
