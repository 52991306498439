import { Avatar, MenuProps, Dropdown } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import UserLogo from "../../assets/Images/useravatar.jpg";

const AccountUserProfile = () => {
  const navigate = useNavigate();
  const AccountName = localStorage.getItem("AccountName");
  const items: MenuProps["items"] = [
    {
      label: "Logout",
      key: "logout",
      icon: <LogoutOutlined />,
    },
  ];

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "logout") {
      navigate("/login");
      localStorage.clear();
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown menu={menuProps} trigger={["click"]}>
      <div className="userprofile m-0">
        <Avatar
          className="userprofile-avatar"
          size={40}
          gap={0}
          icon={
            <img
              src={UserLogo}
              alt={`user image`}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
              }}
            />
          }
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <strong>{AccountName || ""}</strong>
        </div>
      </div>
    </Dropdown>
  );
};

export default AccountUserProfile;
