import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog } from "../../components/dialog/dialogSlice";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";
// import { FiEdit } from "react-icons/fi";
import { getYearsArray } from "../../_helper/helper";
import { MONTHS_MAP } from "../../_contstants/common";
import { clearSalaryGenerationList } from "./salryGenerationSlice";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { getAllActiveEmployee } from "../employee/services/employee.services";
import { IEmployee } from "../employee/employeeModel";
import { getEmployeeSalaryByIDYear } from "./services/salaryGeneration.services";

const SalaryGenerationView: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllActiveEmployee());
    return () => {
      dispatch(closeDialog());
      dispatch(clearSalaryGenerationList());
    };
  }, []);

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Salary View
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem>
          <GeneratedSalaryViewFilter />
        </GridLayoutItem>
        <GridLayoutItem style={{ marginTop: 20 }}>
          <SalaryGenerationViewGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const SalaryGenerationViewGridComponent: React.FC = () => {
  //   const navigate = useNavigate();
  //   const dispatch = useAppDispatch();
  //   const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);

  const SalaryGenerationList = useAppSelector(
    (state) => state.salarygeneration.SalaryGenerationList
  );
  const loading = useAppSelector((state) => state.retailer.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? SalaryGenerationList.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  //   const MyEditCommandCell = (props: GridCellProps) => {
  //     const handleEditSalaryGenerationView = (retailer_guid: any) => {
  //       navigate("/retailer/edit", {
  //         state: { retailer_guid: retailer_guid },
  //       });
  //     };
  //     const handleOpenDeleteDialog = (retailer_guid: any) => {
  //       dispatch(openDialog("deleteSalaryGenerationView"));
  //       dispatch(setSalaryGenerationViewID(retailer_guid));
  //     };
  //     return (
  //       <td className="action-td">
  //         {isEditAccess && (
  //           <IconButton
  //             type="button"
  //             fillMode="flat"
  //             size="small"
  //             title="Edit"
  //             onClick={() => handleEditSalaryGenerationView(props.dataItem.retailer_guid)}
  //           >
  //             <FiEdit
  //               className="absolute-position"
  //               style={{ fontSize: "20px" }}
  //             />
  //           </IconButton>
  //         )}
  //         {isDeleteAccess && (
  //           <IconButton
  //             type="button"
  //             fillMode="flat"
  //             size="small"
  //             title="Delete"
  //             onClick={() => handleOpenDeleteDialog(props.dataItem.retailer_guid)}
  //           >
  //             <MdDelete
  //               className="absolute-position"
  //               style={{ fontSize: "24px" }}
  //             />
  //           </IconButton>
  //         )}
  //       </td>
  //     );
  //   };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 5 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const gridRef = useRef<any>(null);
  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {SalaryGenerationList && SalaryGenerationList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(SalaryGenerationList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : SalaryGenerationList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={SalaryGenerationList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 25, 50, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="Date"
            title="Date"
            width={150}
            cell={(props: GridCellProps) => {
              return (
                <td>
                  <span style={{ textTransform: "capitalize" }}>
                    {MONTHS_MAP[+props?.dataItem?.payout_month] || ""}{" "}
                  </span>{" "}
                  {"-"}
                  {props?.dataItem?.payout_year}
                </td>
              );
            }}
          />
          <Column field="total_days" title="Total Days" width={120} />
          <Column field="working_days" title="Working Days" width={130} />
          <Column field="present_days" title="Present Days" width={130} />
          <Column field="basic" title="Basic" width={120} />
          <Column field="da_amount" title="DA" width={120} />
          <Column field="hra" title="HRA" width={120} />
          <Column field="wash" title="Wash" width={120} />
          <Column field="adv_bonus" title="Advance Bonus" width={140} />
          <Column field="adv_leave" title="Advance Leave" width={140} />
          <Column field="food" title="Food" width={120} />
          <Column field="pf" title="PF" width={120} />
          <Column field="esi" title="ESI" width={120} />
          <Column field="pt" title="PT" width={120} />
          <Column field="it" title="IT" width={120} />
          <Column field="lwf" title="LWF" width={120} />
          <Column field="advance" title="Advance" width={120} />
          <Column field="loan" title="Loan" width={120} />
          <Column field="other" title="Other" width={120} />
          <Column field="total_income" title="Total Earning" width={130} />
          <Column field="total_deduction" title="Total Deduction" width={150} />
          <Column field="payable_amount" title="Total Salary" width={120} />
          <Column field="remarks" title="Remarks" width={120} />
          {/* {(isEditAccess || isDeleteAccess) && (
            <Column
              field="retailer_guid"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
              locked={true}
            />
          )} */}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const GeneratedSalaryViewFilter: React.FC = () => {
  const dispatch = useAppDispatch();
  const EmployeeList = useAppSelector((state) => state.employee.EmployeeList);
  const SalaryGenerationViewFilter = useAppSelector(
    (state) => state.salarygeneration.SalaryGenerationViewFilter
  );
  const loading = useAppSelector((state) => state.salarygeneration.loading);
  const handleSubmit = async (values: any) => {
    try {
      const payload = {
        employee_id: values?.employee_id,
        year: values?.year,
      };
      await dispatch(getEmployeeSalaryByIDYear(payload));
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={SalaryGenerationViewFilter}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem className="d-flex gap-3" style={{ width: "50%" }}>
              <Field
                name="year"
                label="Year"
                wrapperStyle={{ width: "30%" }}
                placeholder="Select Year"
                component={FormSelectionField}
                options={getYearsArray(2000, new Date().getFullYear())
                  ?.reverse()
                  ?.map((year: any) => {
                    return {
                      value: year?.value,
                      label: year?.label,
                    };
                  })}
                validator={requiredValidator}
                astrike={true}
              />
              <Field
                name="employee_id"
                label="Employee"
                wrapperStyle={{ width: "50%" }}
                placeholder="Select Employee"
                component={FormSelectionField}
                options={EmployeeList.map((emp: IEmployee) => ({
                  value: emp.id,
                  label: emp.name,
                }))}
                validator={requiredValidator}
                astrike={true}
              />
              <ButtonWithLoading
                buttonStyle={{ marginTop: 35, width: "20%" }}
                label={"View"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || loading}
                loading={loading}
              />
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default SalaryGenerationView;
