import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";
import {
  IAccountAndStatutoryDetails,
  IEmployeeDetails,
  IEmployeeDocumnet,
  IFamilyMemberDetails,
  ISalaryDetails,
} from "../employeeModel";

export const getAllEmployee = createAsyncThunk(
  "User/FindAllEmployee",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindAllEmployee`,
        formdata
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Employee:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createEmployee = createAsyncThunk(
  "User/InsertEmployee",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/InsertEmployee`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Employee:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateEmployee = createAsyncThunk(
  "User/UpdateEmployee",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateEmployee`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Employee:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteEmployee = createAsyncThunk(
  "User/DeleteEmployee",
  async (user_guid: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/DeleteEmployee`,
        { user_guid }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Employee:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getEmployeeByID = createAsyncThunk(
  "User/FindByIDEmployee",
  async (user_guid: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByIDEmployee`,
        {
          user_guid,
        }
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        user_guid: result?.user_guid,
        employee_code: result?.employee_code,
        employee_code_string: result?.employee_code_string,
        user_image: result?.user_image,
        first_name: result?.first_name,
        middle_name: result?.middle_name,
        last_name: result?.last_name,
        gender: result?.gender,
        mobile_no: result?.mobile_no,
        email: result?.email,
        department_id: result?.department_id,
        designation_id: result?.designation_id,
        official_email: result?.official_email,
        date_of_joining: result?.date_of_joining
          ? moment(result?.date_of_joining, "YYYY-MM-DD").toDate()
          : "",
        work_shift_time: result?.work_shift_time
          ? moment(result?.work_shift_time, "HH:mm:ss").toDate()
          : "",
        weekly_off: result?.weekly_off,
        left_date: result?.left_date
          ? moment(result?.left_date, "YYYY-MM-DD").toDate()
          : "",
        location: result?.location,
        reporting_person: result?.reporting_person,
        dob: result?.dob ? moment(result?.dob, "YYYY-MM-DD").toDate() : "",
        blood_group: result?.blood_group,
        personal_email: result?.personal_email,
        marital_status: result?.marital_status,
        date_of_marrige: result?.date_of_marrige
          ? moment(result?.date_of_marrige, "YYYY-MM-DD").toDate()
          : "",
        spouse_name: result?.spouse_name,
        nationality: result?.nationality,
        address: result?.address,
        street: result?.street,
        area: result?.area,
        city: result?.city,
        pincode: result?.pincode,
        state_id: result?.state_id,
        is_permanent_address_same: result?.is_permanent_address_same
          ? result?.is_permanent_address_same
          : false,
        permenent_address: result?.permenent_address,
        permenent_street: result?.permenent_street,
        permenent_area: result?.permenent_area,
        permenent_city: result?.permenent_city,
        permenent_pincode: result?.permenent_pincode,
        permenent_state_id: result?.permenent_state_id,
        emergency_name: result?.emergency_name,
        emergency_mobile: result?.emergency_mobile,
        emergency_relationship_id: result?.emergency_relationship_id,
        isactive: result.isactive,
      } as IEmployeeDetails;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Employee:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveEmployee = createAsyncThunk(
  "User/ActiveInActiveEmployee",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/ActiveInActiveEmployee`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Employee:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveEmployee = createAsyncThunk(
  "User/FindAllActiveEmployee",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/User/FindAllActiveEmployee`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching active Employee:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const uploadEmployeeImages = createAsyncThunk(
  "User/UserImageUpdate",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UserImageUpdate`,
        formData
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Employee Image:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateAccountStatustory = createAsyncThunk(
  "User/UpdateAccountStatustory",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateAccountStatustory`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Account And Statustory:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAccountStatustoryByUserID = createAsyncThunk(
  "User/FindByIDAccountStatustory",
  async (user_guid: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByIDAccountStatustory`,
        {
          user_guid,
        }
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        user_guid: result?.user_guid,
        bank_name: result?.bank_name,
        account_no: result?.account_no,
        ifsc_code: result?.ifsc_code,
        bank_branch: result?.bank_branch,
        pf_deduction: result?.pf_deduction ? result?.pf_deduction : false,
        pf_no: result?.pf_no,
        pf_join_date: result?.pf_join_date
          ? moment(result?.pf_join_date, "YYYY-MM-DD").toDate()
          : "",
        uan_no: result?.uan_no,
        est_no: result?.est_no,
        esi_join_date: result?.esi_join_date
          ? moment(result?.esi_join_date, "YYYY-MM-DD").toDate()
          : "",
        aadharcard: result?.aadharcard,
        pancard: result?.pancard,
        passport_no: result?.passport_no,
        isactive: result.isactive ? 1 : 2,
      } as IAccountAndStatutoryDetails;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Account And Statustory:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getFamilyMemberByUserID = createAsyncThunk(
  "User/FindByIDFamilyDetails",
  async (id: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByIDFamilyDetails`,
        { employee_id: id }
      );
      const result = response.data?.Data as any;
      const EmployeeFamilyDetails = {
        FamilyMemberDetails: result?.map((member: IFamilyMemberDetails) => ({
          relationship_id: member?.relationship_id,
          name: member?.name,
          date: member?.date ? moment(member?.date, "YYYY-MM-DD").toDate() : "",
          gender: member?.gender,
          blood_group: member?.blood_group,
        })) as IFamilyMemberDetails[],
      };
      return EmployeeFamilyDetails || [];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Family Member:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateFamilyDetails = createAsyncThunk(
  "User/UpdateFamilyDetails",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateFamilyDetails`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Family Details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const uploadEmployeeAttachment = createAsyncThunk(
  "User/InsertEmployeeDocument",
  async (formData: any) => {
    const data = Object.fromEntries(formData.entries());
    const file = new FormData();
    file.append("document", data?.document);
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/InsertEmployeeDocument?document_id=${data.document_id}&employee_id=${data.employee_id}`,
        file
      );
      SuccessToast(response?.data?.Message || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Employee:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateEmployeeAttachment = createAsyncThunk(
  "User/UpdateEmployeeDocument",
  async (formData: any) => {
    const data = Object.fromEntries(formData.entries());
    const file = new FormData();
    file.append("document", data?.document);
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateEmployeeDocument?employee_document_guid=${data?.id}&document_id=${data?.document_id}&employee_id=${data?.employee_id}`,
        file
      );
      SuccessToast(response?.data?.Message || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Account And Statustory:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getDocumentAttachmentByUserID = createAsyncThunk(
  "User/FindByIDEmployeeDocumentDetails",
  async (id: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByIDEmployeeDocumentDetails`,
        { employee_id: id }
      );
      const result = response.data?.Data as any;
      const EmployeeDocuments = result?.map((doc: IEmployeeDocumnet) => ({
        id: doc?.id,
        employee_document_guid: doc?.employee_document_guid,
        document_id: doc?.document_id,
        employee_id: doc?.employee_id,
        document: doc?.document,
      })) as IEmployeeDocumnet[];
      return EmployeeDocuments || [];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Employee Documents:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteEmployeeAttachment = createAsyncThunk(
  "User/DeleteEmployeeDocumentattch",
  async (employee_document_guid: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/DeleteEmployeeDocumentattch`,
        { employee_document_guid }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Employee Attachment:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createEmployeeEducation = createAsyncThunk(
  "User/InsertEmployeeEducation",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/InsertEmployeeEducation`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Employee Education:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateEmployeeEducation = createAsyncThunk(
  "User/UpdateEmployeeEducation",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateEmployeeEducation`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Employee Education:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteEmployeeEducation = createAsyncThunk(
  "User/DeleteEmployeeEducation",
  async (education_guid: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/DeleteEmployeeEducation`,
        { education_guid }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Employee Education:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getEducationByUserID = createAsyncThunk(
  "User/FindByIDEmployeeEducationDetails",
  async (id: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByIDEmployeeEducationDetails`,
        { employee_id: id }
      );
      return response.data?.Data as any;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Employee Documents:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateEmployeeEducationAttachment = createAsyncThunk(
  "User/UpdateEmployeeeducationDocument?education_guid=c26341bf-127c-4d2c-92b7-d390a16e4ac0",
  async (formData: any) => {
    const data = Object.fromEntries(formData.entries());
    const file = new FormData();
    file.append("document_attchement", data?.document_attchement);
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateEmployeeeducationDocument?education_guid=${data.education_guid}`,
        file
      );
      SuccessToast(response?.data?.Message || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Account And Statustory:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createEmployeeExperience = createAsyncThunk(
  "User/InsertEmployeeExperience",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/InsertEmployeeExperience`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Employee Experience:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateEmployeeExperience = createAsyncThunk(
  "User/UpdateEmployeeExperience",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateEmployeeExperience`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Employee Experience:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteEmployeeExperience = createAsyncThunk(
  "User/DeleteEmployeeExperience",
  async (experience_guid: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/DeleteEmployeeExperience`,
        { experience_guid }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Employee Experience:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getExperienceByUserID = createAsyncThunk(
  "User/FindByIDEmployeeExperienceDetails",
  async (id: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByIDEmployeeExperienceDetails`,
        { employee_id: id }
      );
      return response.data?.Data as any;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Employee Documents:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateEmployeeSalaryStructure = createAsyncThunk(
  "User/UpdateEmployeeSalary",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/UpdateEmployeeSalary`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Employee Salary Structure:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllSalaryDateByEmployeeID = createAsyncThunk(
  "User/EmployeeSalaryDatelist",
  async (id: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/EmployeeSalaryDatelist`,
        { employee_id: id }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Salary Date List:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteEmployeeSalaryStructure = createAsyncThunk(
  "User/DeleteEmployeeSalary",
  async (salary_guid: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/DeleteEmployeeSalary`,
        { salary_guid }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Employee Salary Structure:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSalaryStructureByDateAndEmpID = createAsyncThunk(
  "User/FindByIdEmployeeSalaryDetails",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/User/FindByIdEmployeeSalaryDetails`,
        formdata
      );
      const result = response.data?.Data as any;
      return {
        id: result?.id,
        salary_guid: result?.salary_guid,
        employee_id: result?.employee_id,
        basic: result?.basic,
        da_per: result?.da_per,
        da: result?.da,
        hra_per: result?.hra_per,
        hra: result?.hra,
        wash: result?.wash,
        adv_bonus: result?.adv_bonus,
        adv_leave: result?.adv_leave,
        food: result?.food,
        pf_per: result?.pf_per,
        pf: result?.pf,
        esi_per: result?.esi_per,
        esi: result?.esi,
        pt: result?.pt,
        it: result?.it,
        lwf: result?.lwf,
        advance: result?.advance,
        loan: result?.loan,
        other: result?.other,
        total_income: result?.total_income,
        total_deduction: result?.total_deduction,
        payable_amount: result?.payable_amount,
        apply_from_date: result?.apply_from_date
          ? moment(result?.apply_from_date, "YYYY-MM-DD").toDate()
          : "",
        // apply_from_date_selector: result?.apply_from_date,
      } as ISalaryDetails;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Salary Structure:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
