import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import { getAllItemIncremental } from "../../Item/services/item.services";
import { IItem } from "../../Item/itemModel";
import {
  Grid,
  GridColumn,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import { getPackingMaterialStockReport } from "../services/reports.services";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormIncrementalSearch from "../../../components/formFields/FormIncrementalSearch";
import { clearItemList } from "../../Item/itemSlice";
import { Button } from "@progress/kendo-react-buttons";
import Logo from "../../../assets/Images/logo.png";
import {
  checkAcessRights,
  formatIndianNumberForQty,
} from "../../../_helper/helper";
import { PRINT_ACCESS } from "../../../_contstants/common";
import { clearPackingMaterialStockReportList } from "../reportsSlice";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { IItemGroup } from "../../ItemGroup/itemgroupModel";
import { findAllActiveItemGroup } from "../../ItemGroup/services/itemgroup.services";

const PackingMaterialStockReport: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const itemLoading = useAppSelector((state) => state.item.loading);
  const ItemList = useAppSelector((state) => state.item.ItemList);
  const ItemGroupList = useAppSelector(
    (state) => state.itemgroup.ItemGroupList
  );
  const PackingMaterialStockReportList = useAppSelector(
    (state) => state.reports.PackingMaterialStockReportList
  );

  useEffect(() => {
    dispatch(findAllActiveItemGroup());

    return () => {
      dispatch(clearPackingMaterialStockReportList());
      dispatch(clearItemList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      item_id: values?.item_id ? values?.item_id : null,
      item_group_id: values?.item_group_id ? values?.item_group_id : null,
    };
    dispatch(getPackingMaterialStockReport(payload));
  };
  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 10 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[{ width: "40%" }, { width: "60%" }]}
                >
                  <GridLayoutItem colSpan={2}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Packing Material Stock Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="item_group_id"
                      label="Product Group Name"
                      placeholder="Product Group Name"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      astrike={true}
                      options={ItemGroupList?.map((itemgroup: IItemGroup) => {
                        return {
                          value: itemgroup?.id,
                          label: itemgroup?.item_group,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem></GridLayoutItem>
                  <GridLayoutItem
                    style={{ display: "flex", alignItems: "end", gap: 10 }}
                  >
                    <Field
                      wrapperClassName="w-100"
                      name="item_id"
                      label="Product Name"
                      component={FormIncrementalSearch}
                      fetchIncrementalData={(search: any) =>
                        !formRenderProps.valueGetter("item_group_id") ||
                        formRenderProps.valueGetter("item_group_id") === null
                          ? dispatch(
                              getAllItemIncremental({
                                search: search,
                              })
                            )
                          : dispatch(
                              getAllItemIncremental({
                                search: search,
                                item_group_id:
                                  formRenderProps.valueGetter("item_group_id"),
                              })
                            )
                      }
                      loading={itemLoading}
                      options={ItemList?.map((item: IItem) => {
                        return {
                          value: item?.id,
                          label: item?.product_name,
                        };
                      })}
                    />
                    <div
                      style={{
                        marginBottom: 1,
                      }}
                    >
                      <ButtonWithLoading
                        label={"View"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          PackingMaterialStockReportList &&
          PackingMaterialStockReportList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 }}>
              <PackingMaterialStockGridCommponent />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const PackingMaterialStockGridCommponent: React.FC = () => {
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);

  //   const loading = useAppSelector((state) => state.reports.loading);
  const PackingMaterialStockReportList = useAppSelector(
    (state) => state.reports.PackingMaterialStockReportList
  );
  const PackingMaterialStockReportDetails = useAppSelector(
    (state) => state.reports.PackingMaterialStockReportDetails
  );

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? PackingMaterialStockReportList?.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const printTable = (data: any[]) => {
    let printbuttons = document.querySelector(
      ".printbuttons"
    ) as HTMLElement | null;
    let report_container = document.querySelector(
      ".report-container"
    ) as HTMLElement | null;

    if (printbuttons) printbuttons.style.display = "none";
    if (report_container) report_container.style.padding = "20px 0";

    let tableRows = data.length;
    let maxRows = tableRows;
    let pageCount = Math.ceil(tableRows / maxRows);
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      //   content += `<div class="page-header">Page ${i + 1}</div>`;
      content +=
        '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
      content += "<tr>";
      content += `<td rowSpan="3" style="width:15%" ><img src="${Logo}" width="100%" /></td>`;
      content +=
        '<td colSpan="2" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
      content += "</tr>";
      content += "<tr>";
      content +=
        '<td colSpan="2"><h3 style="margin:0;">Packing Material Stock Report</h3></td>';
      content += "</tr>";
      content += "<tr>";
      content += `<td style="text-align:end;">Page : ${i + 1}</td>`;
      content += "</tr>";

      content += "</table>";

      content += '<table class="table table-bordered" style="font-size:10px;">';
      content += '<thead style="background:lightgray;">';
      content += "<tr>";
      content += '<th style="width:40px;">Sr. No.</th>';
      content += "<th>Item Name</th>";
      content += "<th>Qty</th>";
      content += "</tr>";
      content += "</thead>";
      content += "<tbody>";

      let startRow = i * maxRows;
      let endRow = Math.min((i + 1) * maxRows, tableRows);

      for (let j = startRow; j < endRow; j++) {
        content += `<tr style="text-align:center;">`;
        content += `<td>${j + 1}</td>`;
        content += `<td>${data[j].product_name}</td>`;
        content += `<td style="text-align:end;">${formatIndianNumberForQty(
          +data[j].actual_qty || 0
        )}</td>`;
        content += "</tr>";
      }

      content += "</tbody>";
      content += "</table>";

      content += "</div>";
    }

    let printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }
    printWindow.document.write(`
      <html>
      <head>
        <title>Print Preview</title>
        <style>
          @page { margin: 1cm; }
          .print-page { page-break-after: always; }
          .table-bordered { width: 100%; border-collapse: collapse; }
          .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
          body { font-family: Arial, sans-serif; max-width:1140px; margin: 0 auto;}
        </style>
      </head>
      <body>
        ${content}
        <script>
          window.onload = function() {
            window.print();
          };
        </script>
      </body>
      </html>
    `);
    printWindow.document.close();

    if (printbuttons) printbuttons.style.display = "block";
    if (report_container) report_container.style.padding = "20px 10px";
  };

  return (
    <>
      {PackingMaterialStockReportList &&
      PackingMaterialStockReportList.length ? (
        <GridLayout
          //   style={{ marginRight: 30 }}
          gap={{ rows: 10, cols: 10 }}
          cols={[{ width: "100%" }]}
        >
          <GridLayoutItem className="d-flex justify-content-between align-items-center">
            <Typography.h4 className="m-0">
              Packing Material Stock List{" "}
              {(PackingMaterialStockReportDetails?.from_date ||
                PackingMaterialStockReportDetails?.to_date) && (
                <span style={{ fontSize: 13 }}>
                  {"("}
                  {PackingMaterialStockReportDetails?.from_date
                    ? moment(
                        PackingMaterialStockReportDetails?.from_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {PackingMaterialStockReportDetails?.from_date &&
                    PackingMaterialStockReportDetails?.to_date &&
                    " - "}
                  {PackingMaterialStockReportDetails?.to_date
                    ? moment(
                        PackingMaterialStockReportDetails?.to_date,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                  {")"}
                </span>
              )}
            </Typography.h4>
            {isPrintAccess && (
              <Button
                style={{ marginRight: 5 }}
                type="button"
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={() => printTable(PackingMaterialStockReportList)}
              >
                Print
              </Button>
            )}
          </GridLayoutItem>
          <GridLayoutItem>
            <Grid
              className="table-bordered"
              style={{ maxHeight: "calc(100vh - 152px)" }}
              filterable={true}
              filter={filter}
              data={
                filter
                  ? filterBy(PackingMaterialStockReportList, filter).slice(
                      page.skip,
                      page.take + page.skip
                    )
                  : PackingMaterialStockReportList.slice(
                      page.skip,
                      page.take + page.skip
                    )
              }
              onFilterChange={handleFilterChange}
              skip={page.skip}
              take={page.take}
              total={PackingMaterialStockReportList?.length}
              pageable={{
                buttonCount: 5,
                pageSizes: [10, 25, 50, "All"],
                pageSizeValue: pageSizeValue,
                type: "input",
              }}
              onPageChange={pageChange}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <GridColumn
                field="UniqueNo"
                title="Sr. No."
                width={80}
                filterable={false}
              />
              <GridColumn field="product_name" title="Item Name" />
              <GridColumn
                field="actual_qty"
                title="Qty"
                width={200}
                filterable={false}
                cell={(props: any) => (
                  <td style={{ textAlign: "end" }}>
                    {props.dataItem?.actual_qty
                      ? formatIndianNumberForQty(props.dataItem?.actual_qty)
                      : null}
                  </td>
                )}
              />
            </Grid>
          </GridLayoutItem>
        </GridLayout>
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default PackingMaterialStockReport;
