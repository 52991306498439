import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { MdDelete } from "react-icons/md";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { deletePacking, getAllPacking } from "./services/packing.services";
import {
  clearFilterPacking,
  clearPackingID,
  setFilterPacking,
  setPackingID,
} from "./packingSlice";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import {
  checkAcessRights,
  formatIndianNumberForQty,
} from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { clearItemList } from "../Item/itemSlice";
import _debounce from "lodash/debounce";
import { filterClearIcon } from "@progress/kendo-svg-icons";
import { TextBox } from "@progress/kendo-react-inputs";

const Packing: React.FC = () => {
  const navigate = useNavigate();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  const handleCreate = () => {
    navigate("/packing/create");
  };

  return (
    <>
      <DeletePackingDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Packing List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              {"Add New"}
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <PackingGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const PackingGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const isEditBtnRef = useRef<any>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const PackingList = useAppSelector((state) => state.packing.PackingList);
  const loading = useAppSelector((state) => state.packing.loading);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterPacking = useAppSelector((state) => state.packing.FilterPacking);

  useEffect(() => {
    return () => {
      dispatch(clearItemList());
      dispatch(setCurrentPage(0));
      if (!isEditBtnRef.current) {
        dispatch(clearFilterPacking());
      }
    };
  }, []);

  useEffect(() => {
    const payload = {
      ...FilterPacking,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllPacking(payload));
  }, [currentPage, pageLimit]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (packing_guid: any) => {
      navigate("/packing/edit", {
        state: { packing_guid: packing_guid },
      });
    };
    const handleOpenDeleteDialog = (packing_guid: any) => {
      dispatch(openDialog("deletePacking"));
      dispatch(setPackingID(packing_guid));
    };

    return (
      <td
        className={`${props?.className}`}
        style={{
          ...props.style,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            ref={isEditBtnRef}
            onClick={() => {
              isEditBtnRef.current = true;
              handleEdit(props.dataItem?.packing_guid);
            }}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem?.packing_guid)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 11 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)" }}
        filterable={true}
        filter={filter}
        data={filter ? filterBy(PackingList, filter) : PackingList}
        onFilterChange={handleFilterChange}
        total={PackingList[0]?.total_item_count || PackingList.length}
        skip={currentPage * pageLimit}
        take={pageLimit}
        pageable={{
          buttonCount: 5,
          pageSizes: [10, 50, 100, 300, 500],
          pageSizeValue: pageLimit,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="packing_no"
          title="Packing No"
          width={150}
          cell={(props: any) => (
            <td>
              <div
                className={isEditAccess ? "hoverableName" : "normalName"}
                onClick={() =>
                  isEditAccess &&
                  navigate("/packing/edit", {
                    state: {
                      packing_guid: props.dataItem?.packing_guid,
                    },
                  })
                }
              >
                {props.dataItem?.packing_no}
              </div>
            </td>
          )}
        />
        <Column field="packing_date" title="Packing Date" width={200} />
        <Column
          field="packing_item_name"
          title="Packing Item"
          width={250}
          filterCell={CommonFilterCell}
        />
        <Column
          field="batch_no"
          title="Batch No"
          width={200}
          // filterCell={CommonFilterCell}
          cell={(props: any) => (
            <td className="text-end">{props.dataItem?.batch_no}</td>
          )}
        />
        <Column
          field="taken_qty"
          title="Taken Qty"
          width={200}
          cell={(props: any) => (
            <td style={{ textAlign: "end" }}>
              {formatIndianNumberForQty(+props.dataItem?.taken_qty)}
            </td>
          )}
        />
        <Column
          field="ready_qty_in_nos"
          title="Total Qty Nos"
          width={250}
          cell={(props: any) => (
            <td style={{ textAlign: "end" }}>
              {formatIndianNumberForQty(+props.dataItem?.ready_qty_in_nos)}
            </td>
          )}
        />
        <Column
          field="wastage_qty"
          title="Wastage Qty"
          width={250}
          cell={(props: any) => (
            <td style={{ textAlign: "end" }}>
              {formatIndianNumberForQty(+props.dataItem?.wastage_qty)}
            </td>
          )}
        />
        <Column
          field="raw_item_name"
          title="Raw Item"
          width={250}
          filterCell={CommonFilterCell}
        />
        <Column
          field="lot_no"
          title="Lot No"
          width={250}
        />
        <Column field="mfg_date" title="MFG Date" width={200} />
        <Column field="exp_date" title="EXP Date" width={200} />
        {(isEditAccess || isDeleteAccess) && (
          <Column
            field="id"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            locked={true}
            filterable={false}
          />
        )}
      </KendoGrid>
    </>
  );
};

const DeletePackingDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const PackingID = useAppSelector((state) => state.packing.PackingID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterPacking = useAppSelector((state) => state.packing.FilterPacking);

  const handleDeleteAction = async (ID: any) => {
    if (ID) {
      const response = await dispatch(deletePacking(ID));
      dispatch(setCurrentPage(0));
      const payload = {
        ...FilterPacking,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload.Data.Status === 200) {
        dispatch(getAllPacking(payload));
        dispatch(closeDialog());
        dispatch(clearPackingID());
      } else {
        dispatch(getAllPacking(payload));
        dispatch(closeDialog());
        dispatch(clearPackingID());
      }
    }
  };

  return (
    <>
      {dialogName === "deletePacking" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Packing"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Packing?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(PackingID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const CommonFilterCell = (props: any) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterPacking: any = useAppSelector(
    (state) => state.packing.FilterPacking
  );
  const isFilterRef = useRef(true);
  const [inputValue, setInputValue] = useState(props.value || "");
  const [inputField, setInputField] = useState(props.field || "");

  const onChange = (event: any) => {
    setInputValue(event.target.value);
    setInputField(event.target.name);
  };

  const delayedApiCall = React.useCallback(
    _debounce((updatedFilters: any) => {
      dispatch(setCurrentPage(0));
      const payload = {
        ...updatedFilters,
        limit: +pageLimit,
        skip: 0 * +pageLimit,
      };

      dispatch(getAllPacking(payload));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    if (!isFilterRef.current) {
      const updatedFilters = {
        ...FilterPacking,
        [inputField]: inputValue,
      };
      dispatch(setFilterPacking(updatedFilters));
      delayedApiCall(updatedFilters);
    } else {
      isFilterRef.current = false;
    }
    return () => {
      delayedApiCall.cancel();
    };
  }, [inputValue, delayedApiCall]);

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });

    const updatedFilters = { ...FilterPacking, [props.field]: "" };
    dispatch(setFilterPacking(updatedFilters));

    const payload = {
      ...updatedFilters,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };

    dispatch(getAllPacking(payload));
  };

  return (
    <div className="d-flex">
      <TextBox
        value={FilterPacking[props.field] || ""}
        name={props.field || ""}
        onChange={onChange}
      />
      <Button
        svgIcon={filterClearIcon}
        title="Clear"
        disabled={!FilterPacking[props.field]}
        onClick={onClearButtonClick}
        type="button"
        style={{
          marginLeft: "8px",
        }}
      ></Button>
    </div>
  );
};

export default Packing;
