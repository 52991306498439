import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { MdDelete } from "react-icons/md";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import {
  deleteStockTransfer,
  getAllStockTransfers,
} from "./services/stockTransfer.services";
import {
  clearFilterStockTransfer,
  clearStockTransferID,
  setFilterStockTransfer,
  setStockTransferID,
} from "./stockTransferSlice";
import moment from "moment";
import {
  checkAcessRights,
  formatIndianNumberForQty,
} from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { clearItemList } from "../Item/itemSlice";
import _debounce from "lodash/debounce";
import { filterClearIcon } from "@progress/kendo-svg-icons";
import { TextBox } from "@progress/kendo-react-inputs";

const StockTransfer: React.FC = () => {
  const navigate = useNavigate();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  const handleCreate = () => {
    navigate("/stocktransfer/create");
  };

  return (
    <>
      <DeleteStockTransferDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Stock Transfer List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              {"Add New"}
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <StockTransferGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const StockTransferGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const isEditBtnRef = useRef<any>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const StockTransferList = useAppSelector(
    (state) => state.stockTransfer.StockTransferList
  );
  const FilterStockTransfer = useAppSelector(
    (state) => state.stockTransfer.FilterStockTransfer
  );
  const loading = useAppSelector((state) => state.stockTransfer.loading);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  useEffect(() => {
    return () => {
      dispatch(clearItemList());
      dispatch(setCurrentPage(0));
      if (!isEditBtnRef.current) {
        dispatch(clearFilterStockTransfer());
      }
    };
  }, []);

  useEffect(() => {
    const payload = {
      ...FilterStockTransfer,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllStockTransfers(payload));
  }, [currentPage, pageLimit]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (stock_transfer_guid: number) => {
      navigate("/stocktransfer/edit", {
        state: { stock_transfer_guid: stock_transfer_guid },
      });
    };
    const handleOpenDeleteDialog = (stock_transfer_guid: number) => {
      dispatch(openDialog("deleteStockTransfer"));
      dispatch(setStockTransferID(stock_transfer_guid));
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            ref={isEditBtnRef}
            onClick={() => {
              isEditBtnRef.current = true;
              handleEdit(props.dataItem?.stock_transfer_guid);
            }}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() =>
              handleOpenDeleteDialog(props.dataItem?.stock_transfer_guid)
            }
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)" }}
        filterable={true}
        filter={filter}
        data={filter ? filterBy(StockTransferList, filter) : StockTransferList}
        onFilterChange={handleFilterChange}
        total={StockTransferList[0]?.itemcount || StockTransferList.length}
        skip={currentPage * pageLimit}
        take={pageLimit}
        pageable={{
          buttonCount: 5,
          pageSizes: [5, 10, 50, 100, 300, 500],
          pageSizeValue: pageLimit,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="stock_transfer_no"
          title="No"
          width={80}
          filterable={false}
          cell={(props: any) => (
            <td>
              <div
                className={isEditAccess ? "hoverableName" : "normalName"}
                onClick={() =>
                  isEditAccess &&
                  navigate("/stocktransfer/edit", {
                    state: {
                      stock_transfer_guid: props.dataItem?.stock_transfer_guid,
                    },
                  })
                }
              >
                {props.dataItem?.stock_transfer_no}
              </div>
            </td>
          )}
        />
        <Column
          field="stock_transfer_date"
          title="Date"
          cell={(props: any) => (
            <td>
              {props.dataItem?.stock_transfer_date
                ? moment(
                    props.dataItem?.stock_transfer_date,
                    "YYYY-MM-DD"
                  ).format("DD/MM/YYYY")
                : ""}
            </td>
          )}
        />
        <Column field="from_warehouse" title="From Warehouse No" width={150} />
        <Column field="to_warehouse" title="To Warehouse No" />
        <Column
          field="product_name"
          title="Product Name"
          filterCell={CommonFilterCell}
        />
        <Column field="grn_no" title="GRN No" />
        <Column
          field="stock_qty"
          title="Qty"
          cell={(props: any) => (
            <td style={{ textAlign: "end" }}>
              {formatIndianNumberForQty(+props.dataItem?.stock_qty)}
            </td>
          )}
        />
        <Column field="vacal_no" title="Vacal No" />
        {(isEditAccess || isDeleteAccess) && (
          <Column
            field="id"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
          />
        )}
      </KendoGrid>
    </>
  );
};

const DeleteStockTransferDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const StockTransferID = useAppSelector(
    (state) => state.stockTransfer.StockTransferID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterStockTransfer = useAppSelector(
    (state) => state.stockTransfer.FilterStockTransfer
  );

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteStockTransfer(ID));
      dispatch(setCurrentPage(0));
      const payload = {
        ...FilterStockTransfer,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload.Data.Status === 200) {
        dispatch(getAllStockTransfers(payload));
        dispatch(closeDialog());
        dispatch(clearStockTransferID());
      } else {
        dispatch(getAllStockTransfers(payload));
        dispatch(closeDialog());
        dispatch(clearStockTransferID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteStockTransfer" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Stock Transfer"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Stock Transfer?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(StockTransferID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const CommonFilterCell = (props: any) => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterStockTransfer: any = useAppSelector(
    (state) => state.stockTransfer.FilterStockTransfer
  );
  const isFilterRef = useRef(true);
  const [inputValue, setInputValue] = useState(props.value || "");
  const [inputField, setInputField] = useState(props.field || "");

  const onChange = (event: any) => {
    setInputValue(event.target.value);
    setInputField(event.target.name);
  };

  const delayedApiCall = React.useCallback(
    _debounce((updatedFilters: any) => {
      dispatch(setCurrentPage(0));
      const payload = {
        ...updatedFilters,
        limit: +pageLimit,
        skip: 0 * +pageLimit,
      };

      dispatch(getAllStockTransfers(payload));
    }, 300),
    [dispatch]
  );

  useEffect(() => {
    if (!isFilterRef.current) {
      const updatedFilters = {
        ...FilterStockTransfer,
        [inputField]: inputValue,
      };
      dispatch(setFilterStockTransfer(updatedFilters));
      delayedApiCall(updatedFilters);
    } else {
      isFilterRef.current = false;
    }
    return () => {
      delayedApiCall.cancel();
    };
  }, [inputValue, delayedApiCall]);

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });

    const updatedFilters = { ...FilterStockTransfer, [props.field]: "" };
    dispatch(setFilterStockTransfer(updatedFilters));

    const payload = {
      ...updatedFilters,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };

    dispatch(getAllStockTransfers(payload));
  };

  return (
    <div className="d-flex">
      <TextBox
        value={FilterStockTransfer[props.field] || ""}
        name={props.field || ""}
        onChange={onChange}
      />
      <Button
        svgIcon={filterClearIcon}
        title="Clear"
        disabled={!FilterStockTransfer[props.field]}
        onClick={onClearButtonClick}
        type="button"
        style={{
          marginLeft: "8px",
        }}
      ></Button>
    </div>
  );
};

export default StockTransfer;
