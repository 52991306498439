import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormTextField from "../../components/formFields/FormTextField";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ShadowCard from "../../components/common/ShadowCard";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import { getAllActiveAccounts } from "../account/services/account.services";
import { findAllActiveWarehouse } from "../warehouse/services/warehouse.services";
import { findAllActiveUnit } from "../unit/services/unit.services";
import {
  findAllActiveItem,
  getAllItemIncremental,
} from "../Item/services/item.services";
import { clearInwardReturnDetails, clearItemList } from "./inwardReturnSlice";
import InwardReturnItemDetailsArray from "./InwardReturnItemDetailsArray";
import {
  createInwardReturn,
  getAllItemByInwardNo,
  getInwardReturnByID,
  updateInwardReturn,
} from "./services/inwardReturn.services";
import moment from "moment";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import { FINANCIAL_YEAR } from "../../_contstants/common";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const QtychangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const inward_return_items = formRenderProps.valueGetter(
    "inward_return_items"
  );

  let totalamount = 0;
  let totalWeight = 0;

  useEffect(() => {
    if (inward_return_items && inward_return_items.length > 0) {
      inward_return_items?.map((item: any) => {
        totalWeight += +item?.weight || 0;
        totalamount += +item?.total_amount || 0;
      });
    }
    formRenderProps.onChange("totalWeight", {
      value: totalWeight,
    });
    formRenderProps.onChange("total_amount", {
      value: totalamount,
    });
  }, [
    inward_return_items.map((item: any) => item?.weight).join("-"),
    inward_return_items.map((item: any) => item?.total_amount).join("-"),
  ]);

  useEffect(() => {
    if (inward_return_items && inward_return_items.length > 0) {
      inward_return_items?.map((item: any, index: number) => {
        const weight = item?.weight || 0;
        const rate = item?.rate || 0;
        formRenderProps.onChange(`inward_return_items.${index}.total_amount`, {
          value: +weight * +rate,
        });
      });
    }
  }, [
    inward_return_items.map((item: any) => item?.weight).join("-"),
    inward_return_items.map((item: any) => item?.rate).join("-"),
  ]);
  return null;
};

const InwardChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const isTourDateIDRef = useRef(true);
  const location = useLocation();
  const inward_return_guid = location.state?.inward_return_guid;
  const bill_no = formRenderProps.valueGetter("bill_no");

  const fetchItemsDetails = () => {
    const payload = {
      inward_no: bill_no,
      financial_year: FINANCIAL_YEAR,
    };
    dispatch(getAllItemByInwardNo(payload));
  };

  useEffect(() => {
    if (isTourDateIDRef.current && inward_return_guid) {
      if (bill_no) {
        fetchItemsDetails();
      }
      isTourDateIDRef.current = false;
    }
  }, [inward_return_guid]);

  return null;
};

const CreateInwardReturn: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const inward_return_guid = location.state?.inward_return_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.inward.loading);
  const InwardReturnDetail = useAppSelector(
    (state) => state.inwardReturn.InwardReturnDetail
  );
  const AccountList = useAppSelector((state) => state.account.AccountList);
  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [InwardReturnDetail]);

  useEffect(() => {
    dispatch(getAllActiveAccounts());
    dispatch(findAllActiveItem());
    dispatch(findAllActiveUnit());
    dispatch(findAllActiveWarehouse());

    return () => {
      dispatch(clearInwardReturnDetails());
      dispatch(clearItemList());
    };
  }, []);

  useEffect(() => {
    if (inward_return_guid) {
      const payload = {
        inward_return_guid: inward_return_guid,
      };
      dispatch(getInwardReturnByID(payload));
    }
  }, [inward_return_guid]);

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (inward_return_guid) {
      try {
        const updatePayload = {
          id: values?.id ? +values?.id : null,
          inward_return_guid: inward_return_guid,
          inward_return_no: values?.inward_return_no
            ? +values?.inward_return_no
            : null,
          inward_return_date: values?.inward_return_date
            ? moment(values?.inward_return_date).format("YYYY-MM-DD")
            : "",
          vendor_id: values?.vendor_id ? +values?.vendor_id : null,
          bill_no: values?.bill_no ? values?.bill_no : "",
          vehicle_no: values?.vehicle_no ? values?.vehicle_no : "",
          chalan_no: values?.chalan_no ? values?.chalan_no : "",
          financial_year: FINANCIAL_YEAR,
          remarks: values?.remarks ? values?.remarks : "",
          inward_return_items: values?.inward_return_items
            ? values?.inward_return_items.map((inwardreturn: any) => {
                return {
                  id: inwardreturn?.id ? +inwardreturn?.id : 0,
                  inward_return_id: values?.id ? +values?.id : null,
                  item_id: inwardreturn?.item_id
                    ? +inwardreturn?.item_id
                    : null,
                  grn_no: inwardreturn?.grn_no ? inwardreturn?.grn_no : "",
                  weight: inwardreturn?.weight ? +inwardreturn?.weight : 0,
                  unit_id: inwardreturn?.unit_id
                    ? +inwardreturn?.unit_id
                    : null,
                  rate: inwardreturn?.rate ? +inwardreturn?.rate : null,
                  dagina: inwardreturn?.dagina ? +inwardreturn?.dagina : null,
                  total_amount: inwardreturn?.total_amount
                    ? +inwardreturn?.total_amount
                    : null,
                  warehouse_id: inwardreturn?.warehouse_id
                    ? +inwardreturn?.warehouse_id
                    : null,
                };
              })
            : [],
        };
        const response = await dispatch(updateInwardReturn(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/inwardreturn");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload = {
          inward_return_date: values?.inward_return_date
            ? moment(values?.inward_return_date).format("YYYY-MM-DD")
            : "",
          vendor_id: values?.vendor_id ? +values?.vendor_id : null,
          bill_no: values?.bill_no ? values?.bill_no : "",
          vehicle_no: values?.vehicle_no ? values?.vehicle_no : "",
          chalan_no: values?.chalan_no ? values?.chalan_no : "",
          financial_year: FINANCIAL_YEAR,
          remarks: values?.remarks ? values?.remarks : "",
          inward_return_items: values?.inward_return_items
            ? values?.inward_return_items.map((inwardreturn: any) => {
                return {
                  item_id: inwardreturn?.item_id
                    ? +inwardreturn?.item_id
                    : null,
                  grn_no: inwardreturn?.grn_no ? inwardreturn?.grn_no : "",
                  weight: inwardreturn?.weight ? +inwardreturn?.weight : 0,
                  unit_id: inwardreturn?.unit_id
                    ? +inwardreturn?.unit_id
                    : null,
                  rate: inwardreturn?.rate ? +inwardreturn?.rate : null,
                  dagina: inwardreturn?.dagina ? +inwardreturn?.dagina : null,
                  total_amount: inwardreturn?.total_amount
                    ? +inwardreturn?.total_amount
                    : null,
                  warehouse_id: inwardreturn?.warehouse_id
                    ? +inwardreturn?.warehouse_id
                    : null,
                };
              })
            : [],
        };
        const response = await dispatch(createInwardReturn(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/inwardreturn");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleInwardReturnNoChange = (
    ev: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (ev.key === "Enter" || ev.key === "Tab") {
      ev.preventDefault();
      if ((ev.target as HTMLInputElement).value) {
        const payload = {
          inward_return_no: +(ev.target as HTMLInputElement).value,
          financial_year: FINANCIAL_YEAR,
        };
        dispatch(getInwardReturnByID(payload));
      }
    }
  };
  const handleCheckInwardItem = (
    ev: React.KeyboardEvent<HTMLInputElement>,
    formRenderProps: FormRenderProps
  ) => {
    if (ev.key === "Enter" || ev.key === "Tab") {
      ev.preventDefault();
      const bill_no = (ev.target as HTMLInputElement).value;

      if ((ev.target as HTMLInputElement).value) {
        const fetchItemsDetails = async () => {
          const payload = {
            inward_no: bill_no,
            financial_year: FINANCIAL_YEAR,
          };
          const response = await dispatch(getAllItemByInwardNo(payload));
          if (response.meta.requestStatus === "fulfilled") {
            const itemsDetails = response.payload?.map((item: any) => {
              return {
                item_id: item?.item_id,
                grn_no: `${item?.grn_no}`,
                unit_id: item?.unit_id,
                warehouse_id: item?.cold_Storage_id,
                rate: item?.rate,
                weight: "",
                dagina: "",
              };
            });

            formRenderProps.onChange("inward_return_items", {
              value: itemsDetails || [],
            });
            formRenderProps.onChange("vendor_id", {
              value:
                response.payload && response.payload.length > 0
                  ? response.payload[0]?.vendor_id
                  : "",
            });
          } else {
            formRenderProps.onChange("vendor_id", {
              value: "",
            });
            formRenderProps.onChange("inward_return_items", {
              value: [{}],
            });
          }
        };
        fetchItemsDetails();
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={InwardReturnDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {inward_return_guid
                      ? "Update Inward Return"
                      : "Add Inward Return"}
                  </Typography.h4>
                </GridLayoutItem>
                {inward_return_guid && (
                  <GridLayoutItem>
                    <Field
                      name="inward_return_no"
                      onKeyDown={handleInwardReturnNoChange}
                      label="Inward Return No"
                      placeholder="Inward Return No"
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                )}
                {inward_return_guid && (
                  <GridLayoutItem colSpan={3}></GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="inward_return_date"
                    label="Inward Return Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="bill_no"
                    label="Inward No"
                    placeholder="0"
                    disabled={inward_return_guid ? true : false}
                    onKeyDown={(ev: React.KeyboardEvent<HTMLInputElement>) =>
                      handleCheckInwardItem(ev, formRenderProps)
                    }
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vendor_id"
                    label="Vendor"
                    placeholder="Party Name"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={AccountList?.map((account: any) => {
                      return {
                        value: account?.id,
                        label: account?.account_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem></GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="chalan_no"
                    label="Chalan No."
                    placeholder="Chalan No."
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vehicle_no"
                    label="Vehicle No"
                    placeholder="Vehicle No"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}></GridLayoutItem>
                <GridLayoutItem colSpan={3} rowSpan={3}>
                  <Field
                    name="remarks"
                    label="Remarks"
                    placeholder="Remarks"
                    component={FormRichTextField}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                gap={{ rows: 0, cols: 10 }}
                cols={[{ width: "100%" }]}
              >
                <GridLayoutItem>
                  <Typography.h4>Item Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem style={{ borderBottom: "1px solid lightgray" }}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={InwardReturnItemDetailsArray}
                    handleItemSearchChange={handleItemSearchChange}
                    name="inward_return_items"
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 15,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={inward_return_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={() => navigate("/inwardreturn")}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
              <QtychangeWatcher formRenderProps={formRenderProps} />
              <InwardChangeWatcher formRenderProps={formRenderProps} />
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateInwardReturn;
