import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import moment from "moment";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllRoasting = createAsyncThunk(
  "Roasting/FindAllRoasting",
  async (roasting: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Roasting/FindAllRoasting`,
        roasting
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Roastings:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createRoasting = createAsyncThunk(
  "Roasting/InsertRoasting",
  async (RoastingData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Roasting/InsertRoasting`,
        RoastingData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Roasting:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateRoasting = createAsyncThunk(
  "Roasting/UpdateRoasting",
  async (RoastingData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Roasting/UpdateRoasting`,
        RoastingData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Roasting:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteRoasting = createAsyncThunk(
  "Roasting/DeleteRoasting",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Roasting/DeleteRoasting`,
        { roasting_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Roasting:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRoastingByID = createAsyncThunk(
  "Roasting/FindByIDRoasting",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Roasting/FindByIDRoasting`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        roasting_guid: result?.roasting_guid,
        roasting_date: result?.roasting_date
          ? moment(result?.roasting_date, "YYYY-MM-DD").toDate()
          : "",
        roasting_no: result?.roasting_no,
        item_id: result?.item_id,
        item_options: result?.item_options,
        roasting: result?.roasting,
        grn_no: result?.grn_no,
        no_of_workers: result?.no_of_workers,
        machine_id: result?.machine_id,
        machine_name: result?.machine_name,
        start_time: result?.start_time
          ? moment(result?.start_time, "HH:mm:ss").toDate()
          : "",
        end_time: result?.end_time
          ? moment(result?.end_time, "HH:mm:ss").toDate()
          : "",
        total_qty: result?.total_qty,
        received_qty: result?.received_qty,
        process_loss_qty: result?.process_loss_qty,
        checker_by: +result?.checker_by,
        financial_year: result?.financial_year,
        // department_id: result?.department_id,
        process_id: result?.process_id,
        previous_process_id: result?.previous_process_id,
        remarks: result?.remarks,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Roasting:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveRoastings = createAsyncThunk(
  "Roasting/FindAllActiveRoasting",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Roasting/FindAllActiveRoasting`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching vendor types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createRoastingNew = createAsyncThunk(
  "Roasting/InsertRoastingNew",
  async (RoastingData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Roasting/InsertRoastingNew`,
        RoastingData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Roasting:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateRoastingNew = createAsyncThunk(
  "Roasting/UpdateRoastingNew",
  async (RoastingData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Roasting/UpdateRoastingNew`,
        RoastingData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Roasting:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRoastingByIDNew = createAsyncThunk(
  "Roasting/FindByIDRoastingNew",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Roasting/FindByIDRoastingNew`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        roasting_guid: result?.roasting_guid,
        roasting_date: result?.roasting_date
          ? moment(result?.roasting_date, "YYYY-MM-DD").toDate()
          : "",
        roasting_no: result?.roasting_no,
        ready_product_id: result?.ready_product_id,
        // item_id: result?.item_id,
        item_options: result?.item_options,
        roasting: result?.roasting,
        // grn_no: result?.grn_no,
        lot_no: result?.lot_no,
        no_of_workers: result?.no_of_workers,
        machine_id: result?.machine_id,
        machine_name: result?.machine_name,
        start_time: result?.start_time
          ? moment(result?.start_time, "HH:mm:ss").toDate()
          : "",
        end_time: result?.end_time
          ? moment(result?.end_time, "HH:mm:ss").toDate()
          : "",
        total_qty: result?.total_qty,
        received_qty: result?.received_qty,
        process_loss_qty: result?.process_loss_qty,
        checker_by: +result?.checker_by,
        financial_year: result?.financial_year,
        // department_id: result?.department_id,
        process_id: result?.process_id,
        // previous_process_id: result?.previous_process_id,
        remarks: result?.remarks,
        roasting_items: result?.roasting_items
          ? result?.roasting_items?.map((roastitem: any) => {
              return {
                id: roastitem?.id ? +roastitem?.id : null,
                process_id: roastitem?.process_id
                  ? +roastitem?.process_id
                  : null,
                item_id: roastitem?.item_id ? +roastitem?.item_id : null,
                grn_no: roastitem?.grn_no ? roastitem?.grn_no : "",
                taken_qty: roastitem?.taken_qty ? +roastitem?.taken_qty : null,
                wastage_qty: roastitem?.wastage_qty
                  ? +roastitem?.wastage_qty
                  : null,
                item_options: roastitem?.item_options
                  ? roastitem?.item_options
                  : [],
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Roasting:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const generateLotNoForRoasting = createAsyncThunk(
  "Roasting/YearWiseAutoLotNo",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Roasting/YearWiseAutoLotNo`,
        formData
      );
      return response.data;
    } catch (error: any) {
      console.error("Error generating lot no :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
