import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllAccounts = createAsyncThunk(
  "Account/FindAllAccount",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Account/FindAllAccount`,
        {
          account_name: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Accounts:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveAccountsUnderGroup = createAsyncThunk(
  "Account/FindAllActiveUnderGroup",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Account/FindAllActiveUnderGroup`
      );
      const result = response.data?.Data;
      return result.map((item: any) => {
        return {
          id: item?.id ? item?.id : null,
          under_group_name: item?.under_group_name
            ? item?.under_group_name
            : "",
          isactive: item?.isactive ? item?.isactive : false,
        };
      });
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Accounts:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createAccount = createAsyncThunk(
  "Account/InsertAccount",
  async (AccountData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Account/InsertAccount`,
        AccountData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating account:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateAccount = createAsyncThunk(
  "Account/UpdateAccount",
  async (AccountData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Account/UpdateAccount`,
        AccountData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating account:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "Account/DeleteAccount",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Account/DeleteAccount`,
        { account_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting account:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAccountByID = createAsyncThunk(
  "Account/FindByIDAccount",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Account/FindByIDAccount`,
        formData
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        account_guid: result.account_guid,
        under_group_id: result.under_group_id,
        user_type_id: result.user_type_id,
        user_name: result.user_name,
        password: result.password,
        under_group_name: result.under_group_name,
        account_name: result.account_name,
        short_alias_name: result.short_alias_name,
        contact_person_name: result.contact_person_name,
        email: result.email,
        mobile_number: result.mobile_number,
        address1: result.address1,        
        pincode: result.pincode,
        country_id: result.country_id,
        state_id: result.state_id,
        city_id: result.city_id,
        district_id: result?.district_id,        
        address2: result.address2,
        pincode2: result.pincode2,
        country_id2: result.country_id2,
        state_id2: result.state_id2,
        city_id2: result.city_id2,
        district_id2: result?.district_id2,
        payment_due_days: result.payment_due_days,
        provide_bank_details: result.provide_bank_details,
        account_no: result.account_no,
        ifsc_code: result.ifsc_code,
        bank_name: result.bank_name,
        bank_account_name: result.bank_account_name,
        enable_credit_limit: result.enable_credit_limit,
        limit_set_based_on_no_of_invoice:
          result.limit_set_based_on_no_of_invoice,
        limit_set_based_on_outstanding_amount:
          result.limit_set_based_on_outstanding_amount,
        opening_balance: result.opening_balance,
        pan_no: result.pan_no,
        gst_no: result.gst_no,
        route_id: result?.route_id,
        alternet_mobile_no: result?.alternet_mobile_no,
        openingbalance_credit_debit: result.openingbalance_credit_debit,
        isactive: result.isactive === true ? 1 : 2,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching account:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveAccounts = createAsyncThunk(
  "Account/FindAllActiveAccount",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Account/FindAllActiveAccount`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Accounts:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const AccountActiveInactive = createAsyncThunk(
  "Account/ActiveInActiveAccount",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Account/ActiveInActiveAccount`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive account:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllAccountIncremental = createAsyncThunk(
  "Account/FindAllAccountIncremental",
  async (search: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Account/FindAllAccountIncremental`,
        { account_name: search }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Account name:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
