import "./Layout.scss";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { FaGreaterThan, FaHandHoldingWater, FaLessThan } from "react-icons/fa";
import { CiMenuBurger } from "react-icons/ci";
import UserProfile from "./UserProfile";
import { ImCross } from "react-icons/im";
import { FaCircleUser } from "react-icons/fa6";
import { SiHiveBlockchain } from "react-icons/si";
import { MdDashboard } from "react-icons/md";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { INSERT_ACCESS } from "../../_contstants/common";
import { LuPackage2, LuPackageMinus, LuPackagePlus } from "react-icons/lu";
import { BsPersonVcardFill } from "react-icons/bs";
import { AiFillCodeSandboxSquare } from "react-icons/ai";
import { GiSteeringWheel } from "react-icons/gi";

// getItem("Distributor Opening Stock", "distributoropeningstock"),
// getItem("Retailer Sales Order", "retailersalesorder"),

const menuData = [
  {
    key: "dashboard",
    title: "Dashboard",
    icon: <MdDashboard />,
    quickMenu: [],
    sections: [],
  },
  {
    key: "usermanagement",
    title: "User Management",
    icon: <FaCircleUser />,
    quickMenu: [
      // { title: "Menu", key: "menu" },
      // { title: "Rights", key: "rights" },
      { title: "User", key: "user" },
      { title: "User Type", key: "usertype" },
    ],
    sections: [],
  },
  {
    key: "masters",
    title: "Masters",
    icon: <SiHiveBlockchain />,
    quickMenu: [],
    sections: [
      {
        title: "Place",
        children: [
          {
            title: "Country",
            key: "country",
          },
          {
            title: "State",
            key: "state",
          },
          {
            title: "City",
            key: "city",
          },
          {
            title: "District",
            key: "district",
          },
        ],
      },
      {
        title: "Item Configurations",
        children: [
          {
            title: "Item Group",
            key: "itemgroup",
          },
          {
            title: "Item Category",
            key: "itemcategory",
          },
          {
            title: "Unit",
            key: "unit",
          },
        ],
      },
      {
        title: "Items",
        children: [
          {
            title: "Raw Material Items",
            key: "rawmaterialitem",
          },
          {
            title: "Semi Finished Items",
            key: "semifinisheditem",
          },
          {
            title: "Finished Items",
            key: "finisheditem",
          },
          {
            title: "Packing Material Items",
            key: "packingmaterialitem",
          },
        ],
      },
      {
        title: "Ledger",
        children: [
          {
            title: "Account",
            key: "account",
          },
          {
            title: "GST",
            key: "gst",
          },
        ],
      },
      {
        title: "Generals",
        children: [
          {
            title: "Machine",
            key: "machine",
          },
          {
            title: "Warehouse",
            key: "warehouse",
          },
          {
            title: "Process",
            key: "process",
          },
          {
            title: "BOM",
            key: "bom",
          },
          {
            title: "Department",
            key: "department",
          },
          {
            title: "Terms & Conditions",
            key: "termandcondition",
          },
          {
            title: "Employee",
            key: "employee",
          },
          {
            title: "Retailer",
            key: "retailer",
          },
          {
            title: "Designation",
            key: "designation",
          },
          {
            title: "Document",
            key: "document",
          },
          {
            title: "Holiday",
            key: "holiday",
          },
          {
            title: "Leave",
            key: "leave",
          },
          {
            title: "Relation",
            key: "relation",
          },
          {
            title: "Profession Tax Slab",
            key: "professiontaxslab",
          },
          {
            title: "Employee Leave",
            key: "employeeleave",
          },
          {
            title: "Routes",
            key: "routes",
          },
        ],
      },
    ],
  },
  {
    key: "purchasemenu",
    title: "Purchase",
    icon: <LuPackagePlus />,
    quickMenu: [
      { title: "Add Purchase Order", key: "purchaseorder/create" },
      { title: "Add Inward", key: "inward/create" },
      { title: "Add Purchase", key: "purchase/create" },
    ],
    sections: [
      {
        title: "Transctions",
        children: [
          {
            title: "Purchase Order",
            key: "purchaseorder",
          },
          {
            title: "Inward",
            key: "inward",
          },
          {
            title: "Inward Return",
            key: "inwardreturn",
          },
          {
            title: "Purchase",
            key: "purchase",
          },
        ],
      },
      {
        title: "Reports",
        children: [
          {
            title: "Inward Register",
            key: "inwardregisterreportview",
          },
          {
            title: "Purchase Due Date",
            key: "purchasedurdatereportview",
          },
        ],
      },
    ],
  },
  {
    key: "productionmenu",
    title: "Production",
    icon: <AiFillCodeSandboxSquare />,
    quickMenu: [
      {
        title: "Add Product Cleaning",
        key: "productcleaning/create",
      },
      {
        title: "Add Product Milling",
        key: "productmilling/create",
      },
      {
        title: "Add Roasting",
        key: "roasting/create",
      },
      {
        title: "Add Production",
        key: "production/create",
      },
      {
        title: "Add Packing",
        key: "packing/create",
      },
      {
        title: "Add Stock Transfer",
        key: "stocktransfer/create",
      },
      {
        title: "Add Magnet Cleaning",
        key: "magnetcleaning/create",
      },
      {
        title: "Add Machine Cleaning",
        key: "machinecleaning/create",
      },
    ],
    sections: [
      {
        title: "Process",
        children: [
          {
            title: "Product Cleaning",
            key: "productcleaning",
          },
          {
            title: "Product Milling",
            key: "productmilling",
          },
          {
            title: "Roasting",
            key: "roasting",
          },
          {
            title: "Production",
            key: "production",
          },
          {
            title: "Packing",
            key: "packing",
          },
          {
            title: "Stock Transfer",
            key: "stocktransfer",
          },
        ],
      },
      {
        title: "Cleaning",
        children: [
          {
            title: "Magnet Cleaning",
            key: "magnetcleaning",
          },
          {
            title: "Machine Cleaning",
            key: "machinecleaning",
          },
        ],
      },
      {
        title: "Reports",
        children: [
          {
            title: "Product Cleaning",
            key: "productcleaningreportview",
          },
          {
            title: "Product Milling",
            key: "productmillingreportview",
          },
          {
            title: "Roasting",
            key: "roastingreportview",
          },
          {
            title: "Production",
            key: "productionreportview",
          },
          {
            title: "Ghanti Cleaning",
            key: "ghanticleaningreportview",
          },
          {
            title: "Metal Cleaning",
            key: "metalcleaningreportview",
          },
          {
            title: "Stock",
            key: "stockreportview",
          },
        ],
      },
    ],
  },
  {
    key: "salesmenu",
    title: "Sales",
    icon: <LuPackageMinus />,
    quickMenu: [
      {
        title: "Add Sales Order",
        key: "salesorder/create",
      },
      {
        title: "Add Sales Challan",
        key: "saleschallan/create",
      },
      {
        title: "Add Sales Invoice",
        key: "sales/create",
      },
    ],
    sections: [
      {
        title: "Transctions",
        children: [
          {
            title: "Sales Order",
            key: "salesorder",
          },
          {
            title: "Sales Challan",
            key: "saleschallan",
          },
          {
            title: "Sales Invoice",
            key: "sales",
          },
          {
            title: "Retailer Sales Order",
            key: "retailersalesorder",
          },
          {
            title: "Distributor Opening Stock",
            key: "distributoropeningstock",
          },
          {
            title: "Distributor Current Stock",
            key: "distributorcurrentstock",
          },
        ],
      },
    ],
  },
  {
    key: "visitormenu",
    title: "Visitor",
    icon: <BsPersonVcardFill />,
    quickMenu: [
      {
        title: "Add Visitor",
        key: "visitor/create",
      },
      {
        title: "Add Office Staff Out",
        key: "officestaffout/create",
      },
    ],
    sections: [
      {
        title: "Transctions",
        children: [
          {
            title: "Visitor",
            key: "visitor",
          },
          {
            title: "Office Staff Out",
            key: "officestaffout",
          },
        ],
      },
      {
        title: "Report",
        children: [
          {
            title: "Visitor Report",
            key: "visitorreportview",
          },
          {
            title: "Office Staff Out",
            key: "officestaffoutreportview",
          },
        ],
      },
    ],
  },
  {
    key: "packingmaterialmenu",
    title: "Packing Material",
    icon: <LuPackage2 />,
    quickMenu: [
      {
        title: "Add Inward Packing Material",
        key: "inwardpackingmaterial/create",
      },
      {
        title: "Add Outward Packing Material",
        key: "outwardpackingmaterial/create",
      },
    ],
    sections: [
      {
        title: "Transctions",
        children: [
          {
            title: "Inward Packing Material",
            key: "inwardpackingmaterial",
          },
          {
            title: "Outward Packing Material",
            key: "outwardpackingmaterial",
          },
        ],
      },
      {
        title: "Report",
        children: [
          {
            title: "Inward Packing Material Stock Detail",
            key: "inwardpackingstockreportview",
          },
          {
            title: "Outward Packing Material Stock Detail",
            key: "outwordpackingstockreportview",
          },
          {
            title: "Packing Register",
            key: "packingregisterreportview",
          },
          {
            title: "Packing Material Stock",
            key: "packingmaterialstockreportview",
          },
        ],
      },
    ],
  },
  {
    key: "hrmenu",
    title: "HR",
    icon: <FaHandHoldingWater />,
    quickMenu: [],
    sections: [
      {
        title: "Salary",
        children: [
          {
            title: "Process Payroll",
            key: "processpayroll",
          },
          {
            title: "Salary Generation",
            key: "salarygeneration",
          },
          {
            title: "Salary View",
            key: "salarygenerationview",
          },
        ],
      },
    ],
  },
  {
    key: "drivermenu",
    title: "Driver",
    icon: <GiSteeringWheel />,
    quickMenu: [],
    sections: [
      {
        title: "Driver",
        children: [
          {
            title: "Driver Visit",
            key: "drivervisit",
          },
        ],
      },
    ],
  },
];

const Layout = () => {
  const navigate = useNavigate();

  const userResponseString = localStorage.getItem("UserRightsAssign") || "[]";
  let userRights: {
    menu_id: number;
    menu_key: string;
    menu_name: string;
    rights_id: string;
  }[];

  try {
    userRights = JSON.parse(userResponseString);
  } catch (error) {
    userRights = [];
  }

  // const allowedKeys = new Set(userResponse.map((item) => item.menu_key));

  const filterMenuByUserRights = (menuData: any, userRights: any) => {
    const hasRight = (key: string) => {
      if (key.includes("/create")) {
        const baseKey = key.replace("/create", "");
        const userMenu = userRights?.find(
          (menu: any) => menu.menu_key === baseKey
        );
        return (
          userMenu && userMenu.rights_id.split(", ").includes(INSERT_ACCESS)
        );
      } else {
        return userRights?.some((menu: any) => menu.menu_key === key);
      }
    };

    return menuData
      .map((menu: any) => {
        const mainMenuHasRight = hasRight(menu.key);

        if (!mainMenuHasRight) return null;

        const filteredQuickMenu = menu.quickMenu.filter((item: any) =>
          hasRight(item.key)
        );
        const filteredSections = menu.sections
          .map((section: any) => {
            const filteredChildren = section.children.filter((child: any) =>
              hasRight(child.key)
            );
            return { ...section, children: filteredChildren };
          })
          .filter((section: any) => section.children.length > 0);
        if (
          hasRight(menu.key) ||
          filteredQuickMenu.length > 0 ||
          filteredSections.length > 0
        ) {
          return {
            ...menu,
            quickMenu: filteredQuickMenu,
            sections: filteredSections,
          };
        }
        return null;
      })
      .filter((menu: any) => menu !== null);
  };

  const filteredMenus = filterMenuByUserRights(menuData, userRights);

  const toggleMenu = () => {
    document.querySelectorAll(".navbar-nav > li").forEach((element) => {
      element.addEventListener("click", function (this: HTMLElement) {
        document.querySelector(".page-contect")?.classList.remove("in");
        document.querySelectorAll(".navbar-nav > li").forEach((li) => {
          li.classList.remove("active");
          const popup = document.querySelector(
            ".navbar-nav > li.active .subnav-content"
          );
          if (popup) {
            const popupElement = popup as HTMLElement;
            popupElement.classList.remove("d-block");
            popupElement.classList.add("d-none");
          }
        });
        this.classList.add("active");
        const popup = document.querySelector(
          ".navbar-nav > li.active .subnav-content"
        );
        if (popup) {
          const popupElement = popup as HTMLElement;
          popupElement.classList.remove("d-none");
          popupElement.classList.add("d-block");
        }
      });
    });
  };

  useEffect(() => {
    toggleMenu();
  }, []);

  const toggleSubMenu = () => {
    const popup = document.querySelector(
      ".navbar-nav > li.active .subnav-content"
    );
    if (popup) {
      const popupElement = popup as HTMLElement;
      popupElement.classList.remove("d-block");
      popupElement.classList.add("d-none");
    }

    document.querySelectorAll(".navbar-nav > li").forEach((li) => {
      li.classList.remove("active");
    });
  };

  const toggleNav = () => {
    const sidenav = document.querySelector(".side-nav") as any;
    const mbottom = document.querySelector(".m-bottom") as any;
    const gmnavbarheader = document.querySelector(".gmnavbar-header") as any;
    const pagewrapper = document.querySelector(".page-wrapper") as any;
    const pagecontect = document.querySelectorAll(".page-contect") as any;
    const menuName = document.querySelectorAll(".menu-name") as any;
    const greaterThan = document.querySelector(".greaterthan") as any;
    const lessThan = document.querySelector(".lessthan") as any;

    const isOpen = sidenav.style.width === "210px";
    const isHeaderOpen = gmnavbarheader.style.width === "210px";
    sidenav.style.width = isOpen ? "60px" : "210px";
    mbottom.style.width = isOpen ? "60px" : "210px";
    pagewrapper.style.marginLeft = isOpen ? "60px" : "210px";
    gmnavbarheader.style.width = isHeaderOpen ? "60px" : "210px";
    pagecontect.forEach((element: any) => {
      if (isOpen) {
        element.style.left = "60px";
      } else {
        element.style.left = "210px";
      }
    });
    menuName.forEach((element: any) => {
      if (isOpen) {
        element.style.transition = "0.5s";
        element.classList.remove("d-inline");
        element.classList.add("d-none");
      } else {
        element.style.transition = "0.5s";
        element.classList.remove("d-none");
        element.classList.add("d-inline");
      }
    });
    if (isOpen) {
      lessThan.classList.remove("d-block");
      lessThan.classList.add("d-none");
      greaterThan.classList.remove("d-none");
      greaterThan.classList.add("d-block");
    } else {
      lessThan.classList.remove("d-none");
      lessThan.classList.add("d-block");
      greaterThan.classList.remove("d-block");
      greaterThan.classList.add("d-none");
    }
  };

  const handleNavigate = (url: string) => {
    if (url) {
      toggleSubMenu();
      navigate(`${url}`);
    }
  };

  return (
    <div id="wrapper">
      <nav className="gmnavbar" role="navigation">
        <div className="gmnavbar-header" style={{ width: 210 }}>
          <button type="button" className="navbar-toggle">
            <CiMenuBurger />
          </button>
          <h3 className="m-0">GM</h3>
        </div>
        <div className="d-flex align-items-center">
          <UserProfile />
        </div>
      </nav>
      <div className="collapse navbar-collapse navbar-ex1-collapse show">
        <ul
          className="nav navbar-nav side-nav"
          style={{ zIndex: 10, scrollbarWidth: "none", width: 210 }}
        >
          {filteredMenus.map((menu: any, index: any) => (
            <li className="subnav" key={index} onClick={toggleMenu}>
              <p
                className="collapsed"
                onClick={() => menu.key === "dashboard" && navigate("/")}
              >
                {menu.icon}
                <span className="menu-name">{menu.title || ""}</span>
              </p>
              {(menu.quickMenu?.length > 0 || menu.sections?.length > 0) && (
                <div className="subnav-content page-contect">
                  <div className="container-fluid">
                    <div className="container-title">
                      <h2>{menu.title} - Quick Menu</h2>
                      <button className="model-close" onClick={toggleSubMenu}>
                        <ImCross />
                      </button>
                    </div>
                    {menu.quickMenu?.length > 0 && (
                      <div className="quick-menu">
                        <div className="quick-menu-cards">
                          {menu.quickMenu.map((item: any) => (
                            <div
                              key={item.key}
                              className="quick-menu-card"
                              onClick={() => handleNavigate(item.key)}
                            >
                              <h5>{item.title}</h5>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <div className="section-list">
                      {menu.sections?.map(
                        (section: any) =>
                          section.children?.length > 0 && (
                            <div key={section.title} className="section">
                              <h4>{section.title}</h4>
                              <ul>
                                {section.children.map((child: any) => (
                                  <Tooltip
                                    anchorElement="target"
                                    position="top"
                                    parentTitle={true}
                                  >
                                    <li
                                      key={child.key}
                                      title={child.title}
                                      onClick={() => handleNavigate(child.key)}
                                    >
                                      {child.title}
                                    </li>
                                  </Tooltip>
                                ))}
                              </ul>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
              )}
            </li>
          ))}
          <div className="m-bottom" onClick={toggleNav}>
            <a href="javascript:void(0)" className="m-arrow">
              <FaGreaterThan className="greaterthan d-none" />
              <FaLessThan className="lessthan" />
            </a>
          </div>
        </ul>
      </div>

      <div className="all-page">
        <div className="page-wrapper">
          <div className="page-information ">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
