import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getSalesOrderPrint } from "./services/salesOrder.services";
import { convertToWords, formatIndianNumber } from "../../_helper/helper";

const SalesOrderPrint = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const sale_order_guid = params?.sale_order_guid;
  const SalesOrderPrintDetail = useAppSelector(
    (state) => state.salesOrder.SalesOrderPrintDetail
  );

  useEffect(() => {
    if (sale_order_guid) {
      const handleSalesOrderPrint = async () => {
        const res = await dispatch(getSalesOrderPrint(sale_order_guid));
        if (res.meta.requestStatus === "fulfilled") {
          window.print();
        }
      };
      handleSalesOrderPrint();
    }
  }, [sale_order_guid]);

  let totalAmount = 0;
  let totalTaxableAmount = 0;

  SalesOrderPrintDetail?.sales_order_item &&
    SalesOrderPrintDetail?.sales_order_item.length > 0 &&
    SalesOrderPrintDetail?.sales_order_item?.map(
      (salesorderitem: any) =>
        salesorderitem?.sales_items &&
        salesorderitem?.sales_items.length > 0 &&
        salesorderitem?.sales_items?.map(
          (item: any) => (totalAmount += item?.amount)
        )
    );

  SalesOrderPrintDetail?.gst &&
    SalesOrderPrintDetail?.gst?.length > 0 &&
    SalesOrderPrintDetail?.gst?.map((gstamount: any) => {
      const totaltax =
        gstamount?.total_taxable_amount &&
        gstamount?.gst &&
        (gstamount?.total_taxable_amount * gstamount?.gst) / 100;
      totalTaxableAmount += totaltax;
    });

  return (
    <div
      className="p-4 salesorder-print-page"
      style={{ maxWidth: 1140, margin: "0 auto" }}
    >
      <table className="table table-bordered w-100" style={{ minWidth: 500 }}>
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td>
            <p className="m-0" style={{ padding: "2px 8px" }}>
              <span className="fw-600 d-block" style={{ fontSize: 12 }}>
                Dealer Details:
              </span>
              <span className="fw-600">Name</span> :{" "}
              {SalesOrderPrintDetail?.account_name} <br />
              <span className="fw-600">GST No.</span>:{" "}
              {SalesOrderPrintDetail?.gst_no} <br />
              <span className="fw-600">Address</span> :{" "}
              {SalesOrderPrintDetail?.address1}{" "}
              {/* {SalesOrderPrintDetail?.address2} */}
              <br />
              <span className="fw-600">State</span>:{" "}
              {SalesOrderPrintDetail?.state_name}, &nbsp;
              <span className="fw-600">State Code</span>:{" "}
              {SalesOrderPrintDetail?.gst_state_code}
            </p>
          </td>
        </tr>
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td>
            <p className="m-0" style={{ padding: "2px 8px" }}>
              <span className="fw-600">Mobile No.</span>:{" "}
              {SalesOrderPrintDetail?.mobile_number}
              {SalesOrderPrintDetail?.mobile_number &&
                SalesOrderPrintDetail?.alternet_mobile_no &&
                ", "}
              {SalesOrderPrintDetail?.alternet_mobile_no}
            </p>
          </td>
        </tr>
      </table>
      <table
        className="table table-bordered w-100 m-0"
        style={{ borderBottom: "2px solid black", minWidth: 500 }}
      >
        <tr style={{ border: "1px solid rgba(0, 0, 0, 0.5)" }}>
          <td>
            <p className="m-0" style={{ padding: "0 8px" }}>
              <span className="fw-600">Order No</span> :{" "}
              {SalesOrderPrintDetail?.sales_order_no} <br />
              <span className="fw-600">Order Date</span>:{" "}
              {SalesOrderPrintDetail?.sales_date} <br />
            </p>
          </td>
        </tr>
      </table>
      <table
        cellSpacing={1}
        cellPadding={2}
        className="table table-bordered w-100  salesorder-print-table"
        style={{ marginTop: 1 }}
      >
        <tr className="text-center fw-600" style={{ fontSize: 12 }}>
          <th style={{ width: "10%" }}>Sr No.</th>
          <th style={{ width: "40%" }}>Description Of Goods</th>
          <th style={{ width: "10%" }}>MRP</th>
          <th style={{ width: "10%" }}>Quantity</th>
          <th style={{ width: "10%" }}>Per</th>
          <th style={{ width: "10%" }}>Rate</th>
          <th style={{ width: "10%" }}>Amount</th>
        </tr>
        {SalesOrderPrintDetail?.sales_order_item &&
          SalesOrderPrintDetail?.sales_order_item.length > 0 &&
          SalesOrderPrintDetail?.sales_order_item?.map((item: any) => (
            <>
              <tr>
                <td className="fw-600" colSpan={7} style={{ fontSize: 12 }}>
                  {item?.item_group}
                </td>
              </tr>
              {item?.sales_items &&
                item?.sales_items?.length > 0 &&
                item?.sales_items?.map((subitem: any, index: number) => (
                  <tr>
                    <td className="text-center">{index + 1}</td>
                    <td>{subitem?.product_name}</td>
                    <td>Rs. {subitem?.mrp || 0}</td>
                    <td className="text-center">{subitem?.quantity}</td>
                    <td className="text-center">{subitem?.unit_name}</td>
                    <td className=" text-end">
                      {formatIndianNumber(subitem?.rate || 0)}
                    </td>
                    <td className=" text-end">
                      {formatIndianNumber(subitem?.amount || 0)}
                    </td>
                  </tr>
                ))}
            </>
          ))}
        <tr>
          <td colSpan={6}></td>
          <td className="text-end fw-600" style={{ fontSize: 12 }}>
            {formatIndianNumber(totalAmount)}
          </td>
        </tr>
        {SalesOrderPrintDetail?.gst &&
          SalesOrderPrintDetail?.gst.length > 0 &&
          SalesOrderPrintDetail?.gst?.map((gstdetails: any) =>
            SalesOrderPrintDetail?.state_name === "GUJARAT" ? (
              <>
                <tr>
                  <td></td>
                  <td className=" text-end">CGST {gstdetails?.gst / 2}%</td>
                  <td></td>
                  <td></td>
                  <td className=" text-end">
                    {(gstdetails?.gst / 2)?.toFixed(2)}
                  </td>
                  <td>%</td>
                  <td className=" text-end">
                    {gstdetails?.total_taxable_amount &&
                      gstdetails?.gst &&
                      formatIndianNumber(
                        (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                          100 /
                          2
                      )}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className=" text-end">SGST {gstdetails?.gst / 2}%</td>
                  <td></td>
                  <td></td>
                  <td className=" text-end">
                    {(gstdetails?.gst / 2)?.toFixed(2)}
                  </td>
                  <td>%</td>
                  <td className=" text-end">
                    {gstdetails?.total_taxable_amount &&
                      gstdetails?.gst &&
                      formatIndianNumber(
                        (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                          100 /
                          2
                      )}
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td></td>
                <td className=" text-end">IGST {gstdetails?.gst}%</td>
                <td></td>
                <td></td>
                <td className=" text-end">{gstdetails?.gst?.toFixed(2)}</td>
                <td>%</td>
                <td className=" text-end">
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                    )}
                </td>
              </tr>
            )
          )}
        <tr style={{ fontSize: 12 }}>
          <td colSpan={6} className=" text-end fw-600">
            {" "}
            Total Invoice Amount:
          </td>
          <td className="text-end fw-600">
            {formatIndianNumber(Math.round(+totalAmount + +totalTaxableAmount))}
          </td>
        </tr>
        <tr>
          <td colSpan={6}>
            <span className="d-block p-0" style={{ fontSize: 8 }}>
              Amount Chargeable (in words)
            </span>
            <span
              className="d-block fw-600 p-0"
              style={{ fontSize: 12, textTransform: "uppercase" }}
            >
              {convertToWords(Math.round(+totalAmount + +totalTaxableAmount))}
            </span>
          </td>
          <td className="text-end">E & O.E</td>
        </tr>
        {SalesOrderPrintDetail?.state_name === "GUJARAT" ? (
          <>
            <tr className="text-center fw-600" style={{ fontSize: 13 }}>
              <th rowSpan={2}>Taxable Value</th>
              <th colSpan={2}>Central Tax</th>
              <th colSpan={2}>State Tax</th>
              <th rowSpan={2} colSpan={2}>
                Total Tax Amount
              </th>
            </tr>
            <tr className="text-center" style={{ fontSize: 13 }}>
              <th>Rate</th>
              <th>Amount</th>
              <th>Rate</th>
              <th>Amount</th>
            </tr>
          </>
        ) : (
          <>
            <tr className="text-center" style={{ fontSize: 13 }}>
              <th rowSpan={2}>Taxable Value</th>
              <th colSpan={4}>Integrated Tax</th>
              <th rowSpan={2} colSpan={2}>
                Total Tax Amount
              </th>
            </tr>
            <tr className="text-center" style={{ fontSize: 13 }}>
              <th>Rate</th>
              <th colSpan={3}>Amount</th>
            </tr>
          </>
        )}
        {SalesOrderPrintDetail?.gst &&
          SalesOrderPrintDetail?.gst?.length > 0 &&
          SalesOrderPrintDetail?.gst?.map((gstdetails: any) =>
            SalesOrderPrintDetail?.state_name === "GUJARAT" ? (
              <tr className="text-end">
                <td>{formatIndianNumber(gstdetails?.total_taxable_amount)}</td>
                <td>{formatIndianNumber(gstdetails?.gst / 2)} %</td>
                <td className=" ">
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                        100 /
                        2
                    )}
                </td>
                <td>{formatIndianNumber(gstdetails?.gst / 2)} %</td>
                <td>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) /
                        100 /
                        2
                    )}
                </td>
                <td colSpan={2}>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                    )}
                </td>
              </tr>
            ) : (
              <tr className="text-end">
                <td>{formatIndianNumber(gstdetails?.total_taxable_amount)}</td>
                <td>{formatIndianNumber(gstdetails?.gst)} %</td>
                <td colSpan={3}>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                    )}
                </td>
                <td colSpan={2}>
                  {gstdetails?.total_taxable_amount &&
                    gstdetails?.gst &&
                    formatIndianNumber(
                      (gstdetails?.total_taxable_amount * gstdetails?.gst) / 100
                    )}
                </td>
              </tr>
            )
          )}
        {SalesOrderPrintDetail?.state_name === "GUJARAT" ? (
          <tr className="text-end fw-600" style={{ fontSize: 13 }}>
            <td>{formatIndianNumber(totalAmount)}</td>
            <td></td>
            <td>{formatIndianNumber(totalTaxableAmount / 2)}</td>
            <td></td>
            <td>{formatIndianNumber(totalTaxableAmount / 2)}</td>
            <td colSpan={2}>{formatIndianNumber(totalTaxableAmount)}</td>
          </tr>
        ) : (
          <tr className="text-end fw-600" style={{ fontSize: 13 }}>
            <td>{formatIndianNumber(totalAmount)}</td>
            <td></td>
            <td colSpan={3}>{formatIndianNumber(totalTaxableAmount)}</td>
            <td colSpan={2}>{formatIndianNumber(totalTaxableAmount)}</td>
          </tr>
        )}
        <tr>
          <td colSpan={7} style={{ textAlign: "end" }}>
            <span className="d-block p-0" style={{ fontSize: 8 }}>
              Tax Amount (in words)
            </span>
            <span
              className="d-block fw-600 p-0"
              style={{ fontSize: 12, textTransform: "uppercase" }}
            >
              {convertToWords(+(+totalTaxableAmount)?.toFixed(2))}
            </span>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default SalesOrderPrint;
