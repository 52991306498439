import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPacking, IPackingInitial } from "./packingModel";
import {
  createPacking,
  deletePacking,
  getAllPacking,
  getPackingById,
  updatePacking,
} from "./services/packing.services";
import moment from "moment";

const initialState: IPackingInitial = {
  loading: false,
  formLoading: false,
  error: "",
  PackingList: [],
  PackingID: null,
  PackingDetail: {
    packing_date: moment().toDate(),
    raw_item: null,
    lot_no: null,
    packing_item: null,
    batch_no: null,
    taken_qty: null,
    used_qty: null,
    wastage_qty: null,
    ready_qty_in_nos: null,
    mfg_date: moment().toDate(),
    exp_date: "",
    remarks: "",
    verify_by: null,
    financial_year: null,
  },
  FilterPacking: {
    packing_item_name: "",
    raw_item_name: "",
    batch_no: 0,
    lot_no: "",
  },
};

export const packingSlice = createSlice({
  name: "packing",
  initialState,
  reducers: {
    clearPackingDetails: (state) => {
      state.PackingDetail = initialState.PackingDetail;
    },
    setPackingID: (state, action) => {
      state.PackingID = action.payload;
    },
    clearPackingID: (state) => {
      state.PackingID = null;
    },
    setFilterPacking: (state, action) => {
      state.FilterPacking = action.payload;
    },
    clearFilterPacking: (state) => {
      state.FilterPacking = initialState.FilterPacking;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPacking.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllPacking.fulfilled,
      (state, action: PayloadAction<IPacking[]>) => {
        state.loading = false;
        state.PackingList = action.payload;
      }
    );
    builder.addCase(getAllPacking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PackingList = [];
    });
    builder.addCase(createPacking.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createPacking.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createPacking.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updatePacking.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updatePacking.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updatePacking.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deletePacking.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deletePacking.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePacking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getPackingById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getPackingById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.PackingDetail = action.payload;
    });
    builder.addCase(getPackingById.rejected, (state, action) => {
      state.formLoading = false;
      state.PackingDetail = initialState.PackingDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearPackingDetails,
  setPackingID,
  clearPackingID,
  setFilterPacking,
  clearFilterPacking,
} = packingSlice.actions;
export default packingSlice.reducer;
