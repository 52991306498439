import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IDistributorCurrentStockDetails,
  IGhantiCleaningReportList,
  IInwardPackingMaterialStockReportList,
  IInwardRegisterList,
  IMetalCleaningReportList,
  IOfficeStaffOutReportList,
  IOutwardPackingMaterialStockReportList,
  IPackingMaterialStockReportList,
  IPackingRegisterReportList,
  IProductCleaningReportList,
  IProductionReportList,
  IProductMillingReportList,
  IPurchaseDueDateReportList,
  IReportInitialState,
  IRoastingReportList,
  IStockReportList,
  IVisitorReportList,
} from "./reportsModel";
import {
  getDistributorCurrentStockReportByID,
  getGhantiCleaningReport,
  getInwardPackingMaterialStockReport,
  getInwardRegisterReport,
  getItemStockReport,
  getMetalCleaningReport,
  getOfficeStaffOutReport,
  getOutwardPackingMaterialStockReport,
  getPackingMaterialStockReport,
  getPackingRegisterReport,
  getProductCleaningReport,
  getProductionReport,
  getProductMillingReport,
  getPurchaseDueDateInvoiceReport,
  getRoastingReport,
  getVisitorReport,
} from "./services/reports.services";

const initialState: IReportInitialState = {
  loading: false,
  listLoading: false,
  error: "",
  ProductMillingReportList: [],
  ProductMillingReportDetails: {
    item_id: null,
    from_date: "",
    to_date: "",
  },
  ProductionReportList: [],
  ProductionReportDetails: {
    item_id: null,
    from_date: "",
    to_date: "",
  },
  GhantiCleaningReportList: [],
  GhantiCleaningReportDetails: {
    machine_no: null,
    from_date: "",
    to_date: "",
  },
  MetalCleaningReportList: [],
  MetalCleaningReportDetails: {
    item_id: null,
    machine_no: null,
    from_date: "",
    to_date: "",
  },
  ProductCleaningReportList: [],
  ProductCleaningReportDetails: {
    item_id: null,
    from_date: "",
    to_date: "",
  },
  RoastingReportList: [],
  RoastingReportDetails: {
    item_id: null,
    from_date: "",
    to_date: "",
  },
  InwardPackingMaterialStockList: [],
  InwardPackingMaterialStocksDetails: {
    item_id: null,
    from_date: "",
    to_date: "",
  },
  OutwardPackingMaterialStockList: [],
  OutwardPackingMaterialStocksDetails: {
    item_id: null,
    from_date: "",
    to_date: "",
  },
  InwardRegisterList: [],
  InwardRegisterDetails: {
    item_id: null,
    start_date: "",
    end_Date: "",
  },
  StockReportList: [],
  StockReportDetails: {
    item_id: null,
    grn_no: null,
  },
  PackingRegisterReportList: [],
  PackingRegisterReportDetails: {
    item_id: null,
    from_date: "",
    to_date: "",
  },
  PackingMaterialStockReportList: [],
  PackingMaterialStockReportDetails: {
    item_id: null,
    from_date: "",
    to_date: "",
  },
  VisitorReportList: [],
  VisitorReportDetails: {
    user_name: "",
    from_date: "",
    to_date: "",
  },
  OfficeStaffOutReportList: [],
  OfficeStaffOutReportDetails: {
    user_name: "",
    from_date: "",
    to_date: "",
  },
  PurchaseDueDateReportList: [],
  PurchaseDueDateReportDetails: {
    from_date: "",
    to_date: "",
  },
  DistributorCurrentStockDetails: {
    accountid: null,
    date: "",
    distributer_stock: [],
  },
};

const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setProductMillingReport: (state, action) => {
      state.ProductMillingReportDetails = action.payload;
    },
    clearProductMillingReportList: (state) => {
      state.ProductMillingReportList = [];
    },
    setProductionReport: (state, action) => {
      state.ProductionReportDetails = action.payload;
    },
    clearProductionReportList: (state) => {
      state.ProductionReportList = [];
    },
    setGhantiCleaningReport: (state, action) => {
      state.GhantiCleaningReportDetails = action.payload;
    },
    clearGhantiCleaningReportList: (state) => {
      state.GhantiCleaningReportList = [];
    },
    setMetalCleaningReport: (state, action) => {
      state.MetalCleaningReportDetails = action.payload;
    },
    clearMetalCleaningReportList: (state) => {
      state.MetalCleaningReportList = [];
    },
    setProductCleaningReport: (state, action) => {
      state.ProductCleaningReportDetails = action.payload;
    },
    clearProductCleaningReportList: (state) => {
      state.ProductCleaningReportList = [];
    },
    setRoastingReport: (state, action) => {
      state.RoastingReportDetails = action.payload;
    },
    clearRoastingReportList: (state) => {
      state.RoastingReportList = [];
    },
    setInwardPackingStockReport: (state, action) => {
      state.InwardPackingMaterialStocksDetails = action.payload;
    },
    clearInwardPackingStockList: (state) => {
      state.InwardPackingMaterialStockList = [];
    },
    setOutwardPackingStockReport: (state, action) => {
      state.OutwardPackingMaterialStocksDetails = action.payload;
    },
    clearOutwardPackingStockList: (state) => {
      state.OutwardPackingMaterialStockList = [];
    },
    setInwardRegisterReport: (state, action) => {
      state.InwardRegisterDetails = action.payload;
    },
    clearInwardRegisterList: (state) => {
      state.InwardRegisterList = [];
    },
    clearStockReportList: (state) => {
      state.StockReportList = [];
    },
    setPackingRegisterReport: (state, action) => {
      state.PackingRegisterReportDetails = action.payload;
    },
    clearPackingRegisterReportList: (state) => {
      state.PackingRegisterReportList = [];
    },
    clearPackingMaterialStockReportList: (state) => {
      state.PackingMaterialStockReportList = [];
    },
    setVisitorReport: (state, action) => {
      state.VisitorReportDetails = action.payload;
    },
    clearVisitorReportList: (state) => {
      state.VisitorReportList = [];
    },
    setOfficeStaffOutReport: (state, action) => {
      state.OfficeStaffOutReportDetails = action.payload;
    },
    clearOfficeStaffOutReportList: (state) => {
      state.OfficeStaffOutReportList = [];
    },
    setPurchaseDueDateReport: (state, action) => {
      state.PurchaseDueDateReportDetails = action.payload;
    },
    clearPurchaseDueDateReportList: (state) => {
      state.PurchaseDueDateReportList = [];
    },
    clearDistributorCurrentStockDetails: (state) => {
      state.DistributorCurrentStockDetails =
        initialState.DistributorCurrentStockDetails;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductMillingReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getProductMillingReport.fulfilled,
      (state, action: PayloadAction<IProductMillingReportList[]>) => {
        state.loading = false;
        state.ProductMillingReportList = action.payload || [];
      }
    );
    builder.addCase(getProductMillingReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ProductMillingReportList = [];
    });
    builder.addCase(getProductionReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getProductionReport.fulfilled,
      (state, action: PayloadAction<IProductionReportList[]>) => {
        state.loading = false;
        state.ProductionReportList = action.payload || [];
      }
    );
    builder.addCase(getProductionReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ProductionReportList = [];
    });
    builder.addCase(getGhantiCleaningReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getGhantiCleaningReport.fulfilled,
      (state, action: PayloadAction<IGhantiCleaningReportList[]>) => {
        state.loading = false;
        state.GhantiCleaningReportList = action.payload || [];
      }
    );
    builder.addCase(getGhantiCleaningReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.GhantiCleaningReportList = [];
    });
    builder.addCase(getMetalCleaningReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getMetalCleaningReport.fulfilled,
      (state, action: PayloadAction<IMetalCleaningReportList[]>) => {
        state.loading = false;
        state.MetalCleaningReportList = action.payload || [];
      }
    );
    builder.addCase(getMetalCleaningReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.MetalCleaningReportList = [];
    });
    builder.addCase(getProductCleaningReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getProductCleaningReport.fulfilled,
      (state, action: PayloadAction<IProductCleaningReportList[]>) => {
        state.loading = false;
        state.ProductCleaningReportList = action.payload || [];
      }
    );
    builder.addCase(getProductCleaningReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ProductCleaningReportList = [];
    });
    builder.addCase(getRoastingReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getRoastingReport.fulfilled,
      (state, action: PayloadAction<IRoastingReportList[]>) => {
        state.loading = false;
        state.RoastingReportList = action.payload || [];
      }
    );
    builder.addCase(getRoastingReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RoastingReportList = [];
    });
    builder.addCase(getInwardPackingMaterialStockReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getInwardPackingMaterialStockReport.fulfilled,
      (
        state,
        action: PayloadAction<IInwardPackingMaterialStockReportList[]>
      ) => {
        state.loading = false;
        state.InwardPackingMaterialStockList = action.payload || [];
      }
    );
    builder.addCase(
      getInwardPackingMaterialStockReport.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.InwardPackingMaterialStockList = [];
      }
    );

    builder.addCase(getOutwardPackingMaterialStockReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getOutwardPackingMaterialStockReport.fulfilled,
      (
        state,
        action: PayloadAction<IOutwardPackingMaterialStockReportList[]>
      ) => {
        state.loading = false;
        state.OutwardPackingMaterialStockList = action.payload || [];
      }
    );
    builder.addCase(
      getOutwardPackingMaterialStockReport.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.OutwardPackingMaterialStockList = [];
      }
    );
    builder.addCase(getInwardRegisterReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getInwardRegisterReport.fulfilled,
      (state, action: PayloadAction<IInwardRegisterList[]>) => {
        state.loading = false;
        state.InwardRegisterList = action.payload || [];
      }
    );
    builder.addCase(getInwardRegisterReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.InwardRegisterList = [];
    });

    builder.addCase(getItemStockReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getItemStockReport.fulfilled,
      (state, action: PayloadAction<IStockReportList[]>) => {
        state.loading = false;
        state.StockReportList = action.payload || [];
      }
    );
    builder.addCase(getItemStockReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.StockReportList = [];
    });

    builder.addCase(getPackingRegisterReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getPackingRegisterReport.fulfilled,
      (state, action: PayloadAction<IPackingRegisterReportList[]>) => {
        state.loading = false;
        state.PackingRegisterReportList = action.payload || [];
      }
    );
    builder.addCase(getPackingRegisterReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PackingRegisterReportList = [];
    });

    builder.addCase(getPackingMaterialStockReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getPackingMaterialStockReport.fulfilled,
      (state, action: PayloadAction<IPackingMaterialStockReportList[]>) => {
        state.loading = false;
        state.PackingMaterialStockReportList = action.payload || [];
      }
    );
    builder.addCase(getPackingMaterialStockReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PackingMaterialStockReportList = [];
    });

    builder.addCase(getVisitorReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getVisitorReport.fulfilled,
      (state, action: PayloadAction<IVisitorReportList[]>) => {
        state.loading = false;
        state.VisitorReportList = action.payload || [];
      }
    );
    builder.addCase(getVisitorReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.VisitorReportList = [];
    });

    builder.addCase(getOfficeStaffOutReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getOfficeStaffOutReport.fulfilled,
      (state, action: PayloadAction<IOfficeStaffOutReportList[]>) => {
        state.loading = false;
        state.OfficeStaffOutReportList = action.payload || [];
      }
    );
    builder.addCase(getOfficeStaffOutReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.OfficeStaffOutReportList = [];
    });

    builder.addCase(getPurchaseDueDateInvoiceReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getPurchaseDueDateInvoiceReport.fulfilled,
      (state, action: PayloadAction<IPurchaseDueDateReportList[]>) => {
        state.loading = false;
        state.PurchaseDueDateReportList = action.payload || [];
      }
    );
    builder.addCase(
      getPurchaseDueDateInvoiceReport.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.PurchaseDueDateReportList = [];
      }
    );

    builder.addCase(getDistributorCurrentStockReportByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getDistributorCurrentStockReportByID.fulfilled,
      (state, action: PayloadAction<IDistributorCurrentStockDetails>) => {
        state.loading = false;
        state.DistributorCurrentStockDetails = action.payload;
      }
    );
    builder.addCase(
      getDistributorCurrentStockReportByID.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.DistributorCurrentStockDetails =
          initialState.DistributorCurrentStockDetails;
      }
    );
  },
});

export const {
  setProductMillingReport,
  clearProductMillingReportList,
  setProductionReport,
  clearProductionReportList,
  setGhantiCleaningReport,
  clearGhantiCleaningReportList,
  setMetalCleaningReport,
  clearMetalCleaningReportList,
  setProductCleaningReport,
  clearProductCleaningReportList,
  setRoastingReport,
  clearRoastingReportList,
  setInwardPackingStockReport,
  clearInwardPackingStockList,
  setOutwardPackingStockReport,
  clearOutwardPackingStockList,
  setInwardRegisterReport,
  clearInwardRegisterList,
  clearStockReportList,
  setPackingRegisterReport,
  clearPackingRegisterReportList,
  clearPackingMaterialStockReportList,
  setVisitorReport,
  clearVisitorReportList,
  setOfficeStaffOutReport,
  clearOfficeStaffOutReportList,
  setPurchaseDueDateReport,
  clearPurchaseDueDateReportList,
  clearDistributorCurrentStockDetails,
} = reportSlice.actions;
export default reportSlice.reducer;
