import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { IProduction, IProductionInitialState } from "./productionModel";
import {
  createProduction,
  deleteProduction,
  getAllBOMDetails,
  getAllProductions,
  getProductionByID,
  updateProduction,
} from "./services/production.services";

const initialState: IProductionInitialState = {
  loading: false,
  error: "",
  ProductionDeleteIndex: -1,
  ProductionID: null,
  ProductionList: [],
  ProductionDetail: {
    start_date: moment().toDate(),
    end_date: moment().toDate(),
    lot_no: null,
    item_id: null,
    no_of_workers: null,
    start_time: "",
    end_time: "",
    roasting_duration: "",
    old_material_lot_no: null,
    total_quantity: null,
    quantity: 0,
    total_qty: null,
    received_qty: 0,
    loss_qty: null,
    supervisor_by: null,
    financial_year: null,
    remarks: "",
    ProductionItemDetails: [],
  },
  BOMDetail: {},
  FilterProduction: {
    product_name: "",
    start_date: "",
  },
};

const productionSlice = createSlice({
  name: "production",
  initialState,
  reducers: {
    clearProductionDetails: (state) => {
      state.ProductionDetail = initialState.ProductionDetail;
    },
    setProductionID: (state, action) => {
      state.ProductionID = action.payload;
    },
    clearProductionID: (state) => {
      state.ProductionID = initialState.ProductionID;
    },
    setProductionDeleteIndex: (state, action) => {
      state.ProductionDeleteIndex = action.payload;
    },
    setFilterProduction: (state, action) => {
      state.FilterProduction = action.payload;
    },
    clearFilterProduction: (state) => {
      state.FilterProduction = initialState.FilterProduction;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllProductions.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllProductions.fulfilled,
      (state, action: PayloadAction<IProduction[]>) => {
        state.loading = false;
        state.ProductionList = action.payload || [];
      }
    );
    builder.addCase(getAllProductions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ProductionList = [];
    });
    builder.addCase(createProduction.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createProduction.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(createProduction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateProduction.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateProduction.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProduction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteProduction.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteProduction.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteProduction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getProductionByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getProductionByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.ProductionDetail = action.payload;
    });
    builder.addCase(getProductionByID.rejected, (state, action) => {
      state.loading = false;
      state.ProductionDetail = initialState.ProductionDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllBOMDetails.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(getAllBOMDetails.fulfilled, (state, action: any) => {
      state.loading = false;
      state.BOMDetail = action.payload;
    });
    builder.addCase(getAllBOMDetails.rejected, (state, action) => {
      state.loading = false;
      state.BOMDetail = initialState.BOMDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearProductionDetails,
  clearProductionID,
  setProductionID,
  setProductionDeleteIndex,
  setFilterProduction,
  clearFilterProduction,
} = productionSlice.actions;
export default productionSlice.reducer;
