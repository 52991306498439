import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMenuInitialState, IMenus, IMenuSubMenuList } from "./menuModel";
import {
  ActiveInactiveMenu,
  createMenu,
  deleteMenu,
  getAllActiveMenus,
  getAllActiveMenuSubMenuList,
  getAllMenus,
  getMenuByID,
  updateMenu,
} from "./services/menu.services";

const initialState: IMenuInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  MenuID: null,
  MenuList: [],
  MenuSubMenuList: [],
  MenuDetail: {
    menu_name: "",
    menu_key: "",
    order_no: null,
    is_menu: false,
    under_menu_id: null,
    isactive: 1,
  },
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    clearMenuDetails: (state) => {
      state.MenuDetail = initialState.MenuDetail;
    },
    setMenuID: (state, action) => {
      state.MenuID = action.payload;
    },
    clearMenuID: (state) => {
      state.MenuID = initialState.MenuID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMenus.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllMenus.fulfilled,
      (state, action: PayloadAction<IMenus[]>) => {
        state.loading = false;
        state.MenuList = action.payload || [];
      }
    );
    builder.addCase(getAllMenus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.MenuList = [];
    });
    builder.addCase(createMenu.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createMenu.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createMenu.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateMenu.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateMenu.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateMenu.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteMenu.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteMenu.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteMenu.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getMenuByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getMenuByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.MenuDetail = action.payload;
    });
    builder.addCase(getMenuByID.rejected, (state, action) => {
      state.formLoading = false;
      state.MenuDetail = initialState.MenuDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveMenus.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveMenus.fulfilled,
      (state, action: PayloadAction<IMenus[]>) => {
        state.loading = false;
        state.MenuList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveMenuSubMenuList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.MenuSubMenuList = [];
    });
    builder.addCase(ActiveInactiveMenu.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveMenu.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveMenu.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveMenus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.MenuList = [];
    });
    builder.addCase(getAllActiveMenuSubMenuList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveMenuSubMenuList.fulfilled,
      (state, action: PayloadAction<IMenuSubMenuList[]>) => {
        state.loading = false;
        state.MenuSubMenuList = action.payload || [];
      }
    );
  },
});

export const { clearMenuDetails, clearMenuID, setMenuID } = menuSlice.actions;
export default menuSlice.reducer;
