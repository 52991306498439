import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { FiEdit } from "react-icons/fi";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { MdDelete } from "react-icons/md";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import {
  deleteProductCleaning,
  getAllProductCleanings,
} from "./services/productCleaning.services";
import {
  clearFilterProductCleaning,
  clearProductCleaningID,
  setProductCleaningID,
} from "./productCleaningSlice";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import {
  checkAcessRights,
  formatIndianNumberForQty,
} from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  FINANCIAL_YEAR,
  INSERT_ACCESS,
} from "../../_contstants/common";
// import _debounce from "lodash/debounce";

const ProductCleaning: React.FC = () => {
  const navigate = useNavigate();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  const handleCreate = () => {
    navigate("/productcleaning/create");
  };

  return (
    <>
      <DeleteProductCleaningDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Product Cleaning List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              {"Add New"}
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <ProductCleaningGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const ProductCleaningGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const isEditBtnRef = useRef<any>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const ProductCleaningList = useAppSelector(
    (state) => state.productCleaning.ProductCleaningList
  );
  const loading = useAppSelector((state) => state.productCleaning.loading);
  const FilterProductCleaning = useAppSelector(
    (state) => state.productCleaning.FilterProductCleaning
  );
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  useEffect(() => {
    return () => {
      dispatch(setCurrentPage(0));
      if (!isEditBtnRef.current) {
        dispatch(clearFilterProductCleaning());
      }
    };
  }, []);

  useEffect(() => {
    const payload = {
      ...FilterProductCleaning,
      financial_year: FINANCIAL_YEAR,
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    dispatch(getAllProductCleanings(payload));
  }, [currentPage, pageLimit]);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (product_cleaning_guid: any) => {
      navigate("/productcleaning/edit", {
        state: { product_cleaning_guid: product_cleaning_guid },
      });
    };
    const handleOpenDeleteDialog = (product_cleaning_guid: any) => {
      dispatch(openDialog("deleteProductCleaning"));
      dispatch(setProductCleaningID(product_cleaning_guid));
    };

    return (
      <td
        className={`${props?.className}`}
        style={{
          ...props.style,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            ref={isEditBtnRef}
            onClick={() => {
              isEditBtnRef.current = true;
              handleEdit(props.dataItem?.product_cleaning_guid);
            }}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() =>
              handleOpenDeleteDialog(props.dataItem?.product_cleaning_guid)
            }
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 10 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <KendoGrid
        style={{ maxHeight: "calc(100vh - 152px)" }}
        filterable={true}
        filter={filter}
        data={
          filter ? filterBy(ProductCleaningList, filter) : ProductCleaningList
        }
        onFilterChange={handleFilterChange}
        total={ProductCleaningList[0]?.itemcount || ProductCleaningList.length}
        skip={currentPage * pageLimit}
        take={pageLimit}
        pageable={{
          buttonCount: 5,
          pageSizes: [10, 50, 100, 300, 500],
          pageSizeValue: pageLimit,
          type: "input",
        }}
        onPageChange={pageChange}
        cells={{
          headerCell: HeaderCustomCell,
          data: MyDataCustomCell,
        }}
      >
        <Column
          field="cleaning_no"
          title="Cleaning No"
          width={150}
          cell={(props: any) => (
            <td>
              <div
                className={isEditAccess ? "hoverableName" : "normalName"}
                onClick={() =>
                  isEditAccess &&
                  navigate("/productcleaning/edit", {
                    state: {
                      product_cleaning_guid:
                        props.dataItem?.product_cleaning_guid,
                    },
                  })
                }
              >
                {props.dataItem?.cleaning_no}
              </div>
            </td>
          )}
        />
        <Column field="cleaning_date" title="Cleaning Date" width={200} />
        {/* <Column
          field="product_name"
          title="Product Name"
          width={250}
          filterCell={CommonFilterCell}
        />
        <Column field="grn_no" title="GRN No" width={200} /> */}
        <Column
          field="no_of_workers"
          title="No Of Workers"
          width={200}
          cell={(props: any) => (
            <td className="text-end">{props.dataItem?.no_of_workers}</td>
          )}
        />
        <Column field="machine_name" title="Machine No" width={250} />
        <Column
          field="start_time"
          title="Time"
          width={200}
          cell={(props: any) => (
            <td>
              {props.dataItem?.start_time}
              {props.dataItem?.start_time && props.dataItem?.end_time && " - "}
              {props.dataItem?.end_time}
            </td>
          )}
        />
        <Column
          field="total_qty"
          title="Total Qty (KG)"
          width={200}
          cell={(props: any) => (
            <td style={{ textAlign: "end" }}>
              {formatIndianNumberForQty(+props.dataItem.total_qty)}
            </td>
          )}
        />
        <Column
          field="received_qty"
          title="Received Qty (KG)"
          width={200}
          cell={(props: any) => (
            <td style={{ textAlign: "end" }}>
              {formatIndianNumberForQty(+props.dataItem.received_qty)}
            </td>
          )}
        />
        <Column
          field="rejection_qty"
          title="Cleaning Loss Qty (KG)"
          width={200}
          cell={(props: any) => (
            <td style={{ textAlign: "end" }}>
              {formatIndianNumberForQty(+props.dataItem.rejection_qty)}
            </td>
          )}
        />
        <Column
          field="process_name"
          title="Material issued to Department"
          width={250}
        />
        <Column
          field="cleaning_checker_name"
          title="Cleaning Checker"
          width={300}
        />
        {(isEditAccess || isDeleteAccess) && (
          <Column
            field="id"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            locked={true}
            filterable={false}
          />
        )}
      </KendoGrid>
    </>
  );
};

const DeleteProductCleaningDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const ProductCleaningID = useAppSelector(
    (state) => state.productCleaning.ProductCleaningID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterProductCleaning = useAppSelector(
    (state) => state.productCleaning.FilterProductCleaning
  );

  const handleDeleteAction = async (ID: any) => {
    if (ID) {
      const response = await dispatch(deleteProductCleaning(ID));
      dispatch(setCurrentPage(0));
      const payload = {
        ...FilterProductCleaning,
        financial_year: FINANCIAL_YEAR,
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      if (response.payload.Data.Status === 200) {
        dispatch(getAllProductCleanings(payload));
        dispatch(closeDialog());
        dispatch(clearProductCleaningID());
      } else {
        dispatch(getAllProductCleanings(payload));
        dispatch(closeDialog());
        dispatch(clearProductCleaningID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteProductCleaning" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Product Cleaning"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Product Cleaning?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(ProductCleaningID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

// const CommonFilterCell = (props: any) => {
//   const dispatch = useAppDispatch();
//   const currentPage = useAppSelector((state) => state.pagination.currentPage);
//   const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
//   const FilterProductCleaning: any = useAppSelector(
//     (state) => state.productCleaning.FilterProductCleaning
//   );
//   const isFilterRef = useRef(true);
//   const [inputValue, setInputValue] = useState(props.value || "");
//   const [inputField, setInputField] = useState(props.field || "");

//   const onChange = (event: any) => {
//     setInputValue(event.target.value);
//     setInputField(event.target.name);
//   };

//   const delayedApiCall = React.useCallback(
//     _debounce((updatedFilters: any) => {
//       dispatch(setCurrentPage(0));
//       const payload = {
//         ...updatedFilters,
//         financial_year: FINANCIAL_YEAR,
//         limit: +pageLimit,
//         skip: 0 * +pageLimit,
//       };

//       dispatch(getAllProductCleanings(payload));
//     }, 300),
//     [dispatch]
//   );

//   useEffect(() => {
//     if (!isFilterRef.current) {
//       const updatedFilters = {
//         ...FilterProductCleaning,
//         [inputField]: inputValue,
//       };
//       dispatch(setFilterProductCleaning(updatedFilters));
//       delayedApiCall(updatedFilters);
//     } else {
//       isFilterRef.current = false;
//     }
//     return () => {
//       delayedApiCall.cancel();
//     };
//   }, [inputValue, delayedApiCall]);

//   const onClearButtonClick = (event: any) => {
//     event.preventDefault();
//     props.onChange({
//       value: null,
//       operator: "",
//       syntheticEvent: event,
//     });

//     const updatedFilters = { ...FilterProductCleaning, [props.field]: "" };
//     dispatch(setFilterProductCleaning(updatedFilters));

//     const payload = {
//       ...updatedFilters,
//       financial_year: FINANCIAL_YEAR,
//       limit: +pageLimit,
//       skip: +currentPage * +pageLimit,
//     };

//     dispatch(getAllProductCleanings(payload));
//   };

//   return (
//     <div className="d-flex">
//       <TextBox
//         value={FilterProductCleaning[props.field] || ""}
//         name={props.field || ""}
//         onChange={onChange}
//       />
//       <Button
//         svgIcon={filterClearIcon}
//         title="Clear"
//         disabled={!FilterProductCleaning[props.field]}
//         onClick={onClearButtonClick}
//         type="button"
//         style={{
//           marginLeft: "8px",
//         }}
//       ></Button>
//     </div>
//   );
// };

export default ProductCleaning;
