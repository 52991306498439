import { Button } from "@progress/kendo-react-buttons";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { useEffect } from "react";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import {
  Field,
  FieldArrayRenderProps,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { findAllActiveProcess } from "../process/services/process.services";
import { setBomDetailsDeleteIndex } from "./bomSlice";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";

interface ITotalCahngeWatcher {
  formRenderProps: FormRenderProps;
  index: number;
}

const TotalChangeWatcher: React.FC<ITotalCahngeWatcher> = ({
  formRenderProps,
  index,
}) => {
  const BomDetails = formRenderProps.valueGetter("BomDetails");
  const quantity = formRenderProps.valueGetter(`BomDetails.${index}.item_qty`);
  const rate = formRenderProps.valueGetter(`BomDetails.${index}.rate`);

  let totalamount = 0;
  let Total = +quantity * +rate;

  useEffect(() => {
    if (BomDetails && BomDetails.length > 0) {
      BomDetails.map((bomdetail: any) => {
        totalamount += +bomdetail?.total || 0;
      });
    }

    formRenderProps.onChange(`BomDetails.${index}.total`, {
      value: Total.toFixed(2),
    });
    formRenderProps.onChange("total_amount", {
      value: totalamount.toFixed(2),
    });
  }, [
    BomDetails.map((bomdetail: any) => bomdetail?.total).join("-"),
    quantity,
    rate,
  ]);
  return null;
};

const BomDetailsArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const BomDeleteindex = useAppSelector((state) => state.bom.DeleteIndex);

  useEffect(() => {
    dispatch(findAllActiveProcess());
  }, []);

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setBomDetailsDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        bom_id: null,
        item_id: null,
        item_qty: null,
        rate: null,
        total: null,
      },
    });
  }, [fieldArrayRenderProps]);

  const handleOpenDeleteDialog = (index: any) => {
    dispatch(openDialog("bomDeleteDialog"));
    dispatch(setBomDetailsDeleteIndex(index));
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {/* {!fieldArrayRenderProps.value ? pushElementInarray() : null} */}
      <GridLayoutItem className="bg-dark text-white fw-600">
        <GridLayout
          style={{ marginRight: 15, padding: 10 }}
          gap={{ rows: 10, cols: 10 }}
          cols={[
            { width: "4%" },
            { width: "22.6%" },
            { width: "22.6%" },
            { width: "22.6%" },
            { width: "22.6%" },
            { width: "3%" },
          ]}
        >
          <GridLayoutItem>No</GridLayoutItem>
          <GridLayoutItem>Item Name</GridLayoutItem>
          <GridLayoutItem>Quantity</GridLayoutItem>
          <GridLayoutItem>Rate</GridLayoutItem>
          <GridLayoutItem>Total</GridLayoutItem>
        </GridLayout>
      </GridLayoutItem>
      {
        <AppDialog>
          <>
            <Typography.h5>{"Delete BOM Details"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(BomDeleteindex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      }
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((process_data: any, index: number) => (
          <GridLayoutItem key={index} style={{ padding: 15 }}>
            <GridLayout
              style={{ marginRight: 10 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "4%" },
                { width: "22.6%" },
                { width: "22.6%" },
                { width: "22.6%" },
                { width: "22.6%" },
                { width: "3%" },
              ]}
            >
              <GridLayoutItem style={{ position: "relative" }}>
                <span
                  style={{
                    position: "absolute",
                    top: 5,
                    left: 0,
                    fontWeight: 700,
                  }}
                >
                  {index + 1}
                </span>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperStyle={{ margin: 0 }}
                  name={`BomDetails.${index}.item_id`}
                  type="number"
                  placeholder="Item Name"
                  defaultValue={0}
                  component={FormIncrementalSearch}
                  validator={requiredValidator}
                  fetchIncrementalData={(search: any) =>
                    fieldArrayRenderProps?.handleItemSearchChange(
                      search,
                      `BomDetails.${index}.item_options`,
                      fieldArrayRenderProps.formRenderProps
                    )
                  }
                  options={
                    fieldArrayRenderProps.value[index]?.item_options?.map(
                      (item: any) => {
                        return {
                          value: item.id,
                          label: item.product_name,
                        };
                      }
                    ) || []
                  }
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="right-alighned-field"
                  wrapperStyle={{ margin: 0 }}
                  name={`BomDetails.${index}.item_qty`}
                  placeholder="0"
                  minNumber="0"
                  format="n3"
                  component={FormNumericTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="right-alighned-field"
                  wrapperStyle={{ margin: 0 }}
                  name={`BomDetails.${index}.rate`}
                  placeholder="0"
                  minNumber="0"
                  format="n2"
                  component={FormNumericTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="right-alighned-field"
                  wrapperStyle={{ margin: 0 }}
                  name={`BomDetails.${index}.total`}
                  disabled
                  component={FormTextField}
                  type="number"
                  placeholder="0"
                  defaultValue={0}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ position: "relative" }}>
                <IconButton
                  onClick={() => handleOpenDeleteDialog(index)}
                  disabled={fieldArrayRenderProps.value.length === 1}
                  themeColor={"error"}
                  size={"small"}
                  fillMode={"solid"}
                  type="button"
                  style={{
                    position: "absolute",
                    height: 32,
                    width: 32,
                    top: 1,
                    right: 0,
                  }}
                >
                  <MdDelete
                    className="absolute-position"
                    style={{ fontSize: "16px" }}
                  />
                </IconButton>
              </GridLayoutItem>
              <TotalChangeWatcher
                formRenderProps={fieldArrayRenderProps.formRenderProps}
                index={index}
              />
            </GridLayout>
          </GridLayoutItem>
        ))}
      <GridLayoutItem
        style={{ marginTop: 10, borderTop: "1px solid lightgray" }}
      >
        <GridLayout
          style={{ marginRight: 25 }}
          gap={{ rows: 10, cols: 5 }}
          cols={[
            { width: "3%" },
            { width: "22.6%" },
            { width: "22.6%" },
            { width: "22.6%" },
            { width: "16%" },
            { width: "12%" },
            // { width: "3%" },
          ]}
        >
          <GridLayoutItem
            colSpan={2}
            className="d-flex align-items-end"
            style={{ marginBottom: 1 }}
          >
            <Button
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={pushElementInarray}
              type="button"
            >
              Add Item
            </Button>
          </GridLayoutItem>
          <GridLayoutItem></GridLayoutItem>
          <GridLayoutItem></GridLayoutItem>
          <GridLayoutItem
            className="d-flex justify-content-end align-items-center fw-600"
            style={{ marginTop: 10 }}
          >
            Total Amount
          </GridLayoutItem>
          <GridLayoutItem style={{ marginTop: 10 }}>
            <Field
              wrapperClassName="right-alighned-field"
              wrapperStyle={{ margin: 0 }}
              name="total_amount"
              type="number"
              disabled
              component={FormTextField}
            />
          </GridLayoutItem>
        </GridLayout>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default BomDetailsArray;
