import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ShadowCard from "../../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
// import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { clearSalaryDetails, decreaseActiveStep } from "../employeeSlice";
import FormTextField from "../../../components/formFields/FormTextField";
import FormDatePicker from "../../../components/formFields/FormDateField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { ISalaryDate } from "../employeeModel";
import {
  getAllSalaryDateByEmployeeID,
  getSalaryStructureByDateAndEmpID,
  updateEmployeeSalaryStructure,
} from "../services/employee.services";
import moment from "moment";
import { LoadingPanel } from "../../../components/layout/Loading";
import { ErrorToast } from "../../../components/toast/Toasts";
import {
  convertNumToIndianCurrency,
  PercentageValue,
} from "../../../_helper/helper";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";

interface SalaryChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const SalaryChangeWatcher: React.FC<SalaryChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const Basic = +formRenderProps.valueGetter("basic") || 0;
  const DAPercent = +formRenderProps.valueGetter("da_per") || 0;
  const HRAPercent = +formRenderProps.valueGetter("hra_per") || 0;
  const PFPercent = +formRenderProps.valueGetter("pf_per") || 0;
  const ESIPercent = +formRenderProps.valueGetter("esi_per") || 0;

  const DA = +formRenderProps.valueGetter("da") || 0;
  const HRA = +formRenderProps.valueGetter("hra") || 0;
  const Wash = +formRenderProps.valueGetter("wash") || 0;
  const Bonus = +formRenderProps.valueGetter("adv_bonus") || 0;
  const Leave = +formRenderProps.valueGetter("adv_leave") || 0;
  const Food = +formRenderProps.valueGetter("food") || 0;
  const TotalIncome = +formRenderProps.valueGetter("total_income") || 0;
  const TotalDeduction = +formRenderProps.valueGetter("total_deduction") || 0;

  const PF = +formRenderProps.valueGetter("pf") || 0;
  const ESI = +formRenderProps.valueGetter("esi") || 0;
  const PT = +formRenderProps.valueGetter("pt") || 0;
  const IT = +formRenderProps.valueGetter("it") || 0;
  const LWF = +formRenderProps.valueGetter("lwf") || 0;
  const Advance = +formRenderProps.valueGetter("advance") || 0;
  const Loan = +formRenderProps.valueGetter("loan") || 0;
  const Other = +formRenderProps.valueGetter("other") || 0;
  useEffect(() => {
    formRenderProps.onChange("da", {
      value: (Basic * (DAPercent / 100))?.toFixed(2),
    });
    formRenderProps.onChange("hra", {
      value: ((Basic + DA) * (HRAPercent / 100))?.toFixed(2),
    });
    formRenderProps.onChange("pf", {
      value: ((Basic + DA) * (PFPercent / 100))?.toFixed(2),
    });
    formRenderProps.onChange("esi", {
      value: ((Basic + DA) * (ESIPercent / 100))?.toFixed(2),
    });
    formRenderProps.onChange("total_income", {
      value: (Basic + DA + HRA + Wash + Bonus + Leave + Food)?.toFixed(2),
    });
  }, [
    Basic,
    DAPercent,
    HRAPercent,
    DA,
    PFPercent,
    ESIPercent,
    HRA,
    Wash,
    Bonus,
    Leave,
    Food,
  ]);

  useEffect(() => {
    formRenderProps.onChange("total_deduction", {
      value: (PF + ESI + PT + IT + LWF + Advance + Loan + Other)?.toFixed(2),
    });
  }, [PF, ESI, PT, IT, LWF, Advance, Loan, Other]);

  useEffect(() => {
    const PTCal = TotalIncome > 12000 ? 200 : 0;
    formRenderProps.onChange("pt", {
      value: PTCal,
    });
  }, [TotalIncome]);

  useEffect(() => {
    formRenderProps.onChange("payable_amount", {
      value: +(TotalIncome - TotalDeduction)?.toFixed(2),
    });
  }, [TotalIncome, TotalDeduction]);

  useEffect(() => {
    formRenderProps.onChange("da_per", { value: PercentageValue(DAPercent) });
    formRenderProps.onChange("hra_per", { value: PercentageValue(HRAPercent) });
    formRenderProps.onChange("pf_per", { value: PercentageValue(PFPercent) });
    formRenderProps.onChange("esi_per", { value: PercentageValue(ESIPercent) });
  }, [DAPercent, HRAPercent, PFPercent, ESIPercent]);

  return null;
};

const SalaryStructure = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const EmployeeGUID = location.state?.EmployeeGUID;
  const EmployeeID = location.state?.EmployeeID;
  const formloading = useAppSelector((state) => state.employee.formloading);
  const gridRef = useRef<any>(null);
  const [selectedDate, setSelectedDate] = useState<ISalaryDate>();
  const SalaryDetails = useAppSelector(
    (state) => state?.employee?.SalaryDetails
  );
  const SalaryDateList = useAppSelector(
    (state) => state?.employee?.SalaryDateList
  );
  const [formKey, setFormKey] = useState(1);
  const [editMode, setEditMode] = useState<Boolean>(false);
  useEffect(() => {
    dispatch(getAllSalaryDateByEmployeeID(EmployeeID));
  }, [EmployeeID]);

  useEffect(() => {
    if (SalaryDateList?.length > 0) {
      const payload = {
        employee_id: EmployeeID,
        apply_from_date: moment(
          SalaryDateList[0]?.apply_from_date,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
      };
      dispatch(getSalaryStructureByDateAndEmpID(payload));
      setSelectedDate(SalaryDateList[0]);
    } else {
      setSelectedDate(undefined);
    }
  }, [SalaryDateList]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [SalaryDetails]);

  const handleEditMode = () => {
    setEditMode((prev) => !prev);
    setFormKey(formKey + 1);
    dispatch(clearSalaryDetails());
    setSelectedDate(undefined);

    if (editMode) {
      const payload = {
        employee_id: EmployeeID,
        apply_from_date: moment(
          SalaryDateList[0]?.apply_from_date,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
      };
      dispatch(getSalaryStructureByDateAndEmpID(payload));
      setSelectedDate(SalaryDateList[0]);
    }
  };

  const handleSubmit = async (values: any) => {
    const formdata = {
      employee_id: EmployeeID,
      basic: +values?.basic,
      da_per: +values?.da_per,
      da: +values?.da,
      hra_per: +values?.hra_per,
      hra: +values?.hra,
      wash: +values?.wash,
      adv_bonus: +values?.adv_bonus,
      adv_leave: +values?.adv_leave,
      food: +values?.food,
      pf_per: +values?.pf_per,
      pf: +values?.pf,
      esi_per: +values?.esi_per,
      esi: +values?.esi,
      pt: +values?.pt,
      it: +values?.it,
      lwf: +values?.lwf,
      advance: +values?.advance,
      loan: +values?.loan,
      other: +values?.other,
      total_income: +values?.total_income,
      total_deduction: +values?.total_deduction,
      payable_amount: +values?.payable_amount,
      apply_from_date: values?.apply_from_date
        ? moment(values?.apply_from_date).format("YYYY-MM-DD")
        : "",
    };
    try {
      if (!values?.salary_guid) {
        if (!formdata?.apply_from_date) {
          ErrorToast("Date Field is required!");
          return;
        }
        const response = await dispatch(
          updateEmployeeSalaryStructure(formdata)
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          setFormKey(formKey + 1);
          dispatch(clearSalaryDetails());
          dispatch(getAllSalaryDateByEmployeeID(EmployeeID));
          setEditMode(false);
        }
      } else {
        const response = await dispatch(
          updateEmployeeSalaryStructure({
            ...formdata,
            salary_guid: values?.salary_guid,
            apply_from_date: formdata?.apply_from_date
              ? moment(formdata?.apply_from_date).format("YYYY-MM-DD")
              : null,
          })
        );
        if (response?.meta?.requestStatus === "fulfilled") {
          setFormKey(formKey + 1);
          dispatch(clearSalaryDetails());
          dispatch(getAllSalaryDateByEmployeeID(EmployeeID));
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleDateChange = (event: ComboBoxChangeEvent) => {
    setSelectedDate(event.target.value);
    if (!event.target.value) {
      dispatch(clearSalaryDetails());
    }
    const payload = {
      employee_id: EmployeeID,
      apply_from_date: moment(
        event.target.value?.apply_from_date,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD"),
    };
    if (event.target.value?.apply_from_date) {
      dispatch(getSalaryStructureByDateAndEmpID(payload));
    }
  };

  return (
    <>
      {formloading && <LoadingPanel gridRef={gridRef} />}
      <ShadowCard style={{ padding: 10 }}>
        <Form
          onSubmit={handleSubmit}
          key={formKey}
          initialValues={SalaryDetails}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <SalaryChangeWatcher formRenderProps={formRenderProps} />
              <div
                style={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                {editMode && (
                  <Field
                    name="apply_from_date"
                    label="Date"
                    format="dd/MM/yyyy"
                    wrapperStyle={{ width: 300, margin: "0", float: "left" }}
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                )}
                {!editMode && (
                  <ComboBox
                    data={SalaryDateList}
                    textField="apply_from_date"
                    dataItemKey="apply_from_date"
                    label="Date"
                    style={{ width: 300 }}
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                )}
                <Button
                  style={{ marginTop: 21, marginLeft: 10 }}
                  type="button"
                  fillMode={"solid"}
                  themeColor={"primary"}
                  onClick={handleEditMode}
                >
                  {editMode ? "Cancel" : "Add"}
                </Button>
              </div>
              <div className="container p-0" style={{ maxWidth: 850 }}>
                <GridLayout
                  style={{ marginRight: 30 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  {/* Salary Structure */}
                  <GridLayoutItem
                    colSpan={4}
                    style={{
                      border: "1px solid #ccc",
                      // backgroundColor:"#000",
                      padding: 10,
                      borderRadius: 5,
                    }}
                  >
                    <GridLayout
                      style={{ marginRight: 10 }}
                      gap={{ rows: 10, cols: 10 }}
                      cols={[{ width: "50%" }, { width: "50%" }]}
                    >
                      {/* Earnings */}
                      <GridLayoutItem>
                        <GridLayout>
                          <GridLayoutItem>
                            <Typography.h5
                              style={{ textDecoration: "underline" }}
                            >
                              {"Earnings"}
                            </Typography.h5>
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="basic"
                              label="Basic"
                              component={FormTextField}
                              type="number"
                              placeholder="Basic"
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem className="d-flex gap-3">
                            <Field
                              name="da_per"
                              label="% DA"
                              // max={2}
                              wrapperStyle={{ width: "40%" }}
                              type="number"
                              placeholder="i.e. 5"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                            <Field
                              name="da"
                              label="DA"
                              disabled
                              wrapperStyle={{ width: "100%" }}
                              format="n2"
                              type="number"
                              placeholder="DA"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem className="d-flex gap-3">
                            <Field
                              name="hra_per"
                              label="% HRA"
                              // max={2}
                              wrapperStyle={{ width: "40%" }}
                              type="number"
                              placeholder="i.e. 5"
                              component={FormTextField}
                              //validator={requiredValidator}
                            />
                            <Field
                              name="hra"
                              label="HRA"
                              disabled
                              wrapperStyle={{ width: "100%" }}
                              type="number"
                              format="n2"
                              placeholder="HRA"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="wash"
                              label="Wash"
                              type="number"
                              placeholder="Wash"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="adv_bonus"
                              label="Bonus"
                              type="number"
                              placeholder="Bonus"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="adv_leave"
                              label="Advance Leave"
                              type="number"
                              placeholder="Advance Leave"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="food"
                              label="Food"
                              type="number"
                              placeholder="Food"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                        </GridLayout>
                      </GridLayoutItem>

                      {/* Deduction */}
                      <GridLayoutItem
                        style={{
                          borderLeft: "2px solid #000",
                          paddingLeft: 10,
                        }}
                      >
                        <GridLayout>
                          <GridLayoutItem>
                            <Typography.h5
                              style={{ textDecoration: "underline" }}
                            >
                              {"Deduction"}
                            </Typography.h5>
                          </GridLayoutItem>
                          <GridLayoutItem className="d-flex gap-3">
                            <Field
                              name="pf_per"
                              label="% PF"
                              type="number"
                              wrapperStyle={{ width: "40%" }}
                              placeholder="i.e. 5"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                            <Field
                              name="pf"
                              label="PF"
                              wrapperStyle={{ width: "100%" }}
                              disabled
                              type="number"
                              placeholder="PF"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem className="d-flex gap-3">
                            <Field
                              name="esi_per"
                              label="% ESI"
                              wrapperStyle={{ width: "40%" }}
                              type="number"
                              placeholder="i.e. 5"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                            <Field
                              name="esi"
                              label="ESI"
                              wrapperStyle={{ width: "100%" }}
                              type="number"
                              disabled
                              placeholder="ESI"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="pt"
                              label="PT"
                              type="number"
                              placeholder="PT"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="it"
                              label="IT"
                              type="number"
                              placeholder="IT"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="lwf"
                              label="LWF"
                              type="number"
                              placeholder="LWF"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="advance"
                              label="Advance"
                              type="number"
                              placeholder="Advance"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="loan"
                              label="Loan"
                              type="number"
                              placeholder="Loan"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              name="other"
                              label="Other"
                              type="number"
                              placeholder="Other"
                              component={FormTextField}
                              //   validator={requiredValidator}
                            />
                          </GridLayoutItem>
                        </GridLayout>
                      </GridLayoutItem>
                    </GridLayout>
                  </GridLayoutItem>
                  {/* <GridLayoutItem></GridLayoutItem>

                  <GridLayoutItem></GridLayoutItem> */}
                  <GridLayoutItem
                    colSpan={2}
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      backgroundColor: "#e7e6e6",
                      padding: "5px 20px",
                      borderRadius: "5px",
                      marginTop: 20,
                    }}
                  >
                    <Typography.h5 style={{ margin: 0 }}>
                      {" "}
                      Total Earning :
                    </Typography.h5>
                    <Typography.h4 style={{ margin: 0 }}>
                      {convertNumToIndianCurrency(
                        formRenderProps.valueGetter("total_income")
                      )}
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={2}
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      backgroundColor: "#e7e6e6",
                      padding: "5px 20px",
                      borderRadius: "5px",
                      marginLeft: 10,
                      marginTop: 20,
                    }}
                  >
                    <Typography.h5 style={{ margin: 0 }}>
                      Total Deduction :
                    </Typography.h5>
                    <Typography.h4 style={{ margin: 0 }}>
                      {convertNumToIndianCurrency(
                        formRenderProps.valueGetter("total_deduction")
                      )}
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={2}></GridLayoutItem>
                  <GridLayoutItem
                    colSpan={2}
                    style={{
                      padding: "0 0 11px  9px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        backgroundColor: "rgb(231, 230, 230)",
                        padding: "5px 20px",
                        borderRadius: "5px",
                        marginTop: "13px",
                        // minHeight: "68px",
                      }}
                    >
                      <Typography.h5 style={{ color: "green", margin: "0" }}>
                        Total Salary :
                      </Typography.h5>
                      <Typography.h4 style={{ color: "green", margin: "0" }}>
                        {convertNumToIndianCurrency(
                          formRenderProps.valueGetter("payable_amount")
                        )}
                      </Typography.h4>
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{ textAlign: "end", marginTop: 20 }}
                    colSpan={4}
                  >
                    <Button
                      type="button"
                      fillMode="outline"
                      themeColor="primary"
                      style={{ marginRight: 10 }}
                      onClick={() => dispatch(decreaseActiveStep())}
                    >
                      Previous
                    </Button>

                    <ButtonWithLoading
                      label={SalaryDetails.salary_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || formloading}
                      loading={formloading}
                    />
                    <Button
                      fillMode="solid"
                      themeColor="primary"
                      style={{ marginLeft: 10 }}
                      onClick={() => navigate("/employee")}
                    >
                      Go to Employee List
                    </Button>
                  </GridLayoutItem>
                </GridLayout>
              </div>
              {/* <DayChangeWatcher formRenderProps={formRenderProps} /> */}
            </FormElement>
          )}
        />
      </ShadowCard>
    </>
  );
};

export default SalaryStructure;
