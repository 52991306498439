import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import {
  createSalesOrder,
  deleteSalesOrder,
  getAllLimitedSalesOrders,
  getAllSalesOrders,
  getSalesOrderByID,
  getSalesOrderChildList,
  getSalesOrderPrint,
  updateSalesOrder,
} from "./services/salesOrder.services";
import { ISalesOrder, ISalesOrderInitialState } from "./salesOrderModel";

const initialState: ISalesOrderInitialState = {
  loading: false,
  error: "",
  stateForGST: null,
  SODeleteIndex: -1,
  SalesOrderID: null,
  SalesOrderList: [],
  SalesOrderDetail: {
    sales_date: moment().toDate(),
    dealer_id: null,
    financial_year: null,
    total_amount: null,
    total_gst: null,
    gst: null,
    cgst: null,
    sgst: null,
    igst: null,
    sales_order_items: [],
  },
  SalesOrderPrintDetail: {},
  SalesOrderChildList: [],
  FilterSalesOrder: {
    dealer_name: "",
  },
};

const salesOrderSlice = createSlice({
  name: "salesOrder",
  initialState,
  reducers: {
    clearSalesOrderDetails: (state) => {
      state.SalesOrderDetail = initialState.SalesOrderDetail;
    },
    setSalesOrderID: (state, action) => {
      state.SalesOrderID = action.payload;
    },
    clearSalesOrderID: (state) => {
      state.SalesOrderID = initialState.SalesOrderID;
    },
    setSODeleteIndex: (state, action) => {
      state.SODeleteIndex = action.payload;
    },
    setStateForGST: (state, action) => {
      state.stateForGST = action.payload;
    },
    clearStateForGST: (state) => {
      state.stateForGST = initialState.stateForGST;
    },
    clearSalesOrderChildList: (state) => {
      state.SalesOrderChildList = initialState.SalesOrderChildList;
    },
    setFilterSalesOrder: (state, action) => {
      state.FilterSalesOrder = action.payload;
    },
    clearFilterSalesOrder: (state) => {
      state.FilterSalesOrder = initialState.FilterSalesOrder;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSalesOrders.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSalesOrders.fulfilled,
      (state, action: PayloadAction<ISalesOrder[]>) => {
        state.loading = false;
        state.SalesOrderList = action.payload || [];
      }
    );
    builder.addCase(getAllSalesOrders.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SalesOrderList = [];
    });
    builder.addCase(getAllLimitedSalesOrders.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllLimitedSalesOrders.fulfilled,
      (state, action: PayloadAction<ISalesOrder[]>) => {
        state.loading = false;
        state.SalesOrderList = action.payload || [];
      }
    );
    builder.addCase(getAllLimitedSalesOrders.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SalesOrderList = [];
    });
    builder.addCase(createSalesOrder.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createSalesOrder.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createSalesOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateSalesOrder.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateSalesOrder.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSalesOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteSalesOrder.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSalesOrder.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSalesOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getSalesOrderByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getSalesOrderByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.SalesOrderDetail = action.payload;
    });
    builder.addCase(getSalesOrderByID.rejected, (state, action) => {
      state.loading = false;
      state.SalesOrderDetail = initialState.SalesOrderDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getSalesOrderPrint.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getSalesOrderPrint.fulfilled, (state, action: any) => {
      state.loading = false;
      state.SalesOrderPrintDetail = action.payload;
    });
    builder.addCase(getSalesOrderPrint.rejected, (state, action) => {
      state.loading = false;
      state.SalesOrderPrintDetail = initialState.SalesOrderPrintDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getSalesOrderChildList.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getSalesOrderChildList.fulfilled,
      (state, action: PayloadAction<ISalesOrder[]>) => {
        state.loading = false;
        state.SalesOrderChildList = action.payload || [];
      }
    );
    builder.addCase(getSalesOrderChildList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SalesOrderChildList = [];
    });
  },
});

export const {
  clearSalesOrderDetails,
  clearSalesOrderID,
  setSalesOrderID,
  setSODeleteIndex,
  setStateForGST,
  clearStateForGST,
  clearSalesOrderChildList,
  setFilterSalesOrder,
  clearFilterSalesOrder,
} = salesOrderSlice.actions;
export default salesOrderSlice.reducer;
