import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog } from "./dialogSlice";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Ripple } from "@progress/kendo-react-ripple";
import { RootState } from "../../app/store";
import { IAppDialog } from "./dialogModel";

type themeColor =
  | "primary"
  | "base"
  | "secondary"
  | "tertiary"
  | "info"
  | "success"
  | "warning"
  | "error"
  | "dark"
  | "light"
  | "inverse";

export const DialogCloseButton: React.FC<{
  text?: string;
  themeColor?: themeColor;
}> = ({ text = "Close", themeColor = "primary" }) => {
  const dispatch = useAppDispatch();
  return (
    <Ripple>
      <Button
        style={{ marginRight: 4 }}
        fillMode={"outline"}
        themeColor={themeColor}
        onClick={() => dispatch(closeDialog())}
      >
        {text}
      </Button>
    </Ripple>
  );
};

const AppDialog: React.FC<IAppDialog> = (props) => {
  const dispatch = useAppDispatch();
  const open = useAppSelector((state: RootState) => state.dialog.open);

  return (
    <>
      {open && (
        <>
          <Dialog
            onClose={() => dispatch(closeDialog())}
            className={props.className}
          >
            {props.children}
          </Dialog>
        </>
      )}
    </>
  );
};

export default AppDialog;
