import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef } from "react";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "@progress/kendo-react-buttons";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { STATUSARRAY } from "../../_contstants/common";
import { LoadingPanel } from "../../components/layout/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import FormRadioGroup from "../../components/formFields/FormRadioGroup";
// import FormCheckbox from "../../components/formFields/FormCheckbox";
import {
  createAccount,
  // createAccount,
  getAccountByID,
  getAllActiveAccountsUnderGroup,
  updateAccount,
} from "./services/account.services";
import { clearAccountDetails } from "./accountSlice";
import { ICountry } from "../country/countryModel";
import { IState } from "../state/stateModel";
import { ICity } from "../city/cityModel";
import { getAllActiveCountries } from "../country/services/country.services";
import { getAllActiveCities } from "../city/services/city.services";
import { getAllActiveStates } from "../state/services/state.services";
import { IAccountUnderGroup } from "./accountModel";
import {
  emailWOValueValidator,
  gstWOValueValidator,
  MobileNoValidator,
  MobileNoWOValueValidator,
  panWOValueValidator,
  passwordValidator,
  pincodeWOValueValidator,
  requiredValidator,
  skipValidator,
} from "../../components/formFields/CommonValidator";
import AppDrawer from "../../components/muidrawer/Drawer";
import CreateCity from "../city/CreateCity";
import CreateState from "../state/CreateState";
import CreateCountry from "../country/CreateCountry";
import { closed } from "../../components/muidrawer/muiDrawerSlice";
import FormPasswordField from "../../components/formFields/FormPasswordField";
import { getAllActiveDistrict } from "../district/services/district.services";
import { IDistrict } from "../district/districtModel";
import { getAllActiveRoute } from "../route/services/route.services";
import { IRoute } from "../route/routeModel";
import FormTextArea from "../../components/formFields/FormTextArea";

const CreateAccount: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const AccountDetail = useAppSelector((state) => state.account.AccountDetail);
  const formLoading = useAppSelector((state) => state.account.formLoading);
  const AccountUnderGroupList = useAppSelector(
    (state) => state.account.AccountUnderGroupList
  );
  const CountryList = useAppSelector((state) => state.country.CountryList);
  const StateList = useAppSelector((state) => state.state.StateList);
  const CityList = useAppSelector((state) => state.city.CityList);
  const DistrictList = useAppSelector((state) => state.district.DistrictList);
  const RouteList = useAppSelector((state) => state.route.RouteList);
  const isDrawerOpen = useAppSelector((state) => state.muidrawer.open);
  const drawerName = useAppSelector((state) => state.muidrawer.name);

  const [formKey, setFormKey] = React.useState(1);

  const account_guid = location.state?.account_guid;
  const gridRef = useRef<any>(null);

  useEffect(() => {
    if (account_guid) {
      const payload = {
        account_guid: account_guid,
      };
      dispatch(getAccountByID(payload));
    }
  }, [account_guid]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [AccountDetail]);

  useEffect(() => {
    dispatch(getAllActiveAccountsUnderGroup());
    dispatch(getAllActiveCountries());
    dispatch(getAllActiveCities());
    dispatch(getAllActiveStates());
    dispatch(getAllActiveDistrict());
    dispatch(getAllActiveRoute());

    return () => {
      dispatch(clearAccountDetails());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    if (account_guid) {
      try {
        const editPayload = {
          account_guid: account_guid,
          under_group_id: values.under_group_id ? +values.under_group_id : null,
          user_type_id: values.user_type_id ? +values.user_type_id : null,
          user_name: values.user_name ? values.user_name : "",
          account_name: values.account_name,
          short_alias_name: values.short_alias_name,
          contact_person_name: values.contact_person_name,
          email: values.email,
          mobile_number: values.mobile_number,
          address1: values.address1,
          pincode: values.pincode ? +values.pincode : null,
          country_id: values.country_id ? +values.country_id : null,
          state_id: values.state_id ? +values.state_id : null,
          city_id: values.city_id ? +values.city_id : null,
          district_id: values?.district_id ? +values?.district_id : null,
          address2: values.address2,
          pincode2: values.pincode2 ? +values.pincode2 : null,
          country_id2: values.country_id2 ? +values.country_id2 : null,
          state_id2: values.state_id2 ? +values.state_id2 : null,
          city_id2: values.city_id2 ? +values.city_id2 : null,
          district_id2: values?.district_id2 ? +values?.district_id2 : null,
          payment_due_days: values.payment_due_days
            ? +values.payment_due_days
            : null,
          provide_bank_details: values.provide_bank_details,
          account_no: values.account_no,
          bank_account_name: values.bank_account_name,
          bank_name: values.bank_name,
          ifsc_code: values.ifsc_code,
          pan_no: values.pan_no,
          gst_no: values.gst_no,
          route_id: values?.route_id ? +values?.route_id : null,
          alternet_mobile_no: values?.alternet_mobile_no
            ? values?.alternet_mobile_no
            : "",
          enable_credit_limit: values.enable_credit_limit,
          limit_set_based_on_no_of_invoice:
            values.limit_set_based_on_no_of_invoice
              ? +values.limit_set_based_on_no_of_invoice
              : null,
          limit_set_based_on_outstanding_amount:
            values.limit_set_based_on_outstanding_amount
              ? +values.limit_set_based_on_outstanding_amount
              : null,
          opening_balance: values.opening_balance
            ? values.opening_balance
            : "0",
          openingbalance_credit_debit: values.openingbalance_credit_debit,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(updateAccount(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/account");
          dispatch(closed());
          dispatch(clearAccountDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
      }
    } else {
      try {
        const insertPayload = {
          under_group_id: values.under_group_id ? +values.under_group_id : null,
          account_name: values.account_name,
          user_type_id: values.user_type_id ? +values.user_type_id : null,
          user_name: values.user_name ? values.user_name : "",
          password: values.password ? values.password : "",
          short_alias_name: values.short_alias_name,
          contact_person_name: values.contact_person_name,
          email: values.email,
          mobile_number: values.mobile_number,
          address1: values.address1,
          pincode: values.pincode ? +values.pincode : null,
          country_id: values.country_id ? +values.country_id : null,
          state_id: values.state_id ? +values.state_id : null,
          city_id: values.city_id ? +values.city_id : null,
          district_id: values?.district_id ? +values?.district_id : null,
          address2: values.address2,
          pincode2: values.pincode2 ? +values.pincode2 : null,
          country_id2: values.country_id2 ? +values.country_id2 : null,
          state_id2: values.state_id2 ? +values.state_id2 : null,
          city_id2: values.city_id2 ? +values.city_id2 : null,
          district_id2: values?.district_id2 ? +values?.district_id2 : null,
          payment_due_days: values.payment_due_days
            ? +values.payment_due_days
            : null,
          provide_bank_details: values.provide_bank_details,
          account_no: values.account_no,
          bank_account_name: values.bank_account_name,
          bank_name: values.bank_name,
          ifsc_code: values.ifsc_code,
          pan_no: values.pan_no,
          gst_no: values.gst_no,
          route_id: values?.route_id ? +values?.route_id : null,
          alternet_mobile_no: values?.alternet_mobile_no
            ? values?.alternet_mobile_no
            : "",
          enable_credit_limit: values.enable_credit_limit,
          limit_set_based_on_no_of_invoice:
            values.limit_set_based_on_no_of_invoice
              ? +values.limit_set_based_on_no_of_invoice
              : null,
          limit_set_based_on_outstanding_amount:
            values.limit_set_based_on_outstanding_amount
              ? +values.limit_set_based_on_outstanding_amount
              : null,
          opening_balance: values.opening_balance
            ? values.opening_balance
            : "0",
          openingbalance_credit_debit: values.openingbalance_credit_debit,
          isactive: values.isactive === 1,
        };
        const response = await dispatch(createAccount(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/account");
          dispatch(closed());
          dispatch(clearAccountDetails());
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
    navigate("/account");
  };

  const CREDIT_DEBIT = [
    { label: "Credit", value: "credit" },
    { label: "Debit", value: "debit" },
  ];
  const BANK_DETAIL = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const ENABLE_CREDIT_LIMIT = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {isDrawerOpen && drawerName === "cityForm" && (
        <AppDrawer>
          <CreateCity />
        </AppDrawer>
      )}
      {isDrawerOpen && drawerName === "stateForm" && (
        <AppDrawer>
          <CreateState />
        </AppDrawer>
      )}
      {isDrawerOpen && drawerName === "countryForm" && (
        <AppDrawer>
          <CreateCountry />
        </AppDrawer>
      )}
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={AccountDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {account_guid ? "Update Account" : "Add Account"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="user_type_id"
                    label="User Type"
                    placeholder="User Type"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={[
                      { label: "Distributor", value: 1 },
                      { label: "Retailer", value: 2 },
                    ]}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="user_name"
                    label="User Name"
                    placeholder="User Name"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                {!account_guid && (
                  <GridLayoutItem>
                    <Field
                      name={"password"}
                      type={"password"}
                      component={FormPasswordField}
                      label={"Password"}
                      placeholder="Enter your password"
                      validator={passwordValidator}
                    />
                  </GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="under_group_id"
                    label="Under Group"
                    placeholder="Under Group"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={AccountUnderGroupList?.map(
                      (account: IAccountUnderGroup) => {
                        return {
                          value: account?.id,
                          label: account?.under_group_name,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="account_name"
                    label="Account Name"
                    placeholder="Account Name"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="short_alias_name"
                    label="Short/Alise Name"
                    placeholder="Short/Alise Name"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="contact_person_name"
                    label="Contact Person Name"
                    placeholder="Contact Person Name"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100"
                    name="mobile_number"
                    label="Mobile No."
                    placeholder="Mobile No."
                    type="number"
                    max={16}
                    component={FormTextField}
                    validator={MobileNoValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100"
                    name="alternet_mobile_no"
                    label="Alt. Mobile No."
                    placeholder="Alt. Mobile No."
                    type="number"
                    max={16}
                    component={FormTextField}
                    validator={MobileNoWOValueValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="w-100"
                    name="email"
                    label="Email"
                    placeholder="Email"
                    component={FormTextField}
                    validator={emailWOValueValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  <Typography.h4
                    style={{ marginTop: "25px", marginBottom: "3px" }}
                  >
                    Address Details
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  <GridLayout
                    style={{ marginRight: 10 }}
                    gap={{ rows: 0, cols: 10 }}
                    cols={[{ width: "50%" }, { width: "50%" }]}
                  >
                    <GridLayoutItem
                      style={{
                        padding: 10,
                        background: "whitesmoke",
                        borderRadius: 10,
                      }}
                    >
                      <GridLayout
                        style={{ marginRight: 10 }}
                        gap={{ rows: 0, cols: 10 }}
                        cols={[{ width: "50%" }, { width: "50%" }]}
                      >
                        <GridLayoutItem
                          colSpan={2}
                          className="mt-3"
                          style={{ borderBottom: "3px solid white" }}
                        >
                          <h5>Billing Address</h5>
                        </GridLayoutItem>
                        <GridLayoutItem colSpan={2}>
                          <Field
                            wrapperClassName="w-100"
                            name="address1"
                            label="Address"
                            placeholder="Address"
                            component={FormTextArea}
                            rows={3}
                          />
                        </GridLayoutItem>
                        <GridLayoutItem>
                          <Field
                            name="city_id"
                            label="City"
                            placeholder="City"
                            isAddNew={true}
                            isIndirect={true}
                            drawerName="cityForm"
                            component={FormSelectionField}
                            options={CityList?.map((city: ICity) => {
                              return {
                                value: city?.id,
                                label: city?.city_name,
                              };
                            })}
                          />
                        </GridLayoutItem>
                        <GridLayoutItem>
                          <Field
                            name="pincode"
                            label="Pincode"
                            type="number"
                            placeholder="Pincode"
                            minNumber="0"
                            component={FormTextField}
                            validator={pincodeWOValueValidator}
                          />
                        </GridLayoutItem>
                        <GridLayoutItem>
                          <Field
                            name="district_id"
                            label="District"
                            placeholder="District"
                            component={FormSelectionField}
                            options={DistrictList?.map(
                              (district: IDistrict) => {
                                return {
                                  value: district?.id,
                                  label: district?.district_name,
                                };
                              }
                            )}
                          />
                        </GridLayoutItem>
                        <GridLayoutItem>
                          <Field
                            name="state_id"
                            label="State"
                            placeholder="State"
                            isAddNew={true}
                            isIndirect={true}
                            drawerName="stateForm"
                            component={FormSelectionField}
                            validator={requiredValidator}
                            astrike={true}
                            options={StateList?.map((state: IState) => {
                              return {
                                value: state?.id,
                                label: state?.state_name,
                              };
                            })}
                          />
                        </GridLayoutItem>
                        <GridLayoutItem colSpan={2}>
                          <Field
                            name="country_id"
                            label="Country"
                            placeholder="Country"
                            isAddNew={true}
                            isIndirect={true}
                            drawerName="countryForm"
                            component={FormSelectionField}
                            options={CountryList?.map((country: ICountry) => {
                              return {
                                value: country?.id,
                                label: country?.country_name,
                              };
                            })}
                          />
                        </GridLayoutItem>
                      </GridLayout>
                    </GridLayoutItem>
                    <GridLayoutItem
                      style={{
                        padding: 10,
                        background: "whitesmoke",
                        borderRadius: 10,
                      }}
                    >
                      <GridLayout
                        style={{ marginRight: 10 }}
                        gap={{ rows: 0, cols: 10 }}
                        cols={[{ width: "50%" }, { width: "50%" }]}
                      >
                        <GridLayoutItem
                          colSpan={2}
                          className="mt-3"
                          style={{ borderBottom: "3px solid white" }}
                        >
                          <h5>Shipping Address</h5>
                        </GridLayoutItem>
                        <GridLayoutItem colSpan={2}>
                          <Field
                            wrapperClassName="w-100"
                            name="address2"
                            label="Address"
                            placeholder="Address"
                            component={FormTextArea}
                            rows={3}
                          />
                        </GridLayoutItem>
                        <GridLayoutItem>
                          <Field
                            name="city_id2"
                            label="City"
                            placeholder="City"
                            isAddNew={true}
                            isIndirect={true}
                            drawerName="cityForm"
                            component={FormSelectionField}
                            options={CityList?.map((city: ICity) => {
                              return {
                                value: city?.id,
                                label: city?.city_name,
                              };
                            })}
                          />
                        </GridLayoutItem>
                        <GridLayoutItem>
                          <Field
                            name="pincode2"
                            label="Pincode"
                            type="number"
                            placeholder="Pincode"
                            minNumber="0"
                            component={FormTextField}
                            validator={pincodeWOValueValidator}
                          />
                        </GridLayoutItem>
                        <GridLayoutItem>
                          <Field
                            name="district_id2"
                            label="District"
                            placeholder="District"
                            component={FormSelectionField}
                            options={DistrictList?.map(
                              (district: IDistrict) => {
                                return {
                                  value: district?.id,
                                  label: district?.district_name,
                                };
                              }
                            )}
                          />
                        </GridLayoutItem>
                        <GridLayoutItem>
                          <Field
                            name="state_id2"
                            label="State"
                            placeholder="State"
                            isAddNew={true}
                            isIndirect={true}
                            drawerName="stateForm"
                            component={FormSelectionField}
                            validator={
                              formRenderProps.valueGetter("address2")
                                ? requiredValidator
                                : skipValidator
                            }
                            astrike={
                              formRenderProps.valueGetter("address2")
                                ? true
                                : false
                            }
                            options={StateList?.map((state: IState) => {
                              return {
                                value: state?.id,
                                label: state?.state_name,
                              };
                            })}
                          />
                        </GridLayoutItem>
                        <GridLayoutItem colSpan={2}>
                          <Field
                            name="country_id2"
                            label="Country"
                            placeholder="Country"
                            isAddNew={true}
                            isIndirect={true}
                            drawerName="countryForm"
                            component={FormSelectionField}
                            options={CountryList?.map((country: ICountry) => {
                              return {
                                value: country?.id,
                                label: country?.country_name,
                              };
                            })}
                          />
                        </GridLayoutItem>
                      </GridLayout>
                    </GridLayoutItem>
                  </GridLayout>
                </GridLayoutItem>
                {/* <GridLayoutItem
                  colSpan={3}
                  style={{ display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperClassName="w-100"
                    name="address1"
                    label="Address 1"
                    placeholder="Address 1"
                    component={FormTextArea}
                    rows={2}
                  />
                  <Field
                    wrapperClassName="w-100"
                    name="address2"
                    label="Address 2"
                    placeholder="Address 2"
                    component={FormTextArea}
                    rows={2}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="pincode"
                    label="Pincode"
                    type="number"
                    placeholder="Pincode"
                    minNumber="0"
                    component={FormTextField}
                    validator={pincodeWOValueValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="city_id"
                    label="City"
                    placeholder="City"
                    isAddNew={true}
                    isIndirect={true}
                    drawerName="cityForm"
                    component={FormSelectionField}
                    options={CityList?.map((city: ICity) => {
                      return {
                        value: city?.id,
                        label: city?.city_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="district_id"
                    label="District"
                    placeholder="District"
                    component={FormSelectionField}
                    options={DistrictList?.map((district: IDistrict) => {
                      return {
                        value: district?.id,
                        label: district?.district_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="state_id"
                    label="State"
                    placeholder="State"
                    isAddNew={true}
                    isIndirect={true}
                    drawerName="stateForm"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={StateList?.map((state: IState) => {
                      return {
                        value: state?.id,
                        label: state?.state_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="country_id"
                    label="Country"
                    placeholder="Country"
                    isAddNew={true}
                    isIndirect={true}
                    drawerName="countryForm"
                    component={FormSelectionField}
                    options={CountryList?.map((country: ICountry) => {
                      return {
                        value: country?.id,
                        label: country?.country_name,
                      };
                    })}
                  />
                </GridLayoutItem> */}
                <GridLayoutItem>
                  <Field
                    name="route_id"
                    label="Route"
                    placeholder="Route"
                    component={FormSelectionField}
                    options={RouteList?.map((route: IRoute) => {
                      return {
                        value: route?.id,
                        label: route?.route_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="pan_no"
                    label="PAN No"
                    placeholder="PAN No"
                    component={FormTextField}
                    validator={panWOValueValidator}
                    onChange={(e) =>
                      formRenderProps.onChange("pan_no", {
                        value: e?.value?.toUpperCase(),
                      })
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="gst_no"
                    label="GST No"
                    placeholder="GST No"
                    component={FormTextField}
                    onChange={(e) =>
                      formRenderProps.onChange("gst_no", {
                        value: e?.value?.toUpperCase(),
                      })
                    }
                    validator={gstWOValueValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem></GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  <GridLayout
                    style={{ marginRight: 30 }}
                    gap={{ rows: 0, cols: 10 }}
                    cols={[
                      { width: "25%" },
                      { width: "25%" },
                      { width: "25%" },
                      { width: "25%" },
                    ]}
                  >
                    <GridLayoutItem
                      colSpan={2}
                      style={{
                        background: "whitesmoke",
                        marginTop: "10px",
                        padding: "0 10px 15px",
                        borderRadius: formRenderProps.valueGetter(
                          "provide_bank_details"
                        )
                          ? "10px 10px 0 0"
                          : "10px",
                      }}
                    >
                      <Field
                        wrapperClassName="d-flex gap-3 align-items-center"
                        label="Provide Bank Details"
                        component={FormRadioGroup}
                        name={`provide_bank_details`}
                        layout="horizontal"
                        options={BANK_DETAIL}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem
                      colSpan={2}
                      style={{
                        background: "whitesmoke",
                        marginTop: "10px",
                        padding: "0 10px 15px",
                        borderRadius: formRenderProps.valueGetter(
                          "enable_credit_limit"
                        )
                          ? "10px 10px 0 0"
                          : "10px",
                      }}
                    >
                      <Field
                        wrapperClassName="d-flex gap-3 align-items-center"
                        label="Enable Credit Limit"
                        component={FormRadioGroup}
                        name={`enable_credit_limit`}
                        layout="horizontal"
                        options={ENABLE_CREDIT_LIMIT}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem
                      colSpan={2}
                      style={
                        formRenderProps.valueGetter("provide_bank_details") && {
                          background: "whitesmoke",
                          padding: "10px",
                          borderRadius: "0 0 10px 10px",
                        }
                      }
                    >
                      {formRenderProps.valueGetter("provide_bank_details") && (
                        <GridLayout
                          style={{ marginRight: 10 }}
                          gap={{ rows: 0, cols: 10 }}
                          cols={[{ width: "50%" }, { width: "50%" }]}
                        >
                          <GridLayoutItem>
                            <Field
                              wrapperClassName="w-100"
                              label="Bank Name"
                              name="bank_name"
                              placeholder="Bank Name"
                              component={FormTextField}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              wrapperClassName="w-100"
                              name="bank_account_name"
                              label="Bank Account Name"
                              placeholder="Bank Account Name"
                              component={FormTextField}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              wrapperClassName="w-100"
                              label="A/C No"
                              name="account_no"
                              placeholder="A/C No"
                              type="number"
                              component={FormTextField}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem>
                            <Field
                              label="IFSC Code"
                              name="ifsc_code"
                              placeholder="IFSC Code"
                              component={FormTextField}
                            />
                          </GridLayoutItem>
                        </GridLayout>
                      )}
                    </GridLayoutItem>
                    <GridLayoutItem
                      colSpan={2}
                      style={
                        formRenderProps.valueGetter("enable_credit_limit") && {
                          background: "whitesmoke",
                          padding: "10px",
                          borderRadius: "0 0 10px 10px",
                        }
                      }
                    >
                      {formRenderProps.valueGetter("enable_credit_limit") && (
                        <GridLayout
                          style={{ marginRight: 10 }}
                          gap={{ rows: 0, cols: 10 }}
                          cols={[{ width: "50%" }, { width: "50%" }]}
                        >
                          <GridLayoutItem colSpan={2}>
                            <Field
                              style={{ width: "100%" }}
                              label="Limit set based on no of Invoice"
                              name="limit_set_based_on_no_of_invoice"
                              type="number"
                              placeholder="Limit set based on no of Invoice"
                              component={FormTextField}
                            />
                          </GridLayoutItem>
                          <GridLayoutItem colSpan={2}>
                            <Field
                              style={{ width: "100%" }}
                              label="Limit set based on outstanding amount"
                              name="limit_set_based_on_outstanding_amount"
                              type="number"
                              placeholder="Limit set based on outstanding amount"
                              component={FormTextField}
                            />
                          </GridLayoutItem>
                        </GridLayout>
                      )}
                    </GridLayoutItem>
                  </GridLayout>
                </GridLayoutItem>
                {/* {formRenderProps.valueGetter("enable_credit_limit") ? (
                  <>
                    <GridLayoutItem>
                      <Field
                        style={{ width: "100%" }}
                        label="Limit set based on no of Invoice"
                        name="limit_set_based_on_no_of_invoice"
                        type="number"
                        placeholder="Limit set based on no of Invoice"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Field
                        style={{ width: "100%" }}
                        label="Limit set based on outstanding amount"
                        name="limit_set_based_on_outstanding_amount"
                        type="number"
                        placeholder="Limit set based on outstanding amount"
                        component={FormTextField}
                      />
                    </GridLayoutItem>
                  </>
                ) : (
                  <GridLayoutItem colSpan={3}></GridLayoutItem>
                )} */}
                <GridLayoutItem
                  style={{ width: "100%", display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperClassName="w-100"
                    name="opening_balance"
                    label="Opening Balance"
                    placeholder="Opening Balance"
                    type={"number"}
                    min="0"
                    component={FormTextField}
                  />
                  <Field
                    // wrapperClassName="w-100"
                    name="openingbalance_credit_debit"
                    label="Cr/Dr"
                    component={FormSelectionField}
                    options={CREDIT_DEBIT?.map((cr_dr: any) => {
                      return {
                        value: cr_dr.value,
                        label: cr_dr.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="payment_due_days"
                    label="Payment Due Days"
                    placeholder="0"
                    type={"number"}
                    min="0"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"isactive"}
                    name={"isactive"}
                    component={FormSelectionField}
                    label={"Status"}
                    options={STATUSARRAY?.map((status: any) => {
                      return {
                        value: status.value,
                        label: status.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <ButtonWithLoading
                    label={!account_guid ? "Save" : "Update"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || formLoading}
                    loading={formLoading}
                  />
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={handleFormClose}
                  >
                    Cancel
                  </Button>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateAccount;
