import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import {
  createSalesOrder,
  getSalesOrderByID,
  updateSalesOrder,
} from "./services/salesOrder.services";
import {
  clearSalesOrderDetails,
  clearStateForGST,
  setStateForGST,
} from "./salesOrderSlice";
import moment from "moment";
import FormDatePicker from "../../components/formFields/FormDateField";
import {
  getAccountByID,
  getAllAccountIncremental,
} from "../account/services/account.services";
import { findAllActiveUnit } from "../unit/services/unit.services";
import { getAllActiveGST } from "../gst/services/gst.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { FINANCIAL_YEAR, GUJARATIDFORGST } from "../../_contstants/common";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import { clearAccountList } from "../account/accountSlice";
import { getAllItemIncremental } from "../Item/services/item.services";
import SOItemDetailsArray from "./SOItemDetailsArray";
import { closeDialog } from "../../components/dialog/dialogSlice";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const QtychangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const sales_order_items = formRenderProps.valueGetter("sales_order_items");
  const taxable_amount = formRenderProps.valueGetter("taxable_amount");
  const gst = formRenderProps.valueGetter("gst");
  const sub_total = formRenderProps.valueGetter("sub_total");
  const round_off = formRenderProps.valueGetter("round_off");
  const stateForGST = useAppSelector((state) => state.salesOrder.stateForGST);

  useEffect(() => {
    let totalGSTamount = 0;
    sales_order_items?.map((item: any, index: number) => {
      const gst_id = item?.gst_id;
      const amount = item?.amount;
      const gstid = gst_id ? parseInt(gst_id?.split("--")[1]) : 0;
      totalGSTamount += (+amount * +gstid) / 100;
      if (!gst_id) {
        formRenderProps.onChange(`sales_order_items.${index}.gst_id`, {
          value: null,
        });
      }
    });
    formRenderProps.onChange("gst", {
      value: totalGSTamount || 0,
    });
    if (stateForGST === GUJARATIDFORGST) {
      formRenderProps.onChange("cgst", {
        value: totalGSTamount / 2 || 0,
      });
      formRenderProps.onChange("sgst", {
        value: totalGSTamount / 2 || 0,
      });
    } else {
      formRenderProps.onChange("igst", {
        value: totalGSTamount || 0,
      });
    }
  }, [
    stateForGST,
    sales_order_items?.map((item: any) => item?.gst_id).join("-"),
    sales_order_items?.map((item: any) => item?.amount).join("-"),
  ]);

  let totalamount = 0;
  let totalQty = 0;

  useEffect(() => {
    if (sales_order_items && sales_order_items.length > 0) {
      sales_order_items?.map((item: any) => {
        totalQty += +item?.quantity || 0;
        totalamount += +item?.amount || 0;
      });
    }
    formRenderProps.onChange("totalQty", {
      value: totalQty,
    });
    formRenderProps.onChange("taxable_amount", {
      value: totalamount,
    });
  }, [
    sales_order_items.map((item: any) => item?.quantity).join("-"),
    sales_order_items.map((item: any) => item?.amount).join("-"),
  ]);

  useEffect(() => {
    if (sales_order_items && sales_order_items.length > 0) {
      sales_order_items?.map((item: any, index: number) => {
        const quantity = item?.quantity || 0;
        const rate = item?.rate || 0;
        formRenderProps.onChange(`sales_order_items.${index}.amount`, {
          value: +quantity * +rate,
        });
      });
    }
  }, [
    sales_order_items?.map((item: any) => item?.quantity).join("-"),
    sales_order_items?.map((item: any) => item?.rate).join("-"),
  ]);

  useEffect(() => {
    formRenderProps.onChange("sub_total", {
      value: +taxable_amount + +gst || 0,
    });
    formRenderProps.onChange("round_off", {
      value: Math.round(+sub_total) - +sub_total,
    });
    formRenderProps.onChange("ground_total", {
      value: +sub_total + +round_off || 0,
    });
  }, [taxable_amount, gst, sub_total, round_off]);

  return null;
};

// const VendorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
//   formRenderProps,
// }) => {
//   const dispatch = useAppDispatch();
//   const dealer_id = formRenderProps.valueGetter("dealer_id");

//   useEffect(() => {
//     if (dealer_id) {

//       handleVendorChange();
//     } else {
//       dispatch(setStateForGST(null));
//     }
//   }, [dealer_id]);

//   return null;
// };

const CreateSalesOrder: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sale_order_guid = location.state?.sale_order_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.salesOrder.loading);
  const stateForGST = useAppSelector((state) => state.salesOrder.stateForGST);
  const SalesOrderDetail = useAppSelector(
    (state) => state.salesOrder.SalesOrderDetail
  );
  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [SalesOrderDetail, sale_order_guid]);

  useEffect(() => {
    if (sale_order_guid) {
      dispatch(getSalesOrderByID({ sale_order_guid: sale_order_guid }));
    }
  }, [sale_order_guid]);

  useEffect(() => {
    dispatch(findAllActiveUnit());
    dispatch(getAllActiveGST());

    return () => {
      dispatch(clearSalesOrderDetails());
      dispatch(clearStateForGST());
      dispatch(clearAccountList());
      dispatch(closeDialog());
    };
  }, []);

  const handleVendorChange = async (dealer_id: number) => {
    if (dealer_id) {
      const payload = {
        id: dealer_id,
      };
      const response = await dispatch(getAccountByID(payload));
      if (response.meta.requestStatus === "fulfilled") {
        dispatch(setStateForGST(response.payload?.state_id));
      }
    } else {
      dispatch(setStateForGST(null));
    }
  };

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (sale_order_guid) {
      try {
        const updatePayload: any = {
          id: SalesOrderDetail?.id,
          sale_order_guid: SalesOrderDetail?.sale_order_guid,
          sales_date: values?.sales_date
            ? moment(values?.sales_date).format("YYYY-MM-DD")
            : "",
          dealer_id: values?.dealer_id ? values?.dealer_id : null,
          financial_year: FINANCIAL_YEAR,
          total_amount: values?.ground_total ? values?.ground_total : null,
          // total_gst: values?.total_gst ? values?.total_gst : null,
          gst: values?.gst ? values?.gst : null,
          sales_order_items: values?.sales_order_items
            ? values?.sales_order_items?.map((soitems: any) => {
                return {
                  item_id: soitems?.item_id ? +soitems?.item_id : null,
                  mrp: soitems?.mrp ? +soitems?.mrp : null,
                  quantity: soitems?.quantity ? +soitems?.quantity : null,
                  unit_id: soitems?.unit_id ? +soitems?.unit_id : null,
                  rate: soitems?.rate ? +soitems?.rate : null,
                  gst_id: soitems?.gst_id
                    ? parseInt(soitems?.gst_id.split("--")[0])
                    : null,
                  amount: soitems?.amount ? +soitems?.amount : null,
                };
              })
            : [],
        };

        if (stateForGST === GUJARATIDFORGST) {
          updatePayload.cgst = values?.cgst ? values?.cgst : null;
          updatePayload.sgst = values?.sgst ? values?.sgst : null;
          updatePayload.igst = null;
        } else {
          updatePayload.igst = values?.igst ? values?.igst : null;
          updatePayload.cgst = null;
          updatePayload.sgst = null;
        }

        const response = await dispatch(updateSalesOrder(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/salesorder");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload: any = {
          sales_date: values?.sales_date
            ? moment(values?.sales_date).format("YYYY-MM-DD")
            : "",
          dealer_id: values?.dealer_id ? values?.dealer_id : null,
          financial_year: FINANCIAL_YEAR,
          total_amount: values?.ground_total ? values?.ground_total : null,
          // total_gst: values?.total_gst ? values?.total_gst : null,
          gst: values?.gst ? values?.gst : null,
          sales_order_items: values?.sales_order_items
            ? values?.sales_order_items?.map((soitems: any) => {
                return {
                  item_id: soitems?.item_id ? +soitems?.item_id : null,
                  mrp: soitems?.mrp ? +soitems?.mrp : null,
                  quantity: soitems?.quantity ? +soitems?.quantity : null,
                  unit_id: soitems?.unit_id ? +soitems?.unit_id : null,
                  rate: soitems?.rate ? +soitems?.rate : null,
                  gst_id: soitems?.gst_id
                    ? parseInt(soitems?.gst_id.split("--")[0])
                    : null,
                  amount: soitems?.amount ? +soitems?.amount : null,
                };
              })
            : [],
        };
        if (stateForGST === GUJARATIDFORGST) {
          insertPayload.cgst = values?.cgst ? values?.cgst : null;
          insertPayload.sgst = values?.sgst ? values?.sgst : null;
          insertPayload.igst = null;
        } else {
          insertPayload.igst = values?.igst ? values?.igst : null;
          insertPayload.cgst = null;
          insertPayload.sgst = null;
        }

        const response = await dispatch(createSalesOrder(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/salesorder");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handlePONoChange = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter" || ev.key === "Tab") {
      ev.preventDefault();
      if ((ev.target as HTMLInputElement).value) {
        const payload = {
          sales_order_no: `${(ev.target as HTMLInputElement).value}`,
          financial_year: FINANCIAL_YEAR,
        };
        dispatch(getSalesOrderByID(payload));
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={SalesOrderDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 10, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {sale_order_guid ? "Update Sales Order" : "Add Sales Order"}
                  </Typography.h4>
                </GridLayoutItem>
                {sale_order_guid && (
                  <GridLayoutItem>
                    <Field
                      wrapperClassName="right-alighned-field"
                      name="sales_order_no"
                      onKeyDown={handlePONoChange}
                      label="Sales Order No"
                      placeholder="0"
                      component={FormTextField}
                      validator={requiredValidator}
                      astrike={true}
                    />
                  </GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="sales_date"
                    label="Sales Order Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="dealer_id"
                    label="Dealer Name"
                    placeholder="Dealer Name"
                    isAddNew={true}
                    addNewLink="account/create"
                    component={FormIncrementalSearch}
                    onChange={() =>
                      handleVendorChange(
                        formRenderProps.valueGetter("dealer_id")
                      )
                    }
                    validator={requiredValidator}
                    astrike={true}
                    fetchIncrementalData={(search: any) =>
                      handleVendorSearchChange(
                        search,
                        `vendor_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("vendor_options")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.account_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={sale_order_guid ? 1 : 2}
                ></GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Item Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem colSpan={4}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={SOItemDetailsArray}
                    handleItemSearchChange={handleItemSearchChange}
                    name="sales_order_items"
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}></GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  rowSpan={6}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td
                          style={{
                            width: "60%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Total
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "30%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="taxable_amount"
                            format="n2"
                            component={FormNumericTextField}
                            disabled="true"
                          />
                        </td>
                      </tr>
                      {stateForGST === GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "60%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            CGST
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "30%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="cgst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      {stateForGST === GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "60%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            SGST
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "30%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="sgst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      {stateForGST !== GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "60%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            IGST
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "30%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="igst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td
                          style={{
                            width: "60%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Sub Total
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "30%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="sub_total"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "60%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Round Off
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "30%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="round_off"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "0%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Grand Total
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "30%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="ground_total"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      {/* <tr>
                        <td
                          style={{
                            width: "60%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Confirm Total
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "30%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="confirm_total"
                            placeholder="0.00"
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr> */}
                    </table>
                  </div>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <ButtonWithLoading
                    label={sale_order_guid ? "Update" : "Save"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginLeft: 4 }}
                    onClick={() => navigate("/salesorder")}
                  >
                    Cancel
                  </Button>
                </GridLayoutItem>
              </GridLayout>
              <QtychangeWatcher formRenderProps={formRenderProps} />
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateSalesOrder;
