import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { IRoasting, IRoastingInitial } from "./roastingModel";
import {
  createRoasting,
  createRoastingNew,
  deleteRoasting,
  getAllActiveRoastings,
  getAllRoasting,
  getRoastingByID,
  getRoastingByIDNew,
  updateRoasting,
  updateRoastingNew,
} from "./services/roasting.services";

const initialState: IRoastingInitial = {
  loading: false,
  error: "",
  RoastingID: null,
  RoastingDeleteIndex: -1,
  RoastingList: [],
  RoastingDetail: {
    roasting_date: moment().toDate(),
    roasting_no: null,
    // item_id: null,
    // grn_no: null,
    no_of_workers: null,
    machine_id: null,
    lot_no: "",
    start_time: "",
    end_time: "",
    total_qty: null,
    received_qty: null,
    process_loss_qty: null,
    checker_by: null,
    financial_year: null,
    // department_id: null,
    // previous_process_id: null,
    process_id: null,
    remarks: "",
    ready_product_id: null,
    roasting_items: [],
  },
  FilterRoasting: {
    product_name: "",
    roasting_date: "",
  },
};

const productCleaningSlice = createSlice({
  name: "roasting",
  initialState,
  reducers: {
    clearRoastingDetails: (state) => {
      state.RoastingDetail = initialState.RoastingDetail;
    },
    setRoastingID: (state, action) => {
      state.RoastingID = action.payload;
    },
    clearRoastingID: (state) => {
      state.RoastingID = initialState.RoastingID;
    },
    setFilterRoasting: (state, action) => {
      state.FilterRoasting = action.payload;
    },
    clearFilterRoasting: (state) => {
      state.FilterRoasting = initialState.FilterRoasting;
    },
    setRoastingDeleteIndex: (state, action) => {
      state.RoastingDeleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRoasting.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllRoasting.fulfilled,
      (state, action: PayloadAction<IRoasting[]>) => {
        state.loading = false;
        state.RoastingList = action.payload || [];
      }
    );
    builder.addCase(getAllRoasting.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RoastingList = [];
    });
    builder.addCase(getAllActiveRoastings.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveRoastings.fulfilled,
      (state, action: PayloadAction<IRoasting[]>) => {
        state.loading = false;
        state.RoastingList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveRoastings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RoastingList = [];
    });
    builder.addCase(createRoasting.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createRoasting.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(createRoasting.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateRoasting.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateRoasting.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateRoasting.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteRoasting.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteRoasting.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteRoasting.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getRoastingByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getRoastingByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.RoastingDetail = action.payload;
    });
    builder.addCase(getRoastingByID.rejected, (state, action) => {
      state.loading = false;
      state.RoastingDetail = initialState.RoastingDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(createRoastingNew.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createRoastingNew.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(createRoastingNew.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateRoastingNew.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateRoastingNew.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateRoastingNew.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getRoastingByIDNew.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getRoastingByIDNew.fulfilled, (state, action: any) => {
      state.loading = false;
      state.RoastingDetail = action.payload;
    });
    builder.addCase(getRoastingByIDNew.rejected, (state, action) => {
      state.loading = false;
      state.RoastingDetail = initialState.RoastingDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearRoastingDetails,
  clearRoastingID,
  setRoastingID,
  setFilterRoasting,
  clearFilterRoasting,
  setRoastingDeleteIndex,
} = productCleaningSlice.actions;
export default productCleaningSlice.reducer;
