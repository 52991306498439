import { Button } from "@progress/kendo-react-buttons";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";

import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";
import {
  deleteEmployeeLeave,
  getAllEmployeeLeave,
} from "./services/employeeLeave.services";
import {
  clearEmployeeLeaveGUID,
  setEmployeeLeaveGUID,
} from "./employeeLeaveSlice";
import { FiEdit } from "react-icons/fi";
import { checkAcessRights } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";

const EmployeeLeave: React.FC = () => {
  const navigate = useNavigate();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);
  const handleEmployeeLeaveCreate = () => {
    navigate("/employeeLeave/create");
  };
  return (
    <>
      <DeleteEmployeeLeaveDialog />
      {/* <GridLayoutItem colSpan={3} style={{marginBottom:"10px"}}>
        <EmployeeLeaveTab />
      </GridLayoutItem> */}
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Employee Leave List
          </Typography.h4>

          {isAddAccess && (
            <Button
              onClick={handleEmployeeLeaveCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              Apply Leave
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <EmployeeLeaveGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const EmployeeLeaveGridComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const EmployeeLeaveList = useAppSelector(
    (state) => state.employeeLeave.EmployeeLeaveList
  );
  const loading = useAppSelector((state) => state.employeeLeave.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  useEffect(() => {
    const fetchItemList = async () => {
      const payload = {
        employee_id: Number(localStorage.getItem("UserID")) || 0,
        leavestatus: "all",
        limit: +pageLimit,
        skip: +currentPage * +pageLimit,
      };
      await dispatch(getAllEmployeeLeave(payload));
    };
    fetchItemList();
  }, [currentPage, pageLimit]);

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditEmployeeLeave = (ID: number) => {
      navigate("/employeeLeave/edit", {
        state: { employee_leave_guid: ID, id: props.dataItem?.id },
      });
    };

    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteEmployeeLeave"));
      dispatch(setEmployeeLeaveGUID(ID));
    };

    return (
      <td className="action-td">
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            disabled={props.dataItem.leavestatus !== "pending"}
            onClick={() =>
              handleEditEmployeeLeave(props.dataItem.employee_leave_guid)
            }
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            disabled={props.dataItem.leavestatus !== "pending"}
            onClick={() =>
              handleOpenDeleteDialog(props.dataItem.employee_leave_guid)
            }
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 5 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  // const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
  //   const [IsActive, setIsActive] = useState<boolean>(props.dataItem?.isactive);

  //   const handleActiveInActive = () => {
  //     setIsActive(!IsActive);
  //     const data = {
  //       employee_leave_guid: props.dataItem.employee_leave_guid,
  //       isactive: !IsActive,
  //     };
  //     dispatch(EmployeeLeaveActiveInactive(data)).then((response: any) => {
  //       if (response.payload?.data?.Status === 200) {
  //         dispatch(getAllEmployeeLeaves());
  //       } else {
  //         dispatch(getAllEmployeeLeaves());
  //       }
  //     });
  //   };

  //   return (
  //     <td style={{ textAlign: "center" }}>
  //       <Tooltip anchorElement="target" position="top" parentTitle={true}>
  //         <div title={`Set ${IsActive ? "Inactive" : "Active"}.`}>
  //           <Switch
  //             disabled={isEditAccess ? false : true}
  //             name="isactive"
  //             checked={IsActive}
  //             onChange={handleActiveInActive}
  //             size="small"
  //           />
  //         </div>
  //       </Tooltip>
  //     </td>
  //   );
  // };

  const gridRef = useRef<any>(null);
  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {EmployeeLeaveList && EmployeeLeaveList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            (filter
              ? filterBy(EmployeeLeaveList, filter)
              : EmployeeLeaveList) || []
          }
          onFilterChange={handleFilterChange}
          total={EmployeeLeaveList[0]?.itemcount || EmployeeLeaveList?.length}
          skip={currentPage * pageLimit}
          take={pageLimit}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 50, 100, 300, 500],
            pageSizeValue: pageLimit,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="employee_name"
            title="Employee Name"
            cell={(props: any) => (
              <td>
                <div
                  className={isEditAccess ? "hoverableName" : "normalName"}
                  onClick={() =>
                    isEditAccess &&
                    navigate("/employeeLeave/edit", {
                      state: {
                        employee_leave_guid:
                          props.dataItem?.employee_leave_guid,
                      },
                    })
                  }
                >
                  {props.dataItem?.employee_name}
                </div>
              </td>
            )}
          />
          <Column field="leave_type" title="Leave Type" />
          <Column field="from_date" title="From Date" />
          <Column field="to_date" title="To Date" />
          <Column field="days" title="Total Days" />
          <Column field="apply_to_reporting" title="Apply To" />
          <Column
            field="leavestatus"
            title="Leave Status"
            cell={(props: any) => (
              <td>
                <div>
                  {props.dataItem?.leavestatus === "pending" && (
                    <span
                      style={{
                        backgroundColor: "rgb(254, 240, 199)",
                        color: "rgb(128, 55, 23)",
                        padding: "3px 10px",
                        textTransform: "capitalize",
                        borderRadius: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {props.dataItem?.leavestatus}
                    </span>
                  )}
                  {props.dataItem?.leavestatus === "Approved" && (
                    <span
                      style={{
                        backgroundColor: "#D1FADF",
                        color: "#065032",
                        padding: "3px 10px",
                        textTransform: "capitalize",
                        borderRadius: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {props.dataItem?.leavestatus}
                    </span>
                  )}{" "}
                  {props.dataItem?.leavestatus === "Rejected" && (
                    <span
                      style={{
                        backgroundColor: "rgb(255, 200, 200)",
                        color: "rgb(255, 0, 20)",
                        padding: "3px 10px",
                        textTransform: "capitalize",
                        borderRadius: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {props.dataItem?.leavestatus}
                    </span>
                  )}
                </div>
              </td>
            )}
          />
          {/* <Column
            field="isactive"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          /> */}
          {(isEditAccess || isDeleteAccess) && (
            <Column
              field="employee_leave_guid"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
              locked={true}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteEmployeeLeaveDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const EmployeeLeaveGUID = useAppSelector(
    (state) => state.employeeLeave.EmployeeLeaveGUID
  );
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const FilterPlayload = {
    employee_id: Number(localStorage.getItem("UserID")) || 0,
    leavestatus: "all",
    limit: +pageLimit,
    skip: +currentPage * +pageLimit,
  };
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleDeleteAction = async (ID: string) => {
    if (ID) {
      const response = await dispatch(deleteEmployeeLeave(ID));
      if (response.payload?.Data.Status === 200) {
        dispatch(getAllEmployeeLeave(FilterPlayload));
        dispatch(closeDialog());
        dispatch(clearEmployeeLeaveGUID());
      } else {
        dispatch(getAllEmployeeLeave(FilterPlayload));
        dispatch(closeDialog());
        dispatch(clearEmployeeLeaveGUID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteEmployeeLeave" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete EmployeeLeave ?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Employee Leave?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => {
                    handleDeleteAction(EmployeeLeaveGUID);
                  }}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default EmployeeLeave;
